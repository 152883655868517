import { getToken } from "../helpers/authentication-helpers";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://ootb-plansmart.devs.impactsmartsuite.com/api/v2",
});

const requestHandler = async (request) => {
  // for few apis baseURL will be changed to api/v3, pass isV3 key from service file

  const token = await getToken();
  request.headers.common = {
    Authorization: `${token}`,
    "application-code": 3,
    time_format: "",
    time_zone: "",
    "screen-name": null,
    "object-id": request.object_id,
  };

  console.log("request", request);
  return request;
};

const errorHandler = async (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        //store.dispatch(logoutUser());
        break;
      case 501:
        //store.dispatch(ingestionEnabled(true));
        break;
    }
  }

  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
