import React, { useState, Suspense, lazy, useContext, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Route, BrowserRouter as Router } from "react-router-dom-v5-compat";
import {
  // Route,
  Routes,
} from "react-router-dom-v5-compat";
import { Skeleton } from "@mui/material";
import { toastError } from "../../store/features/global/global";
import { setRoleActions } from "../../store/features/auth/auth";

/* 404 Page */
import NotFound from "./NotFound";

/* Layout */
import Layout from "../../components/common/layout/Layout";

/* Components */
import Login from "../../components/auth/Login";

import { formatRoles } from "../../utils/helpers/apiHelpers";
import { protectedRoutes } from "../../constants/NewConstants";

import AnalyticsContext from "../../posthog/AnalyticsContext";
import { createBrowserHistory } from "history";

/* Icons */

/* Decision Dashboard */

const loadComponent = (componentName) => {
  const Component = React.lazy(() =>
    import(`../../components/${componentName}`)
  );
  return Component;
};

const AppRoutes = (props) => {
  const analytics = useContext(AnalyticsContext);

  /* Posthog user tracking */
  const userId = localStorage.getItem("userId");
  const user_name = localStorage.getItem("user");
  const history = createBrowserHistory();
  const userInfo = {
    userId: userId,
    properties: {
      name: user_name,
      email: userId,
    },
  };
  /* Posthog user tracking */

  useEffect(() => {
    const unlisten = history.listen((location) => {
      analytics.trackPageView(location.pathname, userInfo);
    });
    analytics.trackPageView(history.location.pathname, userInfo);

    return () => {
      unlisten();
    };
  }, [analytics, history]);

  return (
    <Router history={history}>
      <main className="App">
        <Suspense
          fallback={
            <div>
              <h1>...Loading</h1>
              <Skeleton />
            </div>
          }
        >
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            {!_.isEmpty(protectedRoutes) &&
              protectedRoutes.map((args) => {
                if (!args.component)
                  return <Route path="/*" element={<NotFound />} />;
                const Component = loadComponent(args.component);

                const { ...rest } = args;
                // if (props.isAuthenticated || 1) {
                // 	const roles =
                // 		localStorage.getItem("userRoles");
                // 	if (roles && _.isEmpty(props.roleActions)) {
                // 		const formattedRoles = formatRoles(
                // 			roles.split(",")
                // 		);
                // 		props.setRoleActions(formattedRoles);
                // 	}
                return (
                  <Route
                    exact
                    {...rest}
                    element={
                      <Suspense>
                        <Layout
                          navOptions={[
                            ...protectedRoutes.filter(
                              (route) => route.showOnMenu === true
                            ),
                          ]}
                        >
                          <Component {...rest} />
                        </Layout>
                      </Suspense>
                    }
                  />
                );
                // 		} else {
                // 			props.toastError(
                // 				"Please Login to continue..."
                // 			);
                // 		}
              })}
            <Route path="/*" element={<NotFound />} />
            <Route
              path="/under-maintenance"
              element={<NotFound type="under_maintenance" />}
            />
          </Routes>
        </Suspense>
      </main>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token,
  roleActions: state.auth.roleActions,
});

const mapDispatchToProps = (dispatch) => {
  return {
    toastError: (data) => dispatch(toastError(data)),
    setRoleActions: (data) => dispatch(setRoleActions(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
