export const SIDEBAR_LAYOUT = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-content-emptystate",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            margin: "2px 10px",
            // border: "1px solid",
            // borderRadius: "10px",
            // borderColor: "#808080",
            //height: "600px",
          },
        },
        componentProps: {
          content: [
            {
              type: "text",
              staticProps: {
                variant: "h2",
                marginTop: "20px",
                content: "Plan Smart Configurator",
              },
            },
            {
              type: "text",
              staticProps: {
                variant: "h4",
                marginTop: "20px",
                content: "Client Level Configuration",
              },
            },
            {
              type: "div",
              id: "configuration-wrapper",
              staticProps: {
                customStyle: {
                  display: "flex",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "module-configuration",
                    componentProps: {
                      content: [
                        {
                          type: "card",
                          staticProps: {
                            mainHeading: "Module Configuratuon",
                            subHeading: "Select what modules should be enabled",
                            customStyle: {
                              margin: "2px 10px",
                              width: "380px",
                              height: "110px",
                            },
                          },
                        },
                      ],
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "module-configuration",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "div",
                    id: "version-configuration",
                    componentProps: {
                      content: [
                        {
                          type: "card",
                          staticProps: {
                            mainHeading: "Version Configuratuon",
                            subHeading: "Maintain versions of data for client",
                            customStyle: {
                              margin: "2px 10px",
                              width: "380px",
                              height: "110px",
                            },
                          },
                        },
                      ],
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "module-configuration",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "div",
                    id: "out-of-box-kpi",
                    componentProps: {
                      content: [
                        {
                          type: "card",
                          staticProps: {
                            mainHeading: "Out of the box KPI Configuratuon",
                            subHeading:
                              "Manage the KPI's defination for the client",
                            customStyle: {
                              margin: "2px 10px",
                              width: "380px",
                              height: "110px",
                            },
                          },
                        },
                      ],
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "kpi-configurator",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              //   functionProps: [
              //     {
              //       functionName: "onClick",
              //       actions: [
              //         {
              //           type: "redirect",
              //           link: "module-configuration",
              //         },
              //       ],
              //     },
              //   ],
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "text",
              staticProps: {
                variant: "h4",
                marginTop: "20px",
                content: "Planning Screen Configurations",
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 70,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "null",
                    dataType: "basic",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "configs",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
