export const PLANING_SCREEN = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "planning-screen-content-emptystate",

        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 50,
                  },
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "parameters",
                    value: {},
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "filterLevelValues",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
        componentProps: {
          content: [
            {
              type: "text",
              staticProps: {
                variant: "h4",
                content: "Select Product Level*",
              },
            },
            {
              type: "div",
              id: "pentagon-wrapper",
              componentProps: {
                content: [
                  {
                    id: "SelectedProductLevel",
                    type: "pentagonButtons",
                    staticProps: {
                      variant: "outlined",
                      className: "",
                      isMultiSelect: true,
                      label: "planFliters",
                      maxFilterAllowed: 2,
                      customStyle: {
                        margin: 0,
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      },
                    },
                    dataProps: {
                      buttons: {
                        type: "derived",
                        dataKey: "filterLevelValues",
                        subjectId: "1",
                        subjectidNested: "value",
                        subjectidNested1: "product_level",
                        saveWithLabel: "pcd_ids",
                        dataType: "array",
                      },
                    },

                    // staticProps: {

                    //   parentId: "temp_selected_filters_for_step4",
                    //   filtersForPayload: "tempFiltersForPayloadStep4",
                    //   changeFlagStatus: "trackSettingsChange",
                    // },
                    // dataProps: {
                    //   buttons: {
                    //     type: "derived",
                    //     dataKey: "filters_for_step_4",
                    //     subjectId: "pcd_metrics",
                    //     saveWithLabel: "pcd_ids",
                    //     dataType: "array",
                    //   },
                    //   defaultActiveButtonIds: {
                    //     type: "derived",
                    //     dataKey: "filtersForPayloadStep4",
                    //     subjectId: "pcd_ids",
                    //   },
                    // },
                  },
                ],
              },
            },
            {
              type: "text",
              staticProps: {
                variant: "h4",
                content: "Select channel level*",
              },
            },
            {
              type: "div",
              id: "pentagon-wrapper",

              componentProps: {
                content: [
                  {
                    id: "SelectedChannelLevel",
                    type: "pentagonButtons",
                    staticProps: {
                      variant: "outlined",
                      className: "",
                      isMultiSelect: true,
                      label: "planFliters",
                      maxFilterAllowed: 1,
                      customStyle: {
                        margin: 0,
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      },
                      // buttons: [
                      //   {
                      //     id: "L0",
                      //     label: "Distribution",
                      //   },
                      //   {
                      //     id: "L1",
                      //     label: "Region",
                      //   },
                      // ],
                      // defaultActiveButtonId: 0,
                    },
                    dataProps: {
                      buttons: {
                        type: "derived",
                        dataKey: "filterLevelValues",
                        subjectId: "0",
                        subjectidNested: "value",
                        subjectidNested1: "channel_level",
                        saveWithLabel: "pcd_ids",
                        dataType: "array",
                      },
                      selectedConfig: {
                        type: "derived",
                        dataKey: "selectedConfig",
                        subjectId: "config",
                      },
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "bottom-buttons",
              staticProps: {
                customStyle: {
                  marginTop: "10px",
                  marginBottom: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    id: "save-preview-button",
                    type: "button",
                    staticProps: {
                      variant: "secondary",
                      label: "Save & Preview",
                      customStyle: {
                        border: "1px solid",
                      },
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "planning-screen-level-table",
              staticProps: {
                customStyle: {
                  justifyContent: "center",
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "#808080",
                  padding: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h4",
                      content: "Preview Table",
                    },
                  },
                  {
                    type: "text",
                    staticProps: {
                      customStyle: {
                        display: "flex",
                        alignItems: "flex-start",
                      },
                      content:
                        "Note: Table name can be renames by double clicking on the names",
                    },
                  },

                  {
                    type: "aggrid",
                    staticProps: {
                      id: "planning-level-screen",
                      height: "400px",
                      rowSelection: "multiple",
                      uniqueKey: "unique_key",
                      isRowSelectable: (rowData) => {
                        return !rowData.data.is_sub_total;
                      },
                      getRowStyle: (params) => {
                        return params?.data?.is_sub_total
                          ? {
                              background: "#F7F7F7",
                              borderBottom: "2px solid #758490",
                            }
                          : {};
                      },
                      tableConfig: {
                        column_headers: [
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Department",
                            key: "department",
                            suppressToolPanel: true,
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                            cellStyle: {
                              background: "#f7f7f7",
                            },
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Division",
                            key: "division",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Class",
                            key: "class_name",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Sub Class",
                            key: "sub_class",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Category",
                            key: "kpi_category_name",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Distribution",
                            key: "distribution",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Region",
                            key: "region",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                          },

                          {
                            header: "Jan",
                            key: "pcd_metrics",
                            marryChildren: true,
                            columnGroupShow: "closed",
                            columns: [
                              {
                                header: "Jan Total",
                                key: "",
                                marryChildren: true,
                                columns: [
                                  {
                                    data_type: "number",
                                    is_edit: false,
                                    extra: {
                                      weekValue: 2904,
                                      metric: "price_point",
                                      disableLockAtTableLevel: false,
                                    },
                                    marryChildren: true,
                                    header: "Omni",
                                    minWidth: 220,
                                    key: "pcd_metrics_pcd_2904_clearance_ia_reco_discount_percent",
                                  },
                                ],
                              },
                              {
                                columnGroupShow: "open",
                                header: "Week1",
                                key: "pcd_2904",
                                marryChildren: true,
                                columns: [
                                  {
                                    data_type: "number",
                                    aggFunc: "sum",
                                    is_edit: false,
                                    extra: {
                                      weekValue: 2904,
                                      metric: "margin_dollar",
                                      disableLockAtTableLevel: false,
                                    },
                                    marryChildren: true,
                                    columnGroupShow: "closed",
                                    header: "Omni",
                                    key: "pcd_metrics_pcd_2904_margin_ia_reco",
                                  },
                                ],
                              },
                              {
                                columnGroupShow: "open",
                                header: "week2",
                                key: "pcd_2904",
                                marryChildren: true,
                                columns: [
                                  {
                                    data_type: "number",
                                    aggFunc: "sum",
                                    is_edit: false,
                                    marryChildren: true,
                                    columnGroupShow: "closed",
                                    header: "Omni",
                                    key: "pcd_metrics_pcd_2904_revenue_ia_reco",
                                  },
                                ],
                              },
                              {
                                columnGroupShow: "open",
                                header: "week3",
                                key: "pcd_2904",
                                marryChildren: true,
                                columns: [
                                  {
                                    data_type: "number",
                                    aggFunc: "sum",
                                    is_edit: false,
                                    extra: {
                                      weekValue: 2904,
                                      metric: "sales_units",
                                      disableLockAtTableLevel: false,
                                    },
                                    marryChildren: true,
                                    header: "Omni",
                                    key: "pcd_metrics_pcd_2904_unit_ia_reco",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Feb",
                            columns: [
                              {
                                type: "static_column",
                                mapping: "data",
                                header: "Feb Total",
                                key: "",
                                suppressMenu: true,
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: false,
                                pinned: false,
                                row_group: true,
                                columns: [
                                  {
                                    type: "static_column",
                                    mapping: "data",
                                    header: "Omni",
                                    key: "",
                                    suppressMenu: true,
                                    action: null,
                                    hidden: false,
                                    aggregate: null,
                                    sorting: false,
                                    filter: false,
                                    pinned: false,
                                    row_group: false,
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "March",
                            columns: [
                              {
                                type: "static_column",
                                mapping: "data",
                                header: "March Total",
                                key: "",
                                suppressMenu: true,
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: false,
                                pinned: false,
                                row_group: false,
                                columns: [
                                  {
                                    type: "static_column",
                                    mapping: "data",
                                    header: "Omni",
                                    key: "",
                                    suppressMenu: true,
                                    action: null,
                                    hidden: false,
                                    aggregate: null,
                                    sorting: false,
                                    filter: false,
                                    pinned: false,
                                    row_group: false,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    },
                    dataProps: {
                      slectedProductFilters: {
                        type: "derived",
                        dataKey: "SelectedProductLevelvalues",
                        dataType: "array",
                      },
                      slectedChannelFilters: {
                        type: "derived",
                        dataKey: "SelectedChannelLevelvalues",
                        dataType: "array",
                      },
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "basic-details-buttons",
              staticProps: {
                customStyle: {
                  bottom: 0,
                  paddingBottom: "10px",
                  width: "100%",
                  marginTop: "50px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "bottom-divider",
                    componentProps: {
                      content: [
                        {
                          id: "create-product-group-divider",
                          type: "divider",
                          staticProps: {
                            color: "black",
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "bottom-buttons",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        display: "flex",
                        marginLeft: "70%",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "cancel-basic-details-button",
                          type: "button",
                          staticProps: {
                            variant: "text",
                            label: "cancel",
                            className: "create-store-group-button",
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "redirect",
                                  link: "landing-screen",
                                },
                              ],
                            },
                          ],
                        },

                        {
                          alignment: "right",
                          id: "strategy-workbench-create-button",
                          type: "button",
                          staticProps: {
                            variant: "secondary",
                            label: "Save As Draft",
                            customStyle: {
                              border: "1px solid",
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                        },

                        {
                          id: "edit-store-group-submit-button",
                          type: "button",
                          staticProps: {
                            variant: "primary",
                            label: "Next",
                            customStyle: {
                              marginLeft: "20px",
                            },
                            className: "create-store-group-button",
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "api_function",
                                  runOnLoad: false,
                                  apiEndPoint: "model-update",
                                  apiMethod: "post",
                                  confirmation: {
                                    type: "check",
                                    showErrorMessage: true,
                                    conditions: [
                                      {
                                        comparison: "exists",
                                        source: "reducer",
                                        sourceId: "configDetails",
                                        subjectId: "config_name",
                                        dataType: "basic",
                                        message:
                                          "Please enter the config  name",
                                      },
                                    ],
                                  },
                                  params: [
                                    {
                                      source: "fixed",
                                      dataType: "object",
                                      apiRequestKey: "id",
                                      value: 51,
                                    },

                                    {
                                      source: "reducer",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "id",
                                      sourceId: "select-module",
                                      subjectId: "selectedItems",
                                      subjectidNested: "0",
                                      subjectidNested1: "id",
                                      dataType: "basic",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "name",
                                      sourceId: "configDetails",
                                      subjectId: "config_name",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "id",
                                      sourceId: "basic-details",
                                      subjectId: "selectedItems",
                                      subjectidNested: "0",
                                      subjectidNested1: "id",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested:
                                        "product_l0_editable",
                                      sourceId: "SelectedProductLevelvalues",
                                      subjectId: "0",
                                      subjectidNested: "value",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested:
                                        "product_l1_editable",
                                      sourceId: "SelectedProductLevelvalues",
                                      subjectId: "1",
                                      subjectidNested: "value",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested:
                                        "product_l2_editable",
                                      sourceId: "SelectedProductLevelvalues",
                                      subjectId: "2",
                                      subjectidNested: "value",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested:
                                        "product_l3_editable",
                                      sourceId: "SelectedProductLevelvalues",
                                      subjectId: "3",
                                      subjectidNested: "value",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested:
                                        "channel_l0_editable",
                                      sourceId: "SelectedChannelLevelvalues",
                                      subjectId: "0",
                                      subjectidNested: "value",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested:
                                        "channel_l1_editable",
                                      sourceId: "SelectedChannelLevelvalues",
                                      subjectId: "1",
                                      subjectidNested: "value",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "description",
                                      sourceId: "configDetails",
                                      subjectId: "config_description",
                                    },
                                  ],
                                  // onComplete: {
                                  //   actions: [
                                  //     {
                                  //       type: "redirect",
                                  //       link: "kpi-master",
                                  //     },
                                  //   ],
                                  // },
                                },
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "screenName",
                                      value: "PLANNING_SCREEN",
                                      dataType: "basic",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
