export const rules_landing_screen_table_column_config = {
	column_headers: [
		{
			type: "static_column",
			mapping: "data",
			header: "ID",
			key: "rule_id",
			action: "",
			hidden: false,
			sorting: false,
			filter: true,
			pinned: true,
			suppressMenu: true,
			checkbox_selection: true,
			row_group: false,
			rowDrag: false,
			is_edit: false,
			width: 150,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Rule Name",
			key: "rule_name",
			action: null,
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: true,
			pinned: true,
			sorting: true,
			suppressMenu: true,
			row_group: false,
			cellRenderer:
				"clickActionCellRenderer",
			cellRendererParams: {
				actions: [
					
				],
			},
			width: 150
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Flexibility",
			key: "rule_flexibility",
			action: "",
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: true,
			pinned: false,
			suppressMenu: true,
			checkbox_selection: false,
			row_group: false,
			width: 150,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Rule Type",
			key: "rule_type",
			action: "",
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: true,
			pinned: false,
			suppressMenu: true,
			checkbox_selection: false,
			row_group: false,
			width: 360,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Division",
			key: "product_h4_name",
			action: "",
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: true,
			pinned: false,
			suppressMenu: true,
			checkbox_selection: false,
			row_group: false,
			width: 360,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Department",
			key: "product_h3_name",
			action: "",
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: true,
			pinned: false,
			suppressMenu: true,
			checkbox_selection: false,
			row_group: false,
			width: 240,
		},
		
		{
			type: "static_column",
			mapping: "data",
			header: "Class",
			key: "product_h2_name",
			action: "",
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: true,
			pinned: false,
			suppressMenu: true,
			checkbox_selection: false,
			row_group: false,
			width: 240,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Sub Class",
			key: "product_h1_name",
			action: "",
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: true,
			pinned: false,
			suppressMenu: true,
			checkbox_selection: false,
			row_group: false,
			width: 240,
		},
        {
			type: "static_column",
			mapping: "data",
			header: "# Products",
			data_type: "number",
			key: "products",
			action: null,
			hidden: false,
			pinned: false,
			sorting: true,
			row_group: false,
			suppressMenu: true,
			width: 240,
		},
      
		{
			type: "static_column",
			mapping: "data",
			header: "Description",
			key: "rule_description",
			action: "",
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: true,
			pinned: false,
			checkbox_selection: false,
			suppressMenu: true,
			row_group: false,
			width: 240,
		},
        {
			type: "static_column",
			mapping: "data",
			header: "Last Revised by",
			key: "updated_by",
			action: "",
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: true,
			pinned: false,
			checkbox_selection: false,
			suppressMenu: true,
			row_group: false,
			width: 240,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Last Revised On",
			key: "updated_at",
			data_type: "date",
			action: null,
			hidden: false,
			aggregate: null,
			sorting: true,
			filter: false,
			pinned: false,
			row_group: false,
			suppressMenu: true,
			// valueFormatter:
			// 	"dateRangeFormatter",
				width: 240,
		},
	],
};
