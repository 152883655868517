import React from "react";
import Typography from "@mui/material/Typography";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";
import { protectedRoutes } from "../../../constants/NewConstants";
import "./Header.scss";

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter(Boolean);
  return (
    <MUIBreadcrumbs
      className="breadcrumb-container"
      aria-label="breadcrumb"
      sx={{
        backgroundColor: "white",
        fontWeight: "400",
        fontSize: "14px",
        padding: "0px 20px",
      }}
    >
      {pathnames.map((path, index) => {
        const isLast = index === pathnames.length - 1;
        const currentRoute = protectedRoutes?.find((pRoute) => {
          return pRoute.breadcrumbPath === path;
        });
        return isLast ? (
          <Typography
            className="active"
            key={currentRoute?.key}
            variant="span"
            component="span"
          >
            {currentRoute?.title}
          </Typography>
        ) : (
          <Link
            key={currentRoute?.key}
            onClick={() => navigate(currentRoute?.path)}
          >
            {currentRoute?.title}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
