import moment from "moment";
export const workflowPanelDataValue = [
  {
    id: 1,
    title: "Configuration Details",
    sub_sections: [
      {
        id: 2,
        title: "Basic Details",
        config_id: "9",
        config_type: "moduleConfig",
      },
      {
        id: 3,
        title: "Planning Screen Level",
        config_id: "10",
        config_type: "moduleConfig",
        json_structure: {},
      },
      // {
      //   id: 4,
      //   title: "Table Configuration",
      //   config_id: "10",
      //   config_type: "filterConfig",
      //   json_structure: {},
      // },
    ],
  },
  {
    id: 4,
    title: "KPI Configurator",
    sub_sections: [
      {
        id: 5,
        title: "KPI List",
      },
    ],
  },
  {
    id: 6,
    title: "Screen Palnner",
    sub_sections: [
      {
        id: 7,
        title: "Aggregation Strategy",
        config_id: "5",
        config_type: "tableConfig",
        json_structure: {},
      },
      {
        id: 8,
        title: "Disaggregation Strategy",
        config_id: "13",
        config_type: "userAccessManagementConfig",
        json_structure: {},
      },
    ],
  },
];

export const sideBarFilterModuleConfiguration = [
  {
    id: 1,
    title: "Filter Configuration Details",
    sub_sections: [
      {
        id: 2,
        title: "Inseason filters configurators",
        config_id: "9",
        config_type: "filterConfig",
      },
      {
        id: 3,
        title: "Preseason filters configurators",
        config_id: "10",
        config_type: "filterConfig",
        json_structure: {},
      },
      {
        id: 4,
        title: "Target filters configurators",
        config_id: "10",
        config_type: "filterConfig",
        json_structure: {},
      },
    ],
  },
];

export const outOfTheBoxContainer = [
  {
    id: 1,
    title: "KPI Configurator",
    sub_sections: [
      {
        id: 2,
        title: "KPI List",
        config_id: "8",
        config_type: "filterConfig",
      },
    ],
  },
];

export const filterConfigPropsValueOutOftheBox = {
  id: 1,
  title: "KPI Configurator",
};

export const filterConfigPropsValue = {
  id: 2,
  title: "Basic Details",
};

export const tabDataValues = {
  dimensions: [
    { createplanfilter: [{ sub_dimension: [] }] },
    { masterplan: [{ sub_dimension: [] }] },
    { quickfilters: [{ sub_dimension: [] }] },
  ],
  saved_filters: {
    name: "New_Productstatus",
    mappings: [
      {
        sub_headers: [],
        tc_code: 46,
        label: "Plan System Name",
        column_name: "name",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 1.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800005",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: true,
        extra: {},
        is_sortable: true,
        width: 300,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "Plan Display Name",
        column_name: "plan_display_name",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 2.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800006",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: true,
        extra: {},
        is_sortable: true,
        width: 200,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "Created By",
        column_name: "created_by",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 3.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800007",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: false,
        extra: {},
        is_sortable: true,
        width: 200,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "Created On",
        column_name: "created_at",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 4.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800008",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: false,
        extra: {},
        is_sortable: true,
        width: 200,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "Start Year",
        column_name: "start_year",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 5.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800009",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: true,
        extra: {},
        is_sortable: true,
        width: 140,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "Start Month",
        column_name: "start_month",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 6.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800010",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: true,
        extra: {},
        is_sortable: true,
        width: 200,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "End Year",
        column_name: "end_year",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 7.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800011",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: true,
        extra: {},
        is_sortable: true,
        width: 140,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "End Month",
        column_name: "end_month",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 8.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800012",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: true,
        extra: {},
        is_sortable: true,
        width: 200,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "Department",
        column_name: "l2_name",
        dimension: "Product",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 9.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800013",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: true,
        extra: {},
        is_sortable: true,
        width: 200,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "Classes",
        column_name: "l3_name",
        dimension: "Product",
        type: "list",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 10.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800014",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: true,
        extra: {},
        is_sortable: true,
        width: 200,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "Last Updated At",
        column_name: "updated_at",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 11.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800015",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: false,
        extra: {},
        is_sortable: true,
        width: 200,
        is_deleted: false,
        is_master_group: false,
      },
      {
        sub_headers: [],
        tc_code: 46,
        label: "Last Updated By",
        column_name: "updated_by",
        dimension: "Plan",
        type: "str",
        is_frozen: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: 12.0,
        is_hidden: null,
        is_required: false,
        tc_mapping_code: "2800016",
        aggregate_type: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: false,
        extra: {},
        is_sortable: true,
        width: 200,
        is_deleted: false,
        is_master_group: false,
      },
    ],
    fc_code: 172,
  },
  dimension_filter_mapping: {
    filters: [
      {
        label: "Merch Department",
        column_name: "l1_name",
        is_hierarchy: true,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Clearance End Date",
        column_name: "clearance_end_date",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Article",
        column_name: "article",
        is_hierarchy: true,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "End Date",
        column_name: "end_date",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Merch Business Category",
        column_name: "l0_name",
        is_hierarchy: true,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Merch Class",
        column_name: "l2_name",
        is_hierarchy: true,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Merch Pyramid",
        column_name: "l3_name",
        is_hierarchy: true,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Color",
        column_name: "l5_name",
        is_hierarchy: true,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Marketing Color Name",
        column_name: "color",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Marketing Color Name",
        column_name: "human_readable_color",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Merchant Pyramid",
        column_name: "merchant_pyramid",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Merchant Pyramid Colorway",
        column_name: "merchant_pyramid_colorway",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "New Carry Over",
        column_name: "new_carryover_style",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "New Carry Over Sku",
        column_name: "new_carryover_sku",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Parent Style",
        column_name: "parent_style_id",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Parent Style Description",
        column_name: "parent_style_description",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Pillar",
        column_name: "pillar",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Primary Fabrication",
        column_name: "fabrication",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Product Bucket Code",
        column_name: "product_bucket_code",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Ps Dropped Date",
        column_name: "style_dropped_date",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Ps Suggested Retail Price",
        column_name: "product_price",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Ps Wholesale Price",
        column_name: "wholesale_price",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Retailer Markup",
        column_name: "retailer_markup",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "School",
        column_name: "school",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Size Description",
        column_name: "size_description",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Sku",
        column_name: "sku",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Sku Initial Launch Date",
        column_name: "launch_date",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Sku Planned Clearance Date",
        column_name: "clearance_start_date",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Sku Retirement Date",
        column_name: "retirement_date",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Sku Selldown Date",
        column_name: "selldown_date",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Style Description",
        column_name: "style_description",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Style Id",
        column_name: "style",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Sub Class",
        column_name: "sub_class",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Color Code",
        column_name: "color_code",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Dropship Flag",
        column_name: "dropship_flag",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Style",
        column_name: "l4_name",
        is_hierarchy: true,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Cs Dropped Date",
        column_name: "sku_dropped_date",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Development Type",
        column_name: "development_type",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Direct Imu Target",
        column_name: "direct_imu_target",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Indirect Imu Target",
        column_name: "indirect_imu_target",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Intellectual Property",
        column_name: "intellectual_property",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Inventsizeid",
        column_name: "inventsizeid",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Plm Standard Cost",
        column_name: "product_cost",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Selling Collection",
        column_name: "selling_collection",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Size",
        column_name: "size",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Product Direct Channel",
        column_name: "product_direct_channel",
        is_hierarchy: false,
        is_attribute: true,
        is_main_col: false,
        dimension: "product",
      },
      {
        label: "Active",
        column_name: "active",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Product Name",
        column_name: "product_name",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Original Price",
        column_name: "original_price",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Product Code",
        column_name: "product_code",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Cost",
        column_name: "cost",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Product Description",
        column_name: "product_description",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Clearance",
        column_name: "clearance",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Updated At",
        column_name: "updated_at",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Created At",
        column_name: "created_at",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Price",
        column_name: "price",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "Receipt Date",
        column_name: "receipt_date",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: true,
        dimension: "product",
      },
      {
        label: "product group",
        column_name: "product_group",
        is_hierarchy: false,
        is_attribute: false,
        is_main_col: false,
        dimension: "product",
      },
    ],
  },
};

export const tableColumnConfig = [
  {
    sub_headers: [],
    tc_code: 198,
    label: "Dimension",
    column_name: "dimension",
    dimension: "Others",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 1,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "71279",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Dimension",
    field: "dimension",
    accessor: "dimension",
    id: "dimension",
    headerName: "Dimension",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Dimension",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Filter",
    column_name: "column_name",
    dimension: "Others",
    type: "list",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 2,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "71268",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Filter",
    field: "column_name",
    accessor: "column_name",
    id: "column_name",
    headerName: "Filter",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Filter",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: false,
    suppressPaste: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Label",
    column_name: "label",
    dimension: "Others",
    type: "str",
    is_frozen: false,
    is_editable: true,
    is_aggregated: true,
    order_of_display: 3,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "71280",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Label",
    field: "label",
    accessor: "label",
    id: "label",
    headerName: "Label",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Label",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Functionality",
    column_name: "display_type",
    dimension: "Others",
    type: "list",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 5,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "7270",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Functionality",
    field: "display_type",
    accessor: "display_type",
    id: "display_type",
    headerName: "Functionality",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Functionality",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: false,
    suppressPaste: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Filter Type",
    column_name: "filter_type",
    dimension: "Others",
    type: "list",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 6,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "7271",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Filter_Type",
    field: "filter_type",
    accessor: "filter_type",
    id: "filter_type",
    headerName: "Filter Type",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Filter Type",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: false,
    suppressPaste: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Mandatory",
    column_name: "is_mandatory",
    dimension: "Others",
    type: "ToogleField",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 7,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "7272",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Mandatory",
    field: "is_mandatory",
    accessor: "is_mandatory",
    id: "is_mandatory",
    headerName: "Mandatory",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Mandatory",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    editable: false,
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Display Order",
    column_name: "display_order",
    dimension: "Others",
    type: "list",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 8,
    is_hidden: false,
    is_required: true,
    tc_mapping_code: "71274",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Display_Order",
    field: "display_order",
    accessor: "display_order",
    id: "display_order",
    headerName: "Display Order",
    pinned: null,
    required: true,
    resizable: true,
    headerTooltip: "Display Order",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: false,
    suppressPaste: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Clearable",
    column_name: "is_clearable",
    dimension: "Others",
    type: "ToogleField",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 10,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "71277",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Clearable",
    field: "is_clearable",
    accessor: "is_clearable",
    id: "is_clearable",
    headerName: "Clearable",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Clearable",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    editable: false,
  },
];

export const rowDataValue = [
  {
    sub_headers: [],
    tc_code: 46,
    label: "Plan System Name",
    column_name: "name",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 1.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800005",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: true,
    extra: {},
    is_sortable: true,
    width: 300,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "Plan Display Name",
    column_name: "plan_display_name",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 2.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800006",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: true,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "Created By",
    column_name: "created_by",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 3.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800007",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "Created On",
    column_name: "created_at",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 4.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800008",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "Start Year",
    column_name: "start_year",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 5.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800009",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: true,
    extra: {},
    is_sortable: true,
    width: 140,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "Start Month",
    column_name: "start_month",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 6.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800010",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: true,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "End Year",
    column_name: "end_year",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 7.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800011",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: true,
    extra: {},
    is_sortable: true,
    width: 140,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "End Month",
    column_name: "end_month",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 8.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800012",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: true,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "Department",
    column_name: "l2_name",
    dimension: "Product",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 9.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800013",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: true,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "Classes",
    column_name: "l3_name",
    dimension: "Product",
    type: "list",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 10.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800014",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: true,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "Last Updated At",
    column_name: "updated_at",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 11.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800015",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
  },
  {
    sub_headers: [],
    tc_code: 46,
    label: "Last Updated By",
    column_name: "updated_by",
    dimension: "Plan",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 12.0,
    is_hidden: null,
    is_required: false,
    tc_mapping_code: "2800016",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
  },
];

export const tableData = [
  {
    sub_headers: [],
    tc_code: 198,
    label: "Dimension",
    column_name: "dimension",
    dimension: "Others",
    type: "str",
    is_frozen: false,
    is_editable: false,
    is_aggregated: false,
    order_of_display: 1,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "71279",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Dimension",
    field: "dimension",
    accessor: "dimension",
    id: "dimension",
    headerName: "Dimension",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Dimension",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Filter",
    column_name: "column_name",
    dimension: "Others",
    type: "list",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 2,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "71268",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Filter",
    field: "column_name",
    accessor: "column_name",
    id: "column_name",
    headerName: "Filter",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Filter",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: false,
    suppressPaste: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Label",
    column_name: "label",
    dimension: "Others",
    type: "str",
    is_frozen: false,
    is_editable: true,
    is_aggregated: true,
    order_of_display: 3,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "71280",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Label",
    field: "label",
    accessor: "label",
    id: "label",
    headerName: "Label",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Label",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Functionality",
    column_name: "display_type",
    dimension: "Others",
    type: "list",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 5,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "7270",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Functionality",
    field: "display_type",
    accessor: "display_type",
    id: "display_type",
    headerName: "Functionality",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Functionality",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: false,
    suppressPaste: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Filter Type",
    column_name: "filter_type",
    dimension: "Others",
    type: "list",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 6,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "7271",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Filter_Type",
    field: "filter_type",
    accessor: "filter_type",
    id: "filter_type",
    headerName: "Filter Type",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Filter Type",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: false,
    suppressPaste: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Mandatory",
    column_name: "is_mandatory",
    dimension: "Others",
    type: "ToogleField",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 7,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "7272",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Mandatory",
    field: "is_mandatory",
    accessor: "is_mandatory",
    id: "is_mandatory",
    headerName: "Mandatory",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Mandatory",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    editable: false,
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Display Order",
    column_name: "display_order",
    dimension: "Others",
    type: "list",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 8,
    is_hidden: false,
    is_required: true,
    tc_mapping_code: "71274",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Display_Order",
    field: "display_order",
    accessor: "display_order",
    id: "display_order",
    headerName: "Display Order",
    pinned: null,
    required: true,
    resizable: true,
    headerTooltip: "Display Order",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    editable: false,
    suppressPaste: true,
    cellStyle: {},
  },
  {
    sub_headers: [],
    tc_code: 198,
    label: "Clearable",
    column_name: "is_clearable",
    dimension: "Others",
    type: "ToogleField",
    is_frozen: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 10,
    is_hidden: false,
    is_required: false,
    tc_mapping_code: "71277",
    aggregate_type: null,
    formatter: null,
    is_row_span: false,
    footer: null,
    is_searchable: false,
    extra: {},
    is_sortable: true,
    width: 200,
    is_deleted: false,
    is_master_group: false,
    headerClass: "Clearable",
    field: "is_clearable",
    accessor: "is_clearable",
    id: "is_clearable",
    headerName: "Clearable",
    pinned: null,
    required: false,
    resizable: true,
    headerTooltip: "Clearable",
    showRangeFilter: false,
    filter: false,
    floatingFilter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    suppressMenu: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    editable: false,
  },
];

export const calendarConfig = {
  fstDayOfWk: 1, // Monday
  fstMoOfYr: 0, // Jan
};
export const DATE_FORMATS = [
  "DD-MM-YYYY",
  "MM-DD-YYYY",
  "YYYY-MM-DD",
  "MM-DD-YYYY HH:mm:ss",
  "MM-DD-YYYYTHH:mm:ssZ",
  "YYYY-MM-DDTHH:mm:ssZ",
  "YYYY-MM-DD HH:mm:ss",
  "MM-DD-YYYY, HH:mm:ss",
];

export const DEFAULT_ROUNDOFF = 2;
export const IGNORE_ROUNDOFF = ["int"];
export const SPECIAL_CHARACTER_MAPPING = {
  __ia_char_01: "'",
  __ia_char_02: '"',
  __ia_char_03: "/",
  __ia_char_04: "\\",
  __ia_char_05: "`",
  __ia_char_06: "~",
  __ia_char_07: "!",
  __ia_char_08: "@",
  __ia_char_09: "#",
  __ia_char_10: "$",
  __ia_char_11: "%",
  __ia_char_12: "^",
  __ia_char_13: "&",
  __ia_char_14: "*",
  __ia_char_15: "(",
  __ia_char_16: ")",
  __ia_char_19: "=",
  __ia_char_20: "+",
  __ia_char_21: "{",
  __ia_char_22: "}",
  __ia_char_23: "[",
  __ia_char_24: "]",
  __ia_char_25: "|",
  __ia_char_26: ":",
  __ia_char_27: ";",
  __ia_char_28: "<",
  __ia_char_29: ">",
  __ia_char_30: ",",
  __ia_char_31: ".",
  __ia_char_32: "?",
};

export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_START_DATE_MOMENT = moment().add(0, "days");
export const DEFAULT_END_DATE_MOMENT = moment().add(120, "days");
export const DEFAULT_START_DATE = moment()
  .add(0, "days")
  .format(DEFAULT_DATE_FORMAT);
export const DEFAULT_END_DATE = moment()
  .add(120, "days")
  .format(DEFAULT_DATE_FORMAT);
export const DEFAULT_START_DATE_MOMENT_REPORTING = moment().subtract(
  31,
  "days"
);
export const DEFAULT_END_DATE_MOMENT_REPORTING = moment().subtract(1, "days");
export const DEFAULT_START_DATE_MOMENT_DECISION_DASHBOARD = moment().subtract(
  14,
  "days"
);

export const NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER = 1;

export const singleSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "32px",
    minHeight: "32px",
    fontSize: "0.8rem",
    border: "1px solid #cccccc",
    top: "2px",
    cursor: "pointer",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #cccccc",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "32px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    color: "transparent",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
    // minHeight: "32px",
    padding: "6px",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: "0px 8px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "2px 4px 2px",
    fontSize: "0.8rem",
  }),
  menuList: (provided, state) => ({
    ...provided,
    fontSize: "0.8rem",
    padding: 0,
  }),
};

export const FILTER_ORDER = {
  product_h1: [
    "product_h2",
    "product_h3",
    "product_h4",
    "brand",
    "strategy_id",
    "calendar_config_ids",
  ],
  product_h2: [
    "product_h3",
    "product_h4",
    "brand",
    "strategy_id",
    "calendar_config_ids",
  ],
  product_h3: ["product_h4", "brand", "strategy_id", "calendar_config_ids"],
  product_h4: [
    "product_h2",
    "product_h3",
    "product_h4",
    "brand",
    "strategy_id",
    "calendar_config_ids",
  ],
  brand: ["strategy_id", "calendar_config_ids"],
  store_h1: [
    "store_h2",
    "store_h3",
    "store_h4",
    "store_h5",
    "store_h6",
    "strategy_id",
    "calendar_config_ids",
  ],
  store_h2: [
    "store_h3",
    "store_h4",
    "store_h5",
    "store_h6",
    "strategy_id",
    "calendar_config_ids",
  ],
  store_h3: [
    "store_h4",
    "store_h5",
    "store_h6",
    "strategy_id",
    "calendar_config_ids",
  ],
  store_h4: ["store_h5", "store_h6", "strategy_id", "calendar_config_ids"],
  store_h5: ["store_h6", "strategy_id", "calendar_config_ids"],
  store_h6: ["strategy_id", "calendar_config_ids"],
  dateRange: ["strategy_id", "calendar_config_ids"],
  rule_type: ["rule_flexibility_type_id", "rule_id"],
  rule_flexibility_type_id: ["rule_id"],
  strategy_status: ["strategy_id"],
};

export const STRATEGY_STATUS_FILTER_DEFAULTS = [0, 5, 1, 2, 3, 4];

export const STRATEGY_STATUS = [
  {
    label: "Draft/Copied",
    value: 0,
  },
  {
    label: "Pending Review",
    value: 1,
  },
  {
    label: "Finalized",
    value: 2,
  },
  {
    label: "Archived",
    value: 6,
  },
];

export const VALID_OFFER_TYPES = {
  percent_off: "% Off",
  extra_amount_off: "$ Off",
  fixed_price: "PP",
  bxgy: "BXGY",
  bundle_offer: "Bundle Offer",
  reg_price: "Reg Price",
  reg_price_cv: "Reg Price CV",
  bxgy_$: "Bundle Offer Buy",
  bmsm: "BMSM",
};

export const DISCOUNT_LEVELS = {
  overall: -200,
  product_h1: 1,
  product_h2: 2,
  product_h3: 3,
  product_h4: 4,
  product_h5: 5,
};

export const PRODUCT_HIERARCHY = [
  {
    label: "Overall",
    value: DISCOUNT_LEVELS.overall,
    hierarchy: -200,
  },
  {
    label: "Division",
    value: DISCOUNT_LEVELS["product_h1"],
    hierarchy: "product_h1",
  },
  {
    label: "Department",
    value: DISCOUNT_LEVELS["product_h2"],
    hierarchy: "product_h2",
  },
  {
    label: "Class",
    value: DISCOUNT_LEVELS["product_h3"],
    hierarchy: "product_h3",
  },
  {
    label: "Sub Class",
    value: DISCOUNT_LEVELS["product_h4"],
    hierarchy: "product_h4",
  },
  {
    label: "SKU",
    value: DISCOUNT_LEVELS["product_h5"],
    hierarchy: "product_h5",
  },
];

export const MODEL_API = {
  WORKBENCH_AGGREGATE_METRICS: {
    MODEL_ID: 1,
    COLUMNS: [
      // "target_gross_margin",
      "finalized_gross_margin",
      "finalized_baseline_gross_margin",
      "finalized_incremental_gross_margin",
      "projected_gross_margin",
      // "target_revenue",
      "finalized_revenue",
      "finalized_baseline_revenue",
      "finalized_incremental_revenue",
      "projected_revenue",
      // "target_sales_units",
      "finalized_sales_units",
      "finalized_baseline_sales_units",
      "finalized_incremental_sales_units",
      "projected_sales_units",
    ],
  },
  METRICS_EVENT_TIME: {
    MODEL_ID: 4,
  },
  METRICS_PROMO_TIME: {
    MODEL_ID: 3,
  },
  METRICS_BULK_PROMO_EDIT: {
    MODEL_ID: 5,
  },
  METRICS_EVENT_PRODUCTS: {
    MODEL_ID: 8,
  },
  METRICS_PROMO_PRODUCTS: {
    MODEL_ID: 7,
  },
  METRICS_EVENTS_PROMO_LIST: {
    MODEL_ID: 9,
  },
  METRICS_EVENTS_FILTERS_LIST: {
    MODEL_ID: 10,
  },
  METRICS_REPORTING: {
    MODEL_ID: 12,
  },
  METRICS_REPORTING_TOP_BOTTOM_OFFERS: {
    MODEL_ID: 11,
  },
  METRICS_REPORTING_WATERFALL: {
    MODEL_ID: 13,
  },
  METRICS_REPORTING_EVENT_FILTER: {
    MODEL_ID: 14,
  },
  METRICS_TOP_BOTTOM_OFFERS_DOWNLOAD: {
    MODEL_ID: 15,
  },
  GLOBAL_CONFIG_OPTIONS: {
    MODEL_ID: 16,
    parameters: {
      // event_ad_type: true,
      // offer_types: true,
      // event_channel: true,
    },
  },
  SAP_DOWNLOAD: {
    DOWNLOAD_1: 17,
    DOWNLOAD_2: 20,
    DOWNLOAD_3: 22,
    DOWNLOAD_4: 25,
  },
};

export const ACTION_MAPPING = {
  1: "create_promo",
  2: "edit_promo",
  3: "delete_promo",
  4: "download",
  5: "upload",
  6: "view_promo",
  7: "approve_promo",
  8: "finalize_promo",
  9: "create_event",
  10: "edit_event",
  11: "delete_event",
  12: "lock_event",
  13: "view_event",
  14: "download_marketing_report",
  15: "download_signage_report",
  16: "download_sap_report",
};

// export const ROLE_ACTION_MAPPING = {
// 	1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
// 	2: [4, 6, 9, 10, 11, 12, 13],
// 	3: [1, 2, 3, 4, 5, 6, 7, 8, 13],
// 	4: [4, 6, 13],
// };

export const SUPPORT = "/support";

export const CONFLICT_TYPES = {
  0: "IN FINALIZED",
  1: "IN REQUEST",
};

export const STORE_HIERARCHY_MAPPING = {
  store_h0: "Country",
  store_h1: "DC",
  store_h2: "Region",
  store_h3: "State",
  store_h4: "District",
  store_h5: "City",
  store_h6: "Location",
};

export const overriddenValueColumns = [
  "markdown_finalized",
  "sales_units_ia_finalized",
  "sales_units_baseline",
  "sales_units_ia_incremental",
  "sales_dollars_ia_finalized",
  "sales_dollars_baseline",
  "sales_dollars_ia_incremental",
  "gross_margin_ia_finalized",
  "gross_margin_baseline",
  "gross_margin_ia_incremental",
  "margin_ia_recommended",
  "margin_scenario_1",
  "margin_scenario_2",
  "sales_ia_recommended",
  "sales_scenario_1",
  "sales_scenario_2",
  "sales_units_ia_recommended",
  "sales_units_scenario_1",
  "sales_units_scenario_2",
  "incremental_margin_ia_recommended",
  "incremental_margin_scenario_1",
  "incremental_margin_scenario_2",
  "incremental_sales_ia_recommended",
  "incremental_sales_scenario_1",
  "incremental_sales_scenario_2",
  "incremental_sales_units_ia_recommended",
  "incremental_sales_units_scenario_1",
  "incremental_sales_units_scenario_2",
  "markdown_budget",
  "units_incremental_ia",
  "revenue_incremental_finalized",
  "revenue_incremental_ia",
  "gross_margin_incremental_finalized",
  "gross_margin_incremental_ia",
  "units_finalized",
  "sales_units_baseline",
  "units_incremental_finalized",
  "revenue_finalized",
  "revenue_baseline",
  "revenue_incremental_finalized",
  "gross_margin_finalized",
  "gross_margin_baseline",
  "gross_margin_incremental_finalized",
  "units_baseline",
];

export const rule_flexibility_type_id = {
  Flexible: 0,
  Inflexible: 1,
};

export const Simulation_mapping = {
  ia_recc: {
    label: "IA Recommended",
    value: "ia_recc",
    finalize: true,
    is_edit: false,
    dataKeys: [
      {
        Units: "ia_recc_sales_units",
        "Revenue $": "ia_recc_revenue",
        "Margin $": "ia_recc_gm_dollar",
      },
      {
        "Margin %": "ia_recc_gm_percent",
        "AUR $": "ia_recc_aur",
        "AUM $": "ia_recc_aum",
      },
      { "ST %": "ia_recc_st_percent", "Markdown $": "ia_recc_markdown_dollar" },
    ],
  },
  draft_scenario: {
    label: "Scenario In Draft",
    value: "draft_override",
    finalize: true,
    dataKeys: [
      {
        Units: "draft_override_sales_units",
        "Revenue $": "draft_override_revenue",
        "Margin $": "draft_override_gm_dollar",
      },
      {
        "Margin %": "draft_override_gm_percent",
        "AUR $": "draft_override_aur",
        "AUM $": "draft_override_aum",
      },
      {
        "ST %": "draft_override_st_percent",
        "Markdown $": "draft_override_markdown_dollar",
      },
    ],
  },
  bl_override: {
    label: "BL Override",
    value: "bl_override",
    finalize: false,
    dataKeys: [
      {
        Units: "bl_override_sales_units",
        "Revenue $": "bl_override_revenue",
        "Margin $": "bl_override_gm_dollar",
      },
      {
        "Margin %": "bl_override_gm_percent",
        "AUR $": "bl_override_aur",
        "AUM $": "bl_override_aum",
      },
      {
        "ST %": "bl_override_st_percent",
        "Markdown $": "bl_override_markdown_dollar",
      },
    ],
  },
  last_week: {
    label: "Last Week",
    finalize: false,
    dataKeys: [
      {
        Units: "lw_sales_units",
        "Revenue $": "lw_revenue",
        "Margin $": "lw_gm_dollar",
      },
      {
        "Margin %": "lw_gm_percent",
        "AUR $": "lw_aur",
        "AUM $": "lw_aum",
      },
      { "ST %": "lw_st_percent", "Markdown $": "lw_markdown_dollar" },
    ],
  },
  historical: {
    label: "Historical",
    finalize: false,
    dataKeys: [
      {
        Units: "till_date_sales_units",
        "Revenue $": "till_date_revenue",
        "Margin $": "till_date_gm_dollar",
      },
      {
        "Margin %": "till_date_gm_percent",
        "AUR $": "till_date_aur",
        "AUM $": "till_date_aum",
      },
      {
        "ST %": "till_date_st_percent",
        "Markdown $": "till_date_markdown_dollar",
      },
    ],
  },
};

export const simulation_data_formatter_mapping = {
  Units: "toUnit",
  "Revenue $": "toDollar",
  "Margin $": "toDollar",
  "Margin %": "toPercentage",
  "AUR $": "toDollarWithDecimal",
  "AUM $": "toDollarWithDecimal",
  "ST %": "toPercentage",
  "Markdown $": "toDollar",
};

export const columnsForIaTable = {
  bl_override: {
    label: "BL Override",
    id: "bl_override",
  },
  ia_reco: {
    label: "IA Recom",
    id: "ia_reco",
  },
};

export const lockColumnsKey = ["product_level_value"];

export const cellsWithLockAndInput = ["bl_override", "ia_reco"];

export const optimizationType = {
  ia_optimization: 0,
  partial_optimization: 1,
  no_optimization: 2,
};

export const disbleColumnMenu = ["price_point"];

export const screenUrlsIncludedInFilterExclusion = [
  { url: "/core/group/store/filter" },

  {
    url: `/master/products`,
    dimensions: [{ dimension: "product", key: "filters" }],
  }, //Product Mapping Dashboard Screen fetch products API
  {
    url: `/product-mapping/stores`,
    dimensions: [{ dimension: "store", key: "filters" }],
  }, //Product Mapping Modify screen fetch stores API
  {
    url: `/product-mapping/stores/download`,
    dimensions: [{ dimension: "store", key: "filters" }],
  }, //Product Mapping Modify screen download stores API
  {
    url: `/master/stores`,
    dimensions: [{ dimension: "store", key: "filters" }],
  }, //Store Mapping Dashboard screen fetch stores API
  {
    url: `/core/group/store/new`,
    dimensions: [
      { dimension: "store", key: ["store_ids", "filters"] },
      { dimension: "store", key: ["store_group_ids", "filters"] },
    ],
  }, //Store Group create API
  {
    url: `/product-mapping/mapped-stores`,
    dimensions: [{ dimension: "store", key: "filters" }],
  }, //Product Mapping view mapped stores API,
  {
    url: `/core/group/store/[0-9]+$`,
    dimensions: [
      { dimension: "store", key: ["store_ids", "filters"] },
      { dimension: "store", key: ["store_group_ids", "filters"] },
    ],
  }, //Store Group update API
  // {
  //   url: `${GET_PRODUCT_TO_DC}`,
  //   dimensions: [{ dimension: "product", key: "filters" }],
  // },
  {
    url: "/core/group/bulk/store",
    dimensions: [
      { dimension: "store", key: ["store_ids", "filters"] },
      { dimension: "store", key: ["store_group_ids", "filters"] },
    ],
  },
  {
    url: `/store-mapping/products`,
    dimensions: [{ dimension: "product", key: "filters" }],
  }, //Store Mapping Modify screen fetch products API
  {
    url: `/product-mapping/product-to-store/set-all`,
    dimensions: [{ dimension: "store", key: ["stores", "filters"] }],
  }, //set all API for product mapping
  {
    url: "/store-mapping/stores/pagination",
    dimensions: [{ dimension: "store", key: "filters" }],
  },
  // {
  //   url: `${SET_ALL_TABLE_DATA}/product`,
  //   dimensions: [{ dimension: "product", key: ["codes", "filters"] }],
  // },
  // {
  //   url: `${SET_ALL_TABLE_DATA}/store`,
  //   dimensions: [{ dimension: "store", key: ["codes", "filters"] }],
  // },
];
