export const AGGREGATION_STRATERGY = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "kpi-master-list",
        staticProps: {
          customStyle: {
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          },
        },
        componentProps: {
          content: [
            {
              type: "SelectedHierarchy",
            },
            {
              type: "div",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "space-between",
                  width: "85px",
                },
              },
              componentProps: {
                content: [
                  {
                    id: "channel-remove-button",
                    type: "iconButton",
                    staticProps: {
                      variant: "outlined",
                      icon: "search",
                      // iconClass: "colorBlack",
                      // className: "icon-button",
                      customStyle: {
                        background: "white",
                        color: "#0055AF",
                        border: "1px solid #0055AF",
                      },
                    },
                  },
                  {
                    id: "channel-remove-button",
                    type: "iconButton",
                    staticProps: {
                      variant: "outlined",
                      icon: "publish",
                      // iconClass: "colorBlack",
                      // // className: "icon-button",
                      customStyle: {
                        background: "white",
                        color: "#0055AF",
                        border: "1px solid #0055AF",
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 65,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "reducer",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    sourceId: "selectedConfig",
                    subjectId: "id",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "kpiConfigurationList",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: "div",
        id: "workbench-metrics-table",
        staticProps: {
          className: "common-content-container",
          customStyle: {
            marginTop: "20px",
          },
        },
        componentProps: {
          content: [
            {
              type: "aggrid",
              staticProps: {
                height: "400px",
                width: "100%",
                tableId: "decision_dashboard_strategies_product_count",
                rowSelection: "multiple",
                onClickCell: "navigate",
                uniqueKey: "",
                tableConfig: {
                  column_headers: [
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "",
                      key: "",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: true,
                      lockPinned: true,
                      checkbox_selection: true,
                      row_group: false,
                      suppressMenu: true,
                      maxWidth: 50,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "KPI Details",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "KPI",
                          key: "name",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: true,
                          row_group: false,
                          cellRenderer: "customIconClick",
                          cellRendererParams: {
                            actions: [
                              {
                                type: "reducer_function",
                                responseFormatter: [
                                  {
                                    destination: "reducer",
                                    dataKey: "loadEditAggregationStrategy",
                                    value: true,
                                  },
                                ],
                              },
                              {
                                type: "redirect",
                                link: "add-new-config",
                              },
                              {
                                type: "reducer_function",
                                params: [
                                  {
                                    source: "self",
                                    apiRequestKey: "selectedKpi",
                                    dataType: "basic",
                                  },
                                ],
                                responseFormatter: [
                                  {
                                    destination: "reducer",
                                    dataKey: "selectedKpi",
                                    apiResponseKey: "selectedKpi",
                                    dataType: "basic",
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Label",
                          key: "label",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: true,
                          row_group: false,
                        },
                        // {
                        //   type: "static_column",
                        //   mapping: "data",
                        //   header: "KPI Identifer/Key",
                        //   key: "label",
                        //   action: null,
                        //   hidden: false,
                        //   aggregate: null,
                        //   sorting: false,
                        //   filter: false,
                        //   pinned: true,
                        //   row_group: false,
                        // },
                        // {
                        //   type: "static_column",
                        //   mapping: "data",
                        //   header: "Versions",
                        //   key: "kpi_key",
                        //   action: null,
                        //   hidden: false,
                        //   aggregate: null,
                        //   sorting: false,
                        //   filter: false,
                        //   pinned: true,
                        //   row_group: false,
                        // },
                      ],
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Product Hierarchy",
                      key: "roll_up_product_hierarchy",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: false,
                      checkbox_selection: false,
                      row_group: false,
                      minWidth: 350,
                      wrapText: true,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Channel Aggregation",
                      key: "roll_up_channel",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: false,
                      checkbox_selection: false,
                      row_group: false,
                      minWidth: 300,
                      wrapText: true,
                    },

                    // {
                    //   type: "static_column",
                    //   mapping: "data",
                    //   header: "Bucket",
                    //   key: "total_bucket_aggregation_formula",
                    //   action: "",
                    //   hidden: false,
                    //   aggregate: null,
                    //   sorting: false,
                    //   filter: false,
                    //   pinned: false,
                    //   checkbox_selection: false,
                    //   row_group: false,
                    // },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Time Period Rule",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Monthly Totals",
                          key: "roll_up_timeline",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                          minWidth: 300,
                          wrapText: true,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Quarterly Totals",
                          key: "roll_up_timeline",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                          minWidth: 300,
                          wrapText: true,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Year To Date Totals",
                          key: "roll_up_timeline",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                          minWidth: 300,
                          wrapText: true,
                        },
                      ],
                    },
                  ],
                },
              },
              dataProps: {
                data: {
                  type: "derived",
                  dataKey: "kpiConfigurationList",
                  // subjectId: "data",
                  // subjectidNested: "data",
                  dataType: "array",
                },
              },
            },
          ],
        },
      },
      {
        type: "div",
        id: "bottom-buttons",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            marginTop: "10px",
            display: "flex",
            marginLeft: "65%",
          },
        },
        componentProps: {
          content: [
            {
              id: "cancel-basic-details-button",
              type: "button",
              staticProps: {
                variant: "secondary",
                label: "cancel",
                className: "create-store-group-button",
                display: {
                  type: "controlled",
                  default: {
                    show: true,
                    enabled: true,
                  },
                },
              },
              functionProps: [
                {
                  functionName: "onClick",
                  actions: [
                    {
                      type: "redirect",
                      link: "landing-screen",
                    },
                  ],
                },
              ],
            },

            {
              alignment: "right",
              id: "strategy-workbench-create-button",
              type: "button",
              staticProps: {
                variant: "secondary",
                label: "Save As Draft",
                customStyle: {
                  border: "1px solid",
                },
                display: {
                  type: "controlled",
                  default: {
                    show: true,
                    enabled: true,
                  },
                },
              },
            },

            {
              id: "edit-store-group-submit-button",
              type: "button",
              staticProps: {
                variant: "primary",
                label: "Next",
                customStyle: {
                  marginLeft: "20px",
                },
                className: "create-store-group-button",
                display: {
                  type: "controlled",
                  default: {
                    show: true,
                    enabled: true,
                  },
                },
              },
              functionProps: [
                {
                  functionName: "onClick",
                  actions: [
                    {
                      type: "reducer_function",
                      responseFormatter: [
                        {
                          destination: "reducer",
                          dataKey: "screenName",
                          value: "AGGREGATION_STRATERGY",
                          dataType: "basic",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
};
