export const MODULE_CONFIGURATION = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-sub-container",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            margin: "2px 10px",
            border: "1px solid",
            borderRadius: "10px",
            borderColor: "#808080",
            //height: "600px",
          },
        },
        componentProps: {
          content: [
            {
              type: "div",
              id: "basic-details-sub-container",

              staticProps: {
                className: "common-content-container ",
                customStyle: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h3",
                      content: "Module Configuration",
                    },
                  },

                  {
                    type: "div",
                    id: "basic-details-header-left",
                    staticProps: {
                      customStyle: {
                        display: "flex",
                        width: "400px",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          staticProps: {
                            content: "Config Completed",
                            customStyle: {
                              fontSize: "14px",
                            },
                          },
                        },
                        {
                          type: "text",
                          staticProps: {
                            content: "0%",
                            customStyle: {
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              fontSize: "18px",
                              border: "1px solid black",
                              borderRadius: "10px",
                              marginLeft: "20px",
                            },
                          },
                        },
                        {
                          id: "decision-dashboard-edit-button",
                          type: "iconButton",
                          staticProps: {
                            variant: "secondary",
                            icon: "help",
                            customStyle: {
                              backgroundColor: "white",
                              width: "34px",
                              height: "34px",
                              marginLeft: "10px",
                              marginBottom: "5px",
                              borderRadius: "50%",
                              color: "black",
                              transform: "scale(1.2)",
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "slect-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              staticProps: {
                customStyle: {
                  display: "flex",
                  marginLeft: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h4",
                      content: "Selected modules to be enabled",
                    },
                  },
                ],
              },
            },
            {
              type: "moduleConfigurationUI",
              dataProps: {
                data: {
                  type: "derived",
                  dataKey: "moduleData",
                  subjectId: "1",
                  subjectidNested: "array_agg",
                  dataType: "array",
                },
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "cancel-next-button-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginRight: "100px",
                },
              },
              componentProps: {
                content: [
                  {
                    alignment: "right",
                    id: "strategy-workbench-create-button",
                    type: "button",
                    staticProps: {
                      variant: "primary",
                      label: "Cancel",
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "landing-screen",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    id: "strategy-workbench-create-button",
                    type: "button",
                    staticProps: {
                      variant: "primary",
                      label: "Next",
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                      customStyle: {
                        marginLeft: "10px",
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "api_function",
                            runOnLoad: false,
                            apiEndPoint: "model-update",
                            apiMethod: "post",
                            params: [
                              {
                                source: "fixed",
                                dataType: "object",
                                apiRequestKey: "id",
                                value: 69,
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "id",
                                sourceId: "isCheckedValue",
                                subjectId: "id",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "is_deleted",
                                sourceId: "isCheckedValue",
                                subjectId: "val",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 70,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "null",
                    dataType: "basic",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "moduleData",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
