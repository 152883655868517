export const STORE_GROUP_CONFIG_VIEW = {
	screen_composition: {
		components: [
			{
				id: "store-group-config-view-filter",
				type: "filter",
				staticProps: {
					showSaveFilter: false,
					showPageHeader: true,
					// showFilterSelection: true,
					showSaveFilter: true,
					filterHeader: "Store Group Filters",
					pageHeader: "Store Group Configuration",
					accordionLabel: "Store Group Configuration",
					showFilterButtons: true,
					showFilterChips: true,
					filter_config: [
						{
							type: "store_h1",
							is_mandatory: true,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "store_h2",
							is_mandatory: true,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "store_h3",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "store_h4",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "store_h5",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
					],
				},
				functionProps: [
					{
						functionName: "onFilter",
						actions: [
							{
								type: "api_function",
								apiEndPoint: "get-store-groups",
								apiMethod: "post",
								params: [
									{
										source: "filters",
										dataType: "object",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "storegroups",
										overwrite: true,
										dataType: "array",
									},
								],
								onComplete: {
									actions: [
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"store-group-config-download-button",
																dataType:
																	"object",
																value: {
																	show: true,
																},
																overwrite: false,
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"filterModalOpen",
																value: false,
															},
														],
													},
												],
											},
										},
									],
								},
							},
							{
								type: "api_function",
								apiEndPoint: "get-ungrouped-stores-details",
								apiMethod: "post",
								params: [
									{
										source: "filters",
										dataType: "object",
									},
									{
										source: "fixed",
										dataType: "basic",
										apiRequestKey: "only_meta_data",
										value: true,
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "ungrouped_meta_detail",
										overwrite: true,
										dataType: "object",
									},
								],
							},
						],
					},
					{
						functionName: "onCancel",
						actions: [],
					},
				],
				dataProps: {
					filterModalOpen: {
						type: "derived",
						dataKey: "filterModalOpen",
					},
				},
			},
			{
				id: "grid-wrapper-div",
				type: "div",
				staticProps: {
					className: "common-content-container",
				},
				componentProps: {
					content: [
						{
							id: "store_group_config_view",
							type: "aggrid",
							staticProps: {
								height: "400px",
								tableId: "store_group_config_view",
								rowSelection: "multiple",
								tableTitle: "Store Group List",
								uniqueKey: "store_group_id",
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "",
											key: "",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: false,
											filter: false,
											pinned: true,
											lockPinned: true,
											checkbox_selection: true,
											row_group: false,
											suppressMenu: true,
											maxWidth: 50,
											cellStyle: {
												background: "#f7f7f7",
											},
										},
										{
											type: "static_column",
											mapping: "data",
											header: "",
											key: "store_group_id",
											action: "",
											hidden: true,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											checkbox_selection: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Group Name",
											key: "store_group_name",
											action: "",
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											checkbox_selection: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Stores",
											key: "stores_count",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Created By",
											key: "created_by_user",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Created On",
											data_type: "date",
											key: "created_at",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Modified By",
											key: "modified_by_user",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Modified On",
											data_type: "date",
											key: "modified_at",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 1,
											minWidth: 250,
										},
									],
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey: "storegroups",
								},
							},
							dependantControls: [
								{
									action: "rowSelection",
									controls: [
										{
											id: "store-group-config-view-edit-button",
											selection: "single",
											switchKey: "show",
											value: true,
										},
										{
											id: "store-group-config-view-delete-button",
											selection: "multiple",
											switchKey: "show",
											value: true,
										},
										{
											id: "store-group-config-view-create-button",
											selection: "none",
											switchKey: "show",
											value: true,
										},
									],
								},
							],
							componentProps: {
								content: [
									{
										alignment: "right",
										id: "store-group-config-view-ungrouped-details",
										type: "progress-bar",
										staticProps: {
											className: "ungrouped-stores",
											variant: "small",
											textContent:
												"stores are not in any group",
										},
										dataProps: {
											data: {
												type: "derived",
												dataKey:
													"ungrouped_meta_detail",
											},
										},
									},
									{
										alignment: "right",
										id: "store-group-config-view-create-button",
										type: "button",
										staticProps: {
											variant: "primary",
											label: "Create New Group",
											display: {
												type: "controlled",
												default: {
													show: true,
													enabled: true,
												},
											},
											customStyle: {
												width: "180px",
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "redirect",
														link: "store-group-configuration/create-store-group",
													},
												],
											},
										],
									},
									{
										alignment: "right",
										id: "store-group-config-download-button",
										type: "iconButton",
										staticProps: {
											variant: "primary",
											iconClass: "colorWhite",
											icon: "file_download",
											className: "icon-button",
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: true,
												},
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "api_function",
														apiEndPoint:
															"report-download",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																apiRequestKey:
																	"id",
																value: 3,
																isDownload: true,
																dataType:
																	"basic",
															},

															{
																source: "filters",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
															},
															{
																source: "fixed",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"report_name",
																value: "store_group_report",
															},
														],
														responseFormatter: [],
													},
												],
											},
										],
									},
									{
										alignment: "right",
										id: "store-group-config-view-edit-button",
										type: "iconButton",
										staticProps: {
											variant: "primary",
											iconClass: "colorWhite",
											icon: "edit",
											className: "icon-button",
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: true,
												},
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														params: [
															{
																source: "table",
																sourceId:
																	"store_group_config_view",
																apiRequestKey:
																	"store_group_id",
																dataType:
																	"basic",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"create_store_group_details",
																overwrite: true,
																dataType:
																	"object",
															},
														],
														onComplete: {
															actions: [
																{
																	type: "redirect",
																	link: "store-group-configuration/create-store-group",
																},
															],
														},
													},
												],
											},
										],
									},
									// {
									// 	alignment: "right",
									// 	id: "store-group-config-view-delete-button",
									// 	type: "iconButton",
									// 	staticProps: {
									// 		variant: "primary",
									// 		icon: "delete",
									// 		className: "icon-button",
									// 		display: {
									// 			type: "controlled",
									// 			default: {
									// 				show: false,
									// 				enabled: true,
									// 			},
									// 		},
									// 	},
									// 	functionProps: [
									// 		{
									// 			functionName: "onClick",
									// 			actions: [
									// 				{
									// 					type: "api_function",
									// 					apiEndPoint: "model",
									// 					apiMethod: "post",
									// 					params: [
									// 						{
									// 							source: "fixed",
									// 							apiRequestKey:
									// 								"id",
									// 							value: 2001,
									// 							dataType:
									// 								"basic",
									// 						},
									// 						{
									// 							source: "table",
									// 							sourceId:
									// 								"store_group_config_view",
									// 							apiRequestKey:
									// 								"parameters",
									// 							apiRequestKeyNested:
									// 								"store_group_id",
									// 							subjectId:
									// 								"store_group_id",
									// 							dataType:
									// 								"array",
									// 						},
									// 					],
									// 					responseFormatter: [
									// 						{
									// 							destination:
									// 								"reducer",
									// 							dataKey:
									// 								"affected_strategies_if_deleted",
									// 						},
									// 					],
									// 					onComplete: {
									// 						actions: [
									// 							{
									// 								type: "validation",
									// 								conditions:
									// 									[
									// 										{
									// 											comparison:
									// 												"exists",
									// 											source: "response",
									// 										},
									// 									],
									// 								onValidationSuccess:
									// 									{
									// 										actions:
									// 											[
									// 												{
									// 													type: "reducer_function",
									// 													responseFormatter:
									// 														[
									// 															{
									// 																destination:
									// 																	"reducer",
									// 																dataKey:
									// 																	"showValidationModalForDelete",
									// 																dataType:
									// 																	"basic",
									// 																value: true,
									// 																overwrite: true,
									// 															},
									// 														],
									// 												},
									// 											],
									// 									},
									// 							},
									// 							{
									// 								type: "validation",
									// 								conditions:
									// 									[
									// 										{
									// 											comparison:
									// 												"notExists",
									// 											source: "response",
									// 										},
									// 									],
									// 								onValidationSuccess:
									// 									{
									// 										actions:
									// 											[
									// 												{
									// 													type: "api_function",
									// 													apiEndPoint:
									// 														"store-groups-delete",
									// 													apiMethod:
									// 														"put",
									// 													params: [
									// 														{
									// 															source: "table",
									// 															sourceId:
									// 																"store_group_config_view",
									// 															apiRequestKey:
									// 																"store_group_id",
									// 															dataType:
									// 																"array",
									// 														},
									// 													],
									// 													onComplete:
									// 														{
									// 															actions:
									// 																[
									// 																	{
									// 																		type: "api_function",
									// 																		apiEndPoint:
									// 																			"get-store-groups",
									// 																		apiMethod:
									// 																			"post",
									// 																		params: [
									// 																			{
									// 																				source: "filters",
									// 																				dataType:
									// 																					"object",
									// 																			},
									// 																		],
									// 																		responseFormatter:
									// 																			[
									// 																				{
									// 																					destination:
									// 																						"reducer",
									// 																					dataKey:
									// 																						"storegroups",
									// 																					overwrite: true,
									// 																					dataType:
									// 																						"array",
									// 																				},
									// 																			],
									// 																	},
									// 																	{
									// 																		type: "api_function",
									// 																		apiEndPoint:
									// 																			"get-ungrouped-stores-details",
									// 																		apiMethod:
									// 																			"post",
									// 																		params: [
									// 																			{
									// 																				source: "filters",
									// 																				dataType:
									// 																					"object",
									// 																			},
									// 																			{
									// 																				source: "fixed",
									// 																				dataType:
									// 																					"basic",
									// 																				apiRequestKey:
									// 																					"only_meta_data",
									// 																				value: true,
									// 																			},
									// 																		],
									// 																		responseFormatter:
									// 																			[
									// 																				{
									// 																					destination:
									// 																						"reducer",
									// 																					dataKey:
									// 																						"ungrouped_meta_detail",
									// 																					overwrite: true,
									// 																					dataType:
									// 																						"object",
									// 																				},
									// 																			],
									// 																	},
									// 																],
									// 														},
									// 												},
									// 											],
									// 									},
									// 							},
									// 						],
									// 					},
									// 				},
									// 			],
									// 		},
									// 	],
									// },
								],
							},
						},
					],
				},
			},
			//  Modal
			{
				type: "modal",
				staticProps: {
					className: "",
					modalTitle: "Delete Store Group",
					primaryButtonText: "Proceed with Deleting",
					secondaryButtonText: "Cancel",
					modalSize: "large",
				},
				componentProps: {
					content: [
						{
							id: "",
							isDefaultExport: false,
							type: "text",
							staticProps: {
								className: "",
								variant: "paragraph",
								content:
									"Based on the data feed received, these are the affected strategies with this deletion",
							},
						},
						{
							id: "store_group_delete_validation",
							type: "aggrid",
							staticProps: {
								height: "300px",
								tableId: "store_group_delete_validation",
								tableTitle: "",
								uniqueKey: "strategy_id",
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "Strategy Name",
											key: "strategy_name",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 1,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Store Group",
											key: "sg_name",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 1,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Status",
											key: "status",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 2,
										},
									],
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey: "affected_strategies_if_deleted",
									dataType: "array",
								},
							},
							componentProps: {},
						},
					],
				},
				functionProps: [
					{
						functionName: "onConfirm",
						actions: [
							{
								type: "api_function",
								apiEndPoint: "store-groups-delete",
								apiMethod: "put",
								params: [
									{
										source: "table",
										sourceId: "store_group_config_view",
										apiRequestKey: "store_group_id",
										dataType: "array",
									},
								],
								onComplete: {
									actions: [
										{
											type: "api_function",
											apiEndPoint: "get-store-groups",
											apiMethod: "post",
											params: [
												{
													source: "filters",
													dataType: "object",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "storegroups",
													overwrite: true,
													dataType: "array",
												},
											],
										},
										{
											type: "api_function",
											apiEndPoint:
												"get-ungrouped-stores-details",
											apiMethod: "post",
											params: [
												{
													source: "filters",
													dataType: "object",
												},
												{
													source: "fixed",
													dataType: "basic",
													apiRequestKey:
														"only_meta_data",
													value: true,
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"ungrouped_meta_detail",
													overwrite: true,
													dataType: "object",
												},
											],
										},
									],
								},
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "showValidationModalForDelete",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
					{
						functionName: "onDeny",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"affected_strategies_if_deleted",
										overwrite: true,
										dataType: "array",
										value: null,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "showValidationModalForDelete",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "showValidationModalForDelete",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
				],
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "showValidationModalForDelete",
					},
				},
			},
		],
	},
};
