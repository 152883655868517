import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import uuid from "react-uuid";
import { useNavigate } from "react-router-dom-v5-compat";
import { connect } from "react-redux";
import { AppBar, styled, Avatar } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { signOut } from "../../../store/features/auth/auth";
import Divider from "@mui/material/Divider";
import {
  getNotifications,
  removeLoader,
  requestCompleteNoLoader,
  requestFailNoLoader,
  addNewNotification,
  toastError,
  getUserHierarchy,
  getGlobalConfig,
  toggleLengthyOpLoader,
  setNewSseConnection,
  getGlobalConfigOptions,
  updateNotificationStatus,
  updateNotificationReadStatus,
} from "../../../store/features/global/global";
import logo from "./../../../assets/images/color-logo.png";
import { setRetainFilters } from "../../../store/features/filters/filters";
import Breadcrumbs from "../header/Breadcrumbs";
import "./Header.scss";
import { loadResponseIntoStore } from "../../../utils/helpers/dynamicActionHelper";
import { invokeReducerAction } from "../../../store/features/appReducer/appReducer";
import Notification from "../notification/Notification";

const { REACT_APP_API_BASE_URL } = process.env;

const StyledAppBar = styled(AppBar)((theme) => ({
  padding: "0px",
  background: "#fff",
  boxShadow: "0px 1px 4px #00000029",
  height: "72px",
  position: "fixed",
  width: "calc(100% - 72px)",
  left: "72px",
}));

const getGuid = () => {
  const id = uuid(); // generate unique UUID
  sessionStorage.setItem("UNIQ_SSE_KEY", id);
  return id;
};

const Header = (props) => {
  const strategyIdRef = useRef(null);
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [newNotificationCircle, showNewNotificationCirlce] = useState(false);

  function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null; // Return null if the cookie is not found
  }

  useEffect(() => {
    if (props.step_1_A?.strategy_id) {
      strategyIdRef.current = props.step_1_A.strategy_id;
    }
  }, [props?.step_1_A?.strategy_id]);

  // Adding event listener for SSE - Lock/Unlock & Override/Finalize
  useEffect(() => {
    let cookieValue = getCookie("pricesmart-ia-token");
    let token = localStorage.getItem("token");
    if (_.isEmpty(token)) {
      token = cookieValue;
    }
    const uniqueId = getGuid();
    const sseListener = new EventSource(
      REACT_APP_API_BASE_URL +
        "/sse-output?guid=" +
        uniqueId +
        "&token=" +
        token,
      {
        authorizationHeader: `Bearer ${token}`,
      }
    );
    establishSseConnection(sseListener);

    return () => {
      sseListener.close();
      sessionStorage.removeItem("UNIQ_SSE_KEY");
    };
  }, []);

  useEffect(() => {
    if (props.newSseConnection) {
      const uniqueId = getGuid();
      const token = localStorage.getItem("token");
      const sseListener = new EventSource(
        REACT_APP_API_BASE_URL +
          "/sse-output?guid=" +
          uniqueId +
          "&token=" +
          token,
        {
          // withCredentials: true,
        }
      );
      establishSseConnection(sseListener);
      props.setNewSseConnection(false);
    }
  }, [props.newSseConnection]);

  const establishSseConnection = (sseListener) => {
    const maxReconnectTries = 100;
    let reconnectAttempts = 0;
    sseListener.onmessage = (e) => {
      // Check event type and parse accordingly
      const parsedResponse = JSON.parse(e.data);
      const eventsComplete = [];
      const newNotifications = [];

      !_.isEmpty(parsedResponse) &&
        !_.isEmpty(parsedResponse.messages) &&
        parsedResponse.messages.map((eventResponse) => {
          if (eventResponse.action === "optimisation") {
            showNewNotificationCirlce(true);
            if (eventResponse.status === 200) {
              props.requestCompleteNoLoader(eventResponse.message);
            } else {
              props.toggleLengthyOpLoader(false);
              props.toastError(eventResponse.message);
            }
          } else if (eventResponse.action === "simulation") {
            const optimiseEvent = _.cloneDeep(eventResponse);
            optimiseEvent.read_by = null;
            optimiseEvent.is_broadcast_message = 0;
            newNotifications.push(optimiseEvent);
            loadResponseIntoStore(
              [
                {
                  destination: "reducer",
                  dataKey: "simulation_sse_response",
                },
              ],
              eventResponse
            );
            if (eventResponse.status === 200) {
              props.toggleLengthyOpLoader(false);
              props.requestCompleteNoLoader(eventResponse.message);
            } else {
              props.requestFailNoLoader(eventResponse.message);
            }
          } else if (eventResponse.action === "download") {
            showNewNotificationCirlce(true);
            if (eventResponse.status === 200) {
              props.requestCompleteNoLoader(eventResponse.message);
            } else {
              props.toastError(eventResponse.message);
            }
          } else if (
            eventResponse.status === 200 &&
            eventResponse.action === "data_load" &&
            eventResponse.strategy_id === strategyIdRef.current
          ) {
            loadResponseIntoStore(
              [
                {
                  destination: "reducer",
                  dataKey: "step_4_table_load_status",
                  subjectId: "data_present",
                },
              ],
              true
            );
          } else if (eventResponse.status !== 200) {
            console.log("Error", eventResponse);
            props.removeLoader();
            props.toastError(eventResponse.message);
          }
        });
      if (newNotifications.length > 0) {
        props.addNewNotification(newNotifications);
      }
    };
    sseListener.onerror = (e) => {
      console.log(e);
      if (reconnectAttempts > maxReconnectTries) {
        sseListener.close();
        sessionStorage.removeItem("UNIQ_SSE_KEY");
        props.toastError(
          "There is an issue establishing SSE connection. Please contact Pricesmart team for details."
        );
      } else {
        console.log("Reconnecting....");
        reconnectAttempts++;
      }
    };
  };

  let userName = "User";
  if (localStorage.getItem("user")) {
    userName = localStorage.getItem("user");
    if (userName.includes(",")) {
      let userNameArray = userName.split(",");
      userName = userNameArray.length > 1 ? userNameArray[1] : userNameArray[0];
    } else if (userName.includes(" ")) {
      userName = userName.split(" ")?.[0];
    }
  }

  const [showNotifications, toggleNotifications] = useState(false);

  const handleNotificationIconClick = async () => {
    if (!showNotifications) {
      const res = await props.getNotifications(false);
    } else {
      showNewNotificationCirlce(false);
    }
    toggleNotifications(!showNotifications);
  };

  useEffect(() => {
    props.getGlobalConfig();

    // Get all notifications for user
    props.getNotifications(false);
  }, []);

  useEffect(() => {
    if (props.notifications && !_.isEmpty(props.notifications.notifications)) {
      let { notifications } = props;
      let newNotifications = _.filter(
        notifications.notifications,
        (notification) => {
          if (
            notification.module === "strategy" ||
            notification.module === "REPORT"
          ) {
            return true;
          }
        }
      );
      setNotifications(() => _.cloneDeep(newNotifications));
      let unreadCount = 0;
      let unreadEventNotifation = [];

      props.notifications.notifications.forEach((notification) => {
        //add unread notification count unread = (is_broadcast_message === 0 && isNull(read_at))
        if (
          notification?.is_broadcast_message === 0 &&
          _.isNull(notification.read_at)
        ) {
          // count all unread notications
          unreadCount++;
        }
        if (
          notification.module == "EVENT_AUTO_RESIMULATION" &&
          _.isNull(notification.read_by) &&
          !notification.is_sse_notification
        ) {
          // event auto resimulation unread notifications
          unreadEventNotifation.push(notification.uuid);
        }
      });

      const reqObj = {
        uuid: unreadEventNotifation,
      };

      if (unreadEventNotifation.length > 0) {
        props.updateNotificationStatus(reqObj);
        const unreadEventNotifationcount = unreadEventNotifation.length;
        // subtract event auto resimulation unread notifications count from all unread notification count
        unreadCount = unreadCount - unreadEventNotifationcount;
      }
    }
  }, [props.notifications]);

  const sortNotifications = (type) => {
    const notificationData = _.cloneDeep(notifications);

    if (type === "descending") {
      notificationData.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }
    if (type === "ascending") {
      notificationData.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    }
    setNotifications([...notificationData]);
  };

  const markAllAsReadHandler = async () => {
    await props.updateNotificationReadStatus({
      parameters: { mark_all_as_read: true },
    });
    props.getNotifications();
  };

  const handleNotificationClick = async (notification) => {
    await props.updateNotificationReadStatus({
      parameters: { notification_ids: [notification.notification_id] },
    });
    props.getNotifications();
    toggleNotifications(false);
    showNewNotificationCirlce(false);
    if (notification.module === "strategy") {
      // Redirect to create strategy
      let strategyValidationPayload = {
        id: 3088,
        parameters: {
          strategy_id: [notification.navigate_to],
        },
      };

      const strategyValidationResponse = await props.invokeReducerAction({
        payload: strategyValidationPayload,
        apiMethod: "post",
        apiEndPoint: "model",
      });

      if (strategyValidationResponse) {
        // Fix for loading a strategy from notification when in create-strategy page.
        loadResponseIntoStore(
          [
            {
              destination: "reducer",
              dataKey: "step_1_A",
              subjectId: "step_count",
            },
          ],
          null
        );
        loadResponseIntoStore(
          [
            {
              destination: "reducer",
              dataKey: "step_1_A",
              subjectId: "currentStep",
            },
          ],
          null
        );
        loadResponseIntoStore(
          [
            {
              destination: "reducer",
              dataKey: "step_1_A",
              subjectId: "strategy_id",
            },
          ],
          null
        );
        // ---- fix end
        loadResponseIntoStore(
          [
            {
              destination: "reducer",
              dataKey: "step_1_A",
              subjectId: "step_count",
            },
          ],
          notification.strategy_step_count
        );
        loadResponseIntoStore(
          [
            {
              destination: "reducer",
              dataKey: "step_1_A",
              subjectId: "strategy_id",
            },
          ],
          notification.navigate_to
        );
        navigate("/workbench/create-strategy");
      }
    } else if (notification.module === "REPORT") {
      if (_.isNull(notification.read_by)) {
        const reqObj = {
          uuid: [notification.uuid],
        };

        await props.updateNotificationStatus(reqObj);
      }

      if (notification.download_url) {
        window.open(notification.download_url, "_self");
      }
    }
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: "#033162",
        width: "48px",
        height: "48px",
        boxShadow: "0px 0px 6px #00000029",
        textAlign: "left",
        fontSize: "16px",
        lineHeight: "25px",
        fontWeight: "600",
        letterSpacing: "0px",
        color: "#FFFFFF",
      },
      children: `${name.trim().charAt(0)}`,
    };
  };

  return (
    <>
      <StyledAppBar>
        <div className="navbar-brand">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "15vw",
            }}
            onClick={() => {
              navigate("/landing-screen");
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ height: "30px", width: "auto" }}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <Typography
              component="h1"
              className="brand__title"
              sx={{ fontWeight: "600", fontSize: "16px" }}
            >
              PlanSmart
            </Typography>
          </div>

          <ul className="navbar-nav">
            <li
              title="Notifications"
              className={`nav-item position-relative notificationIconContainer`}
              style={{
                backgroundColor: showNotifications ? "#e5edf7" : "#FFFFFF",
              }}
              id="notification-nav-item"
            >
              {newNotificationCircle && <span className="notification-count" />}
              <NotificationsIcon
                className={`notificationIcon ${
                  showNotifications ? "noPointerEvents" : ""
                }`}
                sx={{
                  width: "32px",
                  height: "32px",
                  cursor: "pointer",
                  color: `${showNotifications ? "#0055AF" : "#C8CED0"}`,
                  marginTop: "8px",
                }}
                onClick={() => handleNotificationIconClick()}
              />
            </li>

            <li className="nav-item">
              <Avatar {...stringAvatar(userName)} />
            </li>
            <li className="nav-item">Hi {userName} </li>
          </ul>
        </div>
        {showNotifications && (
          <Notification
            notifications={notifications}
            onNotificationClick={handleNotificationClick}
            closeNotification={handleNotificationIconClick}
            sortNotifications={sortNotifications}
            markAllAsReadHandler={markAllAsReadHandler}
          />
        )}
      </StyledAppBar>
      <Breadcrumbs />
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    notifications: store.global.notifications,
    elementLabels: store.global.elementLabels,
    newSseConnection: store.global.newSseConnection,
    step_1_A: store?.app?.step_1_A,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => {
      dispatch(signOut());
    },
    getNotifications: (data) => {
      dispatch(getNotifications(data));
    },
    updateNotificationReadStatus: (data) => {
      dispatch(updateNotificationReadStatus(data));
    },
    requestCompleteNoLoader: (message) =>
      dispatch(requestCompleteNoLoader(message)),
    requestFailNoLoader: (message) => dispatch(requestFailNoLoader(message)),
    removeLoader: () => dispatch(removeLoader()),
    setRetainFilters: (data) => dispatch(setRetainFilters(data)),
    addNewNotification: (notificationList) =>
      dispatch(addNewNotification(notificationList)),
    toastError: (data) => dispatch(toastError(data)),
    getUserHierarchy: (data) => dispatch(getUserHierarchy(data)),
    getGlobalConfig: (data) => dispatch(getGlobalConfig(data)),
    toggleLengthyOpLoader: (data) => dispatch(toggleLengthyOpLoader(data)),
    // setSimulatedPromoId: (data) => dispatch(setSimulatedPromoId(data)),
    setNewSseConnection: (data) => dispatch(setNewSseConnection(data)),
    updateNotificationStatus: (data) =>
      dispatch(updateNotificationStatus(data)),
    getGlobalConfigOptions: (data) => dispatch(getGlobalConfigOptions(data)),
    invokeReducerAction: (data) => dispatch(invokeReducerAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
