export const OUT_OFTHE_BOX_SIDEBAR = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "kpi-master-list",
        staticProps: {
          customStyle: {
            display: "flex",
            justifyContent: "space-between",
          },
        },
        componentProps: {
          content: [
            {
              type: "div",
              id: "decision-dashboard-content",
              staticProps: {
                className: "common-content-container",
              },
            },
          ],
        },
      },
      {
        type: "div",
        id: "workbench-metrics-table",
        staticProps: {
          className: "common-content-container",
          customStyle: {
            marginTop: "20px",
          },
        },
        componentProps: {
          content: [
            {
              type: "outofTheBoxSidebar",
            },
          ],
        },
      },

      {
        type: "div",
        id: "decision-dashboard-content",
        staticProps: {
          className: "common-content-container",
          customStyle: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          },
        },
      },
    ],
  },
};
