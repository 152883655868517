import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import moment from "moment";

import "./Notification.scss";

import ButtonComponent from "../../ui/button/Button";

function Notification(props) {
	const [isSortByOpen, sortByStateHandler] = useState(false);
	const notificationRef = useRef(null);

	function useOutsideAlerter(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					props.closeNotification();
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	useOutsideAlerter(notificationRef);

	const highlightText = (text, searchTerm) => {
		const regex = new RegExp(`(${searchTerm})`, "gi");
		const newText = text.split(regex).map((part, index) => {
			return regex.test(part) ? (
				<span className="url-text" key={index}>
					{part}
				</span>
			) : (
				part
			);
		});

		return newText;
	};

	return (
		<div className="notificationsContainer" ref={notificationRef}>
			<div className="flex-spaceBetween marginBottom-20 padding-horizontal-15">
				<span className="text-16px-600">Notifications</span>
				<ButtonComponent
					variant="url"
					label="Mark all as read"
					onClick={props.markAllAsReadHandler}
					customStyle={{
						fontSize: "12px",
						color: "#0055AF",
						padding: 0,
						textTransform: "unset",
					}}
				/>
			</div>
			<div className="horizontalLine" />
			<div
				className="urlButton position-right padding-horizontal-15 padding-vertical-10"
				onClick={() => sortByStateHandler(!isSortByOpen)}
				style={{ fontSize: "12px" }}
			>
				Sort By{" "}
				<span
					class="material-icons"
					style={{
						fontSize: "12px",
						transform: isSortByOpen ? "rotate(180deg)" : "unset",
					}}
				>
					expand_more
				</span>
				{isSortByOpen ? (
					<div className={`sortByOptionsContainer`}>
						<div
							className="text-14px-black"
							onClick={() => props.sortNotifications("ascending")}
						>
							Oldest First
						</div>
						<div
							className="text-14px-black"
							onClick={() =>
								props.sortNotifications("descending")
							}
						>
							Latest First
						</div>
					</div>
				) : null}
			</div>
			{props.notifications.length ? (
				<div className="notificationsSection">
					{_.map(props.notifications, (notification) => {
						let notificationName = notification.name
							? notification.name.trim()
							: notification.name;
						const highlightedText = highlightText(
							notification.message,
							notificationName
						);
						let iconBGColor =
							notification.module === "REPORT"
								? "#24a147"
								: "#0055AF";
						return (
							<div>
								<div
									className="notificationContainer"
									key={notification.uuid}
									style={{
										backgroundColor: notification?.read_at
											? "#FFFFFF"
											: "#F3F9FF",
									}}
									onClick={() =>
										props.onNotificationClick(notification)
									}
								>
									<div className="notificationHeader">
										<span
											className="circle-10"
											style={{
												visibility:
													notification?.read_at
														? "hidden"
														: "unset",
												backgroundColor:
													notification.execution_status
														? iconBGColor
														: "#DA1E28",
											}}
										/>
										<span
											className="notificationTextIcon"
											style={{
												background:
													notification.execution_status
														? iconBGColor
														: "#DA1E28",
											}}
										>
											{notification.header_text.charAt(0)}
										</span>
										<span>
											<p className="margin-0 text-14px-black">
												{notification.header_text}
											</p>
											<p className="margin-0 notificationTime">
												{moment(
													notification.created_at
												).format("DD/MM/YYYY")}{" "}
												-{" "}
												{moment(
													notification.created_at
												).format("h:mm a")}
											</p>
										</span>
									</div>
									<p className="notificationMessage text-14px-black">
										{highlightedText}
									</p>
								</div>
								<div
									className="horizontalLine"
									style={{ margin: "0 10px" }}
								/>
							</div>
						);
					})}
				</div>
			) : (
				<div className="noNotifications">
					No new notifications available.
				</div>
			)}
		</div>
	);
}

export default Notification;
