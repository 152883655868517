//Snackbar
export const ADD_SNACK = "ADD_SNACK";
export const REMOVE_SNACK = "REMOVE_SNACK";
export const CLOSE_SNACK = "CLOSE_SNACK";

//Tenant Info
export const TENANT_FETCH_INIT = "TENANT_FETCH_INIT";
export const TENANT_FETCH_SUCCESS = "TENANT_FETCH_SUCCESS";
export const TENANT_FETCH_FAILED = "TENANT_FETCH_FAILED";

//User Session Info
export const CHECK_USER_SESSION_INIT = "CHECK_USER_SESSION_INFO_INIT";

//EmailLogin
export const EMAIL_LOGIN_FAILED = "EMAIL_LOGIN_FAILED";

export const LOADER = "LOADER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
