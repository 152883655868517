export const DIS_AGGREGATION_STRATERGY = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "kpi-master-list",
        staticProps: {
          customStyle: {
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          },
        },
        componentProps: {
          content: [
            {
              type: "SelectedHierarchy",
            },
            {
              type: "div",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "space-between",
                  width: "85px",
                },
              },
              componentProps: {
                content: [
                  {
                    id: "channel-remove-button",
                    type: "iconButton",
                    staticProps: {
                      variant: "outlined",
                      icon: "search",
                      // iconClass: "colorBlack",
                      // className: "icon-button",
                      customStyle: {
                        background: "white",
                        color: "#0055AF",
                        border: "1px solid #0055AF",
                      },
                    },
                  },
                  {
                    id: "channel-remove-button",
                    type: "iconButton",
                    staticProps: {
                      variant: "outlined",
                      icon: "publish",
                      // iconClass: "colorBlack",
                      // // className: "icon-button",
                      customStyle: {
                        background: "white",
                        color: "#0055AF",
                        border: "1px solid #0055AF",
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 66,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "reducer",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    sourceId: "selectedConfig",
                    subjectId: "id",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "kpiConfigurationList",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
              // {
              //   type: "reducer_function",
              //   responseFormatter: [
              //     {
              //       destination: "reducer",
              //       dataKey: "loadEditAggregationStrategy",
              //       value: false,
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        type: "div",
        id: "workbench-metrics-table",
        staticProps: {
          className: "common-content-container",
          customStyle: {
            marginTop: "20px",
          },
        },
        componentProps: {
          content: [
            {
              type: "aggrid",
              staticProps: {
                height: "600px",
                width: "100%",
                tableId: "decision_dashboard_strategies_product_count",
                rowSelection: "multiple",
                onClickCell: "navigate",
                uniqueKey: "",
                tableConfig: {
                  column_headers: [
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "",
                      key: "",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: true,
                      lockPinned: true,
                      checkbox_selection: true,
                      row_group: false,
                      suppressMenu: true,
                      maxWidth: 50,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "KPI Details",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "KPI",
                          key: "name",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: true,
                          row_group: false,
                          cellRenderer: "customIconClick",
                          cellRendererParams: {
                            actions: [
                              {
                                type: "reducer_function",
                                responseFormatter: [
                                  {
                                    destination: "reducer",
                                    dataKey: "loadEditDisaggregationStrategy",
                                    value: true,
                                  },
                                ],
                              },
                              {
                                type: "redirect",
                                link: "add-new-config",
                              },
                              {
                                type: "reducer_function",
                                params: [
                                  {
                                    source: "self",
                                    apiRequestKey: "selectedKpi",
                                    dataType: "basic",
                                  },
                                ],
                                responseFormatter: [
                                  {
                                    destination: "reducer",
                                    dataKey: "selectedKpi",
                                    apiResponseKey: "selectedKpi",
                                    dataType: "basic",
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Label",
                          key: "label",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: true,
                          row_group: false,
                        },
                        // {
                        //   type: "static_column",
                        //   mapping: "data",
                        //   header: "KPI Identifer/Key",
                        //   key: "label",
                        //   action: null,
                        //   hidden: false,
                        //   aggregate: null,
                        //   sorting: false,
                        //   filter: false,
                        //   pinned: true,
                        //   row_group: false,
                        // },
                        // {
                        //   type: "static_column",
                        //   mapping: "data",
                        //   header: "Versions",
                        //   key: "kpi_key",
                        //   action: null,
                        //   hidden: false,
                        //   aggregate: null,
                        //   sorting: false,
                        //   filter: false,
                        //   pinned: true,
                        //   row_group: false,
                        // },
                      ],
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Dimensions of Dis-Aggregation",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Time",
                          columns: [
                            {
                              type: "static_column",
                              mapping: "data",
                              header: "With 0's in Values",
                              key: "update_spread_logic",
                              action: null,
                              hidden: false,
                              aggregate: null,
                              sorting: false,
                              filter: false,
                              pinned: false,
                              row_group: false,
                              // cellRenderer: 'DualSelectCell',
                              // cellStyle: {
                              //   color: 'green'
                              // }
                            },
                            {
                              type: "static_column",
                              mapping: "data",
                              header: "Roll Down Time", //new column
                              key: "roll_down_timeline",
                              action: null,
                              hidden: false,
                              aggregate: null,
                              sorting: false,
                              filter: false,
                              pinned: false,
                              row_group: false,
                              minWidth: 100,
                              maxWidth: 250,
                              wrapText: true,
                              // cellRenderer: "CustomDropdownCellEditor",
                            },
                          ],
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Product Hierarchy",
                          key: "product_roll_down",
                          action: "",
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          checkbox_selection: false,
                          row_group: false,
                          minWidth: 100,
                          maxWidth: 250,
                          wrapText: true,
                          //cellRenderer: "CustomDropdownCellEditor",
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Update Formula",
                          key: "update_formula",
                          action: "",
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          checkbox_selection: false,
                          row_group: false,
                          // cellRenderer: "DualSelectCell",
                        },
                      ],
                    },
                  ],
                },
              },
              dataProps: {
                data: {
                  type: "derived",
                  dataKey: "kpiConfigurationList",
                  // subjectId: '0',
                  // subjectidNested: 'data',
                  dataType: "array",
                },
              },
            },
          ],
        },
      },
      {
        type: "div",
        id: "bottom-buttons",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            marginTop: "10px",
            display: "flex",
            marginLeft: "65%",
          },
        },
        componentProps: {
          content: [
            {
              id: "cancel-basic-details-button",
              type: "button",
              staticProps: {
                variant: "secondary",
                label: "cancel",
                className: "create-store-group-button",
                display: {
                  type: "controlled",
                  default: {
                    show: true,
                    enabled: true,
                  },
                },
              },
              functionProps: [
                {
                  functionName: "onClick",
                  actions: [
                    {
                      type: "redirect",
                      link: "landing-screen",
                    },
                  ],
                },
              ],
            },

            {
              alignment: "right",
              id: "strategy-workbench-create-button",
              type: "button",
              staticProps: {
                variant: "secondary",
                label: "Save As Draft",
                customStyle: {
                  border: "1px solid",
                },
                display: {
                  type: "controlled",
                  default: {
                    show: true,
                    enabled: true,
                  },
                },
              },
            },

            {
              id: "edit-store-group-submit-button",
              type: "button",
              staticProps: {
                variant: "primary",
                label: "Next",
                customStyle: {
                  marginLeft: "20px",
                },
                className: "create-store-group-button",
                display: {
                  type: "controlled",
                  default: {
                    show: true,
                    enabled: true,
                  },
                },
              },
            },
          ],
        },
      },
    ],
  },
};
