export const BASIC_DETAILS = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-content-emptystate",

        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "basic",
                    apiRequestKey: "id",
                    value: 70,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "null",
                    dataType: "basic",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "filterData",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
        componentProps: {
          content: [
            {
              type: "div",
              id: "basic-details-sub-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h1",
                      content: "config details",
                    },
                  },
                ],
              },
            },

            {
              id: "configDetails",
              type: "form",
              staticProps: {
                customStyle: {
                  width: "auto",
                },
                fields: [
                  {
                    type: "text",
                    id: "config_name",
                    placeholder: "Enter plan name here",
                    variant: "outlined",
                    isMandatory: true,
                    label: "Config Name",
                    showLabel: true,
                    staticProps: {
                      variant: "h1",
                      customStyle: {
                        fontSize: "20px",
                      },
                    },
                  },
                  {
                    type: "text",
                    id: "description",
                    placeholder: "Enter here...",
                    variant: "outlined",
                    label: "Config description(optional)",
                    showLabel: true,
                  },
                ],
              },
              dataProps: {
                config_name: {
                  type: "derived",
                  dataKey: "configDetails",
                  subjectId: "config_name",
                },
                description: {
                  type: "derived",
                  dataKey: "configDetails",
                  subjectId: "description",
                },
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
                color: "black",
              },
            },
            {
              type: "div",
              id: "basic-details-sub-container",
              staticProps: {
                className: "common-content-container ",
                customStyle: {
                  display: "flex",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h1",
                      content: "configuration for hierarchy",
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "select-basic-details-wrapper",
              staticProps: {
                customStyle: {
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "slect-basic-details",
                    componentProps: {
                      content: [
                        {
                          isDefaultExport: false,
                          type: "text",
                          staticProps: {
                            className: "common-page-title",
                            variant: "h4",
                            content: "Select Module",
                            customStyle: {
                              color: "#758490",
                              fontSize: "12px",
                              fontWeight: "100",
                              padding: "0 10px",
                            },
                          },
                        },
                        {
                          type: "basicDetailModuleSelection",
                          id: "select-module",
                          staticProps: {
                            loadFunctionName: "loadEnableOptions",
                            fromPage: "basicdetails",
                          },
                          dataProps: {
                            options: {
                              type: "derived",
                              dataKey: "filterData",
                              subjectId: "1",
                              subjectidNested: "array_agg",
                              dataType: "array",
                            },
                            selectedItem: {
                              type: "derived",
                              dataKey: "select-module",
                              subjectId: "selectedItems",
                              subjectidNested: "0",
                            },
                            selectedConfig: {
                              type: "derived",
                              dataKey: "selectedConfig",
                            },
                            selectedModule: {
                              type: "derived",
                              dataKey: "select-module",
                              subjectId: "selectedItems",
                              subjectidNested: "0",
                            },
                            selectedConfigDetails: {
                              type: "derived",
                              dataKey: "configDetails",
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "slect-channel-dropdown",
                    staticProps: {
                      customStyle: {
                        marginLeft: "10px",
                      },
                      display: {
                        type: "controlled",
                        default: {
                          show: false,
                        },
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          isDefaultExport: false,
                          type: "text",
                          staticProps: {
                            className: "common-page-title",
                            variant: "h4",
                            content: "Select Channel",
                            customStyle: {
                              color: "#758490",
                              fontSize: "12px",
                              fontWeight: "100",
                              padding: "0 10px",
                            },
                          },
                        },
                        {
                          type: "basicDetailModuleSelection",
                          id: "select-channel",
                          staticProps: {
                            loadFunctionName: "loadOnlySelectedChannel",
                            fromPage: "basicDetails",
                          },
                          dataProps: {
                            options: {
                              type: "derived",
                              dataKey: "filterData",
                              subjectId: "0",
                              subjectidNested: "array_agg",
                              dataType: "array",
                            },
                            selectedItem: {
                              type: "derived",
                              dataKey: "select-module",
                              subjectId: "selectedItems",
                              subjectidNested: "0",
                            },
                            selectedConfig: {
                              type: "derived",
                              dataKey: "selectedConfig",
                            },
                            selectedModule: {
                              type: "derived",
                              dataKey: "select-module",
                              subjectId: "selectedItems",
                              subjectidNested: "0",
                            },
                            selectedChannel: {
                              type: "derived",
                              dataKey: "select-channel",
                              subjectId: "selectedItems",
                              subjectidNested: "0",
                              dataType: "object",
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "slect-sub-channel-dropdown",
                    staticProps: {
                      customStyle: {
                        marginLeft: "10px",
                      },
                      display: {
                        type: "controlled",
                        default: {
                          show: false,
                        },
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          isDefaultExport: false,
                          type: "text",
                          staticProps: {
                            className: "common-page-title",
                            variant: "h4",
                            content: "Select Sub Channel",
                            customStyle: {
                              color: "#758490",
                              fontSize: "12px",
                              fontWeight: "100",
                              padding: "0 10px",
                            },
                          },
                        },
                        {
                          type: "select",
                          id: "select-sub-channel",
                          staticProps: {
                            isMultipleSelection: false,
                            hideClearSelection: true,
                            hideSearch: true,
                            updateSelectedOnEachSelection: true,
                            // options: [
                            //   {
                            //     value: "s1",
                            //     label: "Wholesale",
                            //   },
                            // ],
                            // selectedOptions: [
                            //   {
                            //     value: "s1",
                            //     label: "Wholesale",
                            //   },
                            // ],
                          },
                          dataProps: {
                            options: {
                              type: "derived",
                              dataKey: "filterData",
                              forSelect: "sub-channel",
                              subjectId: "2",
                              subjectidNested: "array_agg",
                              dataType: "array",
                            },
                          },
                          // functionProps: [
                          //   {
                          //     functionName: "onSelect",
                          //     actions: [
                          //       {
                          //         type: "reducer_function",
                          //         params: [
                          //           {
                          //             source: "self",
                          //             apiRequestKey: "selectedData",
                          //             dataType: "array",
                          //           },
                          //         ],
                          //         responseFormatter: [
                          //           {
                          //             destination: "reducer",
                          //             dataKey: "sub-channel-select",
                          //             apiResponseKey: "selectedData",
                          //             dataType: "array",
                          //             overwrite: true,
                          //           },
                          //         ],
                          //       },
                          //     ],
                          //   },
                          // ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-remove-channel-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "18px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "channel-remove-button",
                          type: "iconButton",
                          staticProps: {
                            // variant: "primary",
                            icon: "delete",
                            iconClass: "colorBlack",
                            // className: "icon-button",
                            customStyle: {
                              background: "none",
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: false,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "slect-channel-dropdown",
                                      // subjectId: "decision-dashboard-edit-button",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "select-channel",
                                      apiResponseKey: "selectedData",
                                      dataType: "array",
                                      overwrite: true,
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button-channel",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: "reducer_function",
                                  params: [
                                    {
                                      source: "reducer",
                                      sourceId: "select-module",
                                      subjectId: "selectedItems",
                                      subjectidNested: "0",
                                      dataType: "array",
                                    },
                                  ],
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "selectedConfig",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-remove-sub-channel-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "18px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "sub-channel-remove-button",
                          type: "iconButton",
                          staticProps: {
                            // variant: "primary",
                            icon: "close",
                            iconClass: "colorBlack",
                            // className: "icon-button",
                            customStyle: {
                              background: "none",
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: false,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "sub-channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "select-sub-channel",
                                      apiResponseKey: "selectedData",
                                      dataType: "array",
                                      overwrite: true,
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "channel-remove-button",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button-channel",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-add-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "28px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "decision-dashboard-edit-button",
                          type: "iconButton",
                          staticProps: {
                            variant: "primary",
                            icon: "add",
                            iconClass: "colorWhite",
                            className: "icon-button",
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "slect-channel-dropdown",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button-channel",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-add-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "28px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "decision-dashboard-edit-button-channel",
                          type: "iconButton",
                          staticProps: {
                            variant: "primary",
                            icon: "add",
                            iconClass: "colorWhite",
                            className: "icon-button",
                            display: {
                              type: "controlled",
                              default: {
                                show: false,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "sub-channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button-channel",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-close-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "18px",
                      },
                      display: {
                        type: "controlled",
                        default: {
                          show: false,
                        },
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "decision-dashboard-close-button",
                          type: "iconButton",
                          staticProps: {
                            variant: "primary",
                            icon: "close",
                            iconClass: "colorWhite",
                            className: " icon-button",
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },

            {
              type: "div",
              id: "basic-details-buttons",
              staticProps: {
                customStyle: {
                  paddingBottom: "10px",
                  width: "100%",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "bottom-divider",
                    componentProps: {
                      content: [
                        {
                          id: "create-product-group-divider",
                          type: "divider",
                          staticProps: {
                            color: "black",
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "bottom-buttons",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        display: "flex",
                        marginLeft: "65%",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "cancel-basic-details-button",
                          type: "button",
                          staticProps: {
                            variant: "text",
                            label: "Cancel",
                            className: "create-store-group-button",
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "redirect",
                                  link: "landing-screen",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          alignment: "right",
                          id: "strategy-workbench-create-button",
                          type: "button",
                          staticProps: {
                            variant: "secondary",
                            label: "Save As Draft",
                            customStyle: {
                              border: "1px solid",
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "api_function",
                                  runOnLoad: false,
                                  apiEndPoint: "model-update",
                                  apiMethod: "post",
                                  confirmation: {
                                    type: "check",
                                    showErrorMessage: true,
                                    conditions: [
                                      {
                                        comparison: "exists",
                                        source: "reducer",
                                        sourceId: "configDetails",
                                        subjectId: "config_name",
                                        dataType: "basic",
                                        message:
                                          "Please enter the config  name",
                                      },
                                    ],
                                  },
                                  params: [
                                    {
                                      source: "fixed",
                                      dataType: "object",
                                      apiRequestKey: "id",
                                      value: 51,
                                    },

                                    {
                                      source: "reducer",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "id",
                                      sourceId: "selectedConfig",
                                      subjectId: "id",
                                      dataType: "basic",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "name",
                                      sourceId: "configDetails",
                                      subjectId: "config_name",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "description",
                                      sourceId: "configDetails",
                                      subjectId: "description",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          id: "edit-store-group-submit-button",
                          type: "button",
                          staticProps: {
                            variant: "primary",
                            label: "Next",
                            customStyle: {
                              marginLeft: "20px",
                            },
                            className: "create-store-group-button",
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "api_function",
                                  runOnLoad: false,
                                  apiEndPoint: "model-update",
                                  apiMethod: "post",
                                  confirmation: {
                                    type: "check",
                                    showErrorMessage: true,
                                    conditions: [
                                      {
                                        comparison: "exists",
                                        source: "reducer",
                                        sourceId: "configDetails",
                                        subjectId: "config_name",
                                        dataType: "basic",
                                        message:
                                          "Please enter the config  name",
                                      },
                                    ],
                                  },
                                  params: [
                                    {
                                      source: "fixed",
                                      dataType: "object",
                                      apiRequestKey: "id",
                                      value: 51,
                                    },

                                    {
                                      source: "reducer",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "id",
                                      sourceId: "selectedConfig",
                                      subjectId: "id",
                                      dataType: "basic",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "name",
                                      sourceId: "configDetails",
                                      subjectId: "config_name",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "description",
                                      sourceId: "configDetails",
                                      subjectId: "description",
                                    },
                                  ],
                                  onComplete: {
                                    actions: [
                                      {
                                        type: "reducer_function",
                                        responseFormatter: [
                                          {
                                            destination: "reducer",
                                            dataKey: "screenName",
                                            value: "BASIC_DETAILS",
                                            dataType: "basic",
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
