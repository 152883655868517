export const ADD_NEW_KPI = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-content-emptystate",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            margin: "2px 10px",

            //height: "600px",
          },
        },
        componentProps: {
          content: [
            {
              type: "div",
              id: "basic-details-sub-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h2",
                      content: "Add New Kpi",
                    },
                  },
                ],
              },
            },

            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "enable-disable-div",
              staticProps: {
                customStyle: {
                  display: "flex",
                  marginBottom: "10px",
                  marginTop: "10px",
                  width: "100%",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "enable-disable-text",
                    staticProps: {
                      margin: "20px 0px",
                    },
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          staticProps: {
                            variant: "h3",
                            content: "Enable /Disable KPI",
                            marginBottom: "10px",
                          },
                        },
                        {
                          type: "text",
                          staticProps: {
                            content: "To make KPI enable or diable",
                            variant: "paragraph",
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "switch",
                    id: "kpi-enable-disable",
                    staticProps: {
                      className: "switch-alignment",
                      updatedDataName: "is_deleted",
                      customStyle: {
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "20%",
                      },
                    },
                    dataProps: {
                      data: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "is_deleted",
                      },
                      kpiDetails: {
                        type: "derived",
                        dataKey: "kpiDetails",
                      },
                    },
                  },
                ],
              },
            },

            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "basic-details-sub-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                  marginBottom: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h3",
                      content: "Kpi details",
                      marginBottom: "10px",
                    },
                  },
                ],
              },
            },

            {
              type: "div",
              id: "select-basic-details-wrapper",
              staticProps: {
                customStyle: {
                  display: "flex",
                  alignItems: "center",
                },
              },
              componentProps: {
                content: [
                  {
                    id: "kpi_details",
                    core_id: "kpiDetails",
                    type: "form",
                    staticProps: {
                      customStyle: {
                        marginLeft: "20px",
                      },
                      fields: [
                        {
                          type: "select",
                          id: "category",
                          placeholder: "select",
                          variant: "outlined",
                          label: "Select KPI Category",
                          isMandatory: true,
                          showLabel: true,
                          updateSelectedOnEachSelection: true,
                        },
                        {
                          type: "plainText",
                          id: "kpiKey",
                          placeholder: "kPI key",
                          customStyle: {
                            width: "250px",
                            padding: "8px",
                            marginTop: "22px",
                            fontSize: "15px",
                            marginRight: "700px",
                          },
                        },
                        {
                          type: "autocomplete",
                          id: "name",
                          placeholder: "select",
                          variant: "outlined",
                          label: "KPI Name",
                          isMandatory: true,
                          showLabel: true,
                          updateSelectedOnEachSelection: true,
                        },

                        {
                          type: "text",
                          id: "label",
                          variant: "outlined",
                          placeholder: "Enter here",
                          isMandatory: true,
                          label: "KPI Label",
                          showLabel: true,
                        },

                        {
                          type: "text",
                          id: "description",
                          placeholder: "Enter here...",
                          variant: "outlined",
                          label: "Kpi description",
                          isMandatory: true,
                          showLabel: true,
                        },
                        // {
                        //   type: "select",
                        //   id: "attribute",
                        //   placeholder: "select",
                        //   variant: "outlined",
                        //   label: "Select Version",
                        //   isMandatory: false,
                        //   showLabel: true,
                        //   updateSelectedOnEachSelection: true,
                        // },
                      ],
                    },
                    dataProps: {
                      kpiDetails: {
                        type: "derived",
                        dataKey: "kpiDetails",
                      },
                      kpiKey: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "kpi",
                      },
                      description: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "description",
                      },
                      core_details: {
                        type: "derived",
                        dataKey: "kpiDetails",
                      },
                      core_details1: {
                        type: "derived",
                        dataKey: "requiredListKpiDetails",
                      },
                      category: {
                        type: "derived",
                        dataKey: "requiredListKpiDetails",
                        subjectId: "category",
                        dataType: "array",
                      },

                      name: {
                        type: "derived",
                        dataKey: "requiredListKpiDetails",
                        subjectId: "kpis",
                        dataType: "array",
                      },
                      kpi_id: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "kpi",
                      },

                      attribute: {
                        type: "derived",
                        dataKey: "filterData",
                        subjectId: "1",
                        subjectidNested: "array_agg",
                        dataType: "array",
                      },
                    },
                  },
                ],
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "kpi-value-container",
              staticProps: {},
              componentProps: {
                content: [
                  {
                    type: "text",
                    id: "kpi-value",
                    staticProps: {
                      variant: "h3",
                      content: "Kpi Value",
                    },
                  },
                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "KPI Type",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                type: "switch",
                                id: "kpi-editable",

                                staticProps: {
                                  className: "switch-alignment",
                                  updatedDataName: "editable",
                                  customStyle: {
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: "20%",
                                  },
                                },
                                dataProps: {
                                  data: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "editable",
                                  },
                                  kpiDetails: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },
                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "KPI Formatting Value Type",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                type: "form",
                                id: "kpi_details",
                                core_id: "kpiDetails",
                                staticProps: {
                                  customStyle: {
                                    display: "flex",
                                    alignItems: "flex-start",
                                  },
                                  fields: [
                                    {
                                      type: "select",
                                      id: "formatter",
                                      variant: "outlined",
                                      isMandatory: true,
                                      showLabel: true,
                                      label: "Select KPI Value",
                                      updateSelectedOnEachSelection: true,
                                    },
                                  ],
                                },

                                dataProps: {
                                  formatter: {
                                    type: "derived",
                                    dataKey: "requiredListKpiDetails",
                                    subjectId: "value_types",
                                    dataType: "array",
                                  },
                                  core_details: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                  core_details1: {
                                    type: "derived",
                                    dataKey: "requiredListKpiDetails",
                                  },
                                  formatter_selected_option: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "formatter",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },
                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-decimals",
                                staticProps: {
                                  content: "KPI Decimals",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-decimals",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                id: "kpi_details",
                                type: "form",
                                staticProps: {
                                  customStyle: {
                                    width: "auto",
                                  },
                                  fields: [
                                    {
                                      type: "text",
                                      id: "decimals",
                                      variant: "outlined",
                                      placeholder: "0...",
                                      staticProps: {
                                        variant: "h1",
                                        customStyle: {
                                          fontSize: "20px",
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },

                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },

                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Advance Lock",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                id: "advance-lock",
                                type: "switch",
                                staticProps: {
                                  updatedDataName: "is_advance_lock",
                                  customStyle: {
                                    width: "auto",
                                  },
                                },
                                dataProps: {
                                  data: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "is_advance_lock",
                                  },
                                  kpiDetails: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },
                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },
                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "default_value",
                                staticProps: {
                                  content: "KPI Default Value",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "default_value",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                id: "kpi-details",
                                type: "form",
                                staticProps: {
                                  customStyle: {
                                    width: "auto",
                                  },
                                  fields: [
                                    {
                                      type: "text",
                                      id: "decimals",
                                      placeholder: "0",
                                      variant: "outlined",

                                      staticProps: {
                                        variant: "h1",
                                        customStyle: {
                                          fontSize: "20px",
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },

                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },

                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "KPI Options",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                type: "checkbox",
                                id: "kpiOptions",
                                staticProps: {
                                  value: "Editable",
                                  checked: "true",
                                  optionsValue: {
                                    is_actualized: false,
                                    match_with_all: false,
                                    single_match_with: false,
                                  },
                                },
                              },
                            ],
                          },
                        },
                        {
                          id: "create-product-group-divider",
                          type: "divider",
                          staticProps: {
                            margin: "20px 0px",
                          },
                        },
                      ],
                    },
                  },

                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },
                  {
                    type: "div",
                    id: "editable_flows_wrapper",
                    staticProps: {
                      customStyle: {
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          staticProps: {
                            variant: "h4",
                            content: "Editable Flows",
                          },
                        },
                        {
                          type: "div",
                          id: "add_flow_button_container",
                          componentProps: {
                            content: [
                              {
                                alignment: "right",
                                id: "strategy-workbench-create-button",
                                type: "button",
                                staticProps: {
                                  variant: "primary",
                                  label: "Add Flow",
                                  customStyle: {
                                    marginRight: "30px",
                                  },
                                  display: {
                                    type: "controlled",
                                    default: {
                                      show: true,
                                      enabled: true,
                                    },
                                  },
                                },
                                functionProps: [
                                  {
                                    functionName: "onClick",
                                    actions: [
                                      {
                                        type: "reducer_function",
                                        responseFormatter: [
                                          {
                                            destination: "reducer",
                                            dataKey: "kpiDetails",
                                            subjectId: "editable_flow",
                                            updateExistingNestedArray: true,
                                            dataType: "array",
                                            value: {
                                              name: "",
                                              kpi: null,
                                              value: [
                                                {
                                                  formula: "",
                                                  calculate_order: 1,
                                                  kpi_calculate_id: null,
                                                  kpi_calculate_name: "",
                                                },
                                              ],
                                              lock_and_hold: null,
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "editable_flows_sub_container",
                    staticProps: {
                      customStyle: {
                        padding: "10px",
                        marginTop: "5px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          id: "editable_flows_wrapper",
                          componentProps: {
                            content: [
                              {
                                type: "editableFlows",
                                dataProps: {
                                  kpiList: {
                                    type: "derived",
                                    dataKey: "kpiConfigurationList",
                                    subjectId: "0",
                                    subjectidNested: "data",
                                    dataType: "array",
                                  },
                                  kpiNames: {
                                    type: "derived",
                                    dataKey: "requiredListKpiDetails",
                                    subjectId: "kpis",
                                    dataType: "array",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "kpi-value-sub-container",
              staticProps: {
                customStyle: {
                  marginLeft: "15px",
                  display: "flex",
                  alignItems: "center",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    componentProps: {
                      content: [
                        {
                          type: "text",

                          staticProps: {
                            content: "Lock/Hold",
                            variant: "h4",
                          },
                        },
                        {
                          type: "text",
                          staticProps: {
                            content: "Some additional dummy text",
                            variant: "paragraph",
                          },
                        },
                      ],
                    },
                  },

                  {
                    type: "div",
                    id: "select_container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "400px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "select",
                          id: "lock_and_hold",
                          staticProps: {
                            isMultipleSelection: false,
                            hideClearSelection: true,
                            hideSearch: true,
                            updateSelectedOnEachSelection: true,
                          },
                          dataProps: {
                            options: {
                              type: "derived",
                              dataKey: "requiredListKpiDetails",
                              subjectId: "lock_and_hold",
                              dataType: "array",
                            },
                            kpiDetails: {
                              type: "derived",
                              dataKey: "kpiDetails",
                              dataType: "object",
                            },
                            selectedItem: {
                              type: "derived",
                              dataKey: "kpiDetails",
                              subjectId: "lock_and_hold",
                              subjectidNested: "label",
                              dataType: "string",
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "cancel-next-button-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginRight: "100px",
                },
              },
              componentProps: {
                content: [
                  {
                    alignment: "right",
                    id: "strategy-workbench-create-button",
                    type: "button",
                    staticProps: {
                      variant: "primary",
                      label: "Cancel",
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "landing-screen",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    id: "strategy-workbench-create-button",
                    type: "button",
                    staticProps: {
                      variant: "primary",
                      label: "Next",
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                      customStyle: {
                        marginLeft: "10px",
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "api_function",
                            runOnLoad: false,
                            apiEndPoint: "model-update",
                            apiMethod: "post",
                            params: [
                              {
                                source: "fixed",
                                dataType: "object",
                                apiRequestKey: "id",
                                value: 54,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "kpi",
                                value: null,
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "name",
                                sourceId: "kpiDetails",
                                subjectId: "name",
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "default_kpi_settings",
                                value: false,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "attribute",
                                value: -1,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "default_visible",
                                value: false,
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "editable_flow",
                                sourceId: "kpiDetails",
                                subjectId: "editable_flow",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "editable",
                                sourceId: "kpiDetails",
                                subjectId: "editable",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "is_advance_lock",
                                sourceId: "kpiDetails",
                                subjectId: "is_advance_lock",
                              },

                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "default_visible",
                                value: false,
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "label",
                                sourceId: "kpiDetails",
                                subjectId: "label",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "category",
                                sourceId: "kpiDetails",
                                subjectId: "category",
                                subjectidNested: "id",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "formatter",
                                sourceId: "kpiDetails",
                                subjectId: "formatter",
                                subjectidNested: "id",
                              },

                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "order",
                                sourceId: "kpiDetails",
                                subjectId: "order",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "rank",
                                sourceId: "kpiDetails",
                                subjectId: "rank",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "decimals",
                                sourceId: "kpiDetails",
                                subjectId: "decimals",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "options",
                                sourceId: "kpiDetails",
                                subjectId: "options",
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "type",
                                value: 101,
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "default_value",
                                sourceId: "kpiDetails",
                                subjectId: "default_value",
                              },

                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "description",
                                sourceId: "kpiDetails",
                                subjectId: "description",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "lock_and_hold_id",
                                sourceId: "kpiDetails",
                                subjectId: "lock_and_hold",
                                subjectidNested: "id",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "is_actualized",
                                sourceId: "kpiDetails",
                                subjectId: "options",
                                subjectidNested: "is_actualized",
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "conditional_formatting",
                                value: "null",
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "variance_visible",
                                value: true,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "variance_editable",
                                value: false,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "class_to_dept_visible",
                                value: false,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "class_to_dept_editable",
                                value: false,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "class_to_channel_visible",
                                value: false,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested:
                                  "class_to_channel_editable",
                                value: false,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "class_to_class_visible",
                                value: false,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "class_to_class_editable",
                                value: false,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "default",
                                value: true,
                              },

                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "match_with_all",
                                sourceId: "kpiDetails",
                                subjectId: "options",
                                subjectidNested: "match_with_all",
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "single_match_with",
                                sourceId: "kpiDetails",
                                subjectId: "options",
                                subjectidNested: "single_match_with",
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "order",
                                value: 1,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "rank",
                                value: 1,
                              },
                              {
                                source: "fixed",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "visible",
                                value: true,
                              },
                              {
                                source: "reducer",
                                apiRequestKey: "parameters",
                                apiRequestKeyNested: "is_deleted",
                                sourceId: "kpiDetails",
                                subjectId: "is_deleted",
                              },
                            ],
                          },
                          // {
                          //   type: "redirect",
                          //   link: "aggregation-stratergy",
                          // },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 49,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "reducer",
                    dataType: "basic",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    sourceId: "basic-details",
                    subjectId: "selectedItems",
                    subjectidNested: "0",
                    subjectidNested1: "id",
                  },
                  {
                    source: "reducer",
                    dataType: "basic",
                    sourceId: "selectedKpi",
                    subjectId: "kpi",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "kpi",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "addNewKpi",
                    dataType: "object",
                    overwrite: true,
                    multiLevelResponse: true,
                    level: 0,
                    level1: "data",
                    level2: 0,
                  },
                ],
                onComplete: {
                  actions: [
                    {
                      type: "api_function",
                      runOnLoad: true,
                      apiEndPoint: "model-data",
                      apiMethod: "post",
                      params: [
                        {
                          source: "fixed",
                          dataType: "object",
                          apiRequestKey: "id",
                          value: 52,
                        },
                        {
                          source: "reducer",
                          dataType: "basic",
                          sourceId: "selectedKpi",
                          subjectId: "kpi",
                          apiRequestKey: "parameters",
                          apiRequestKeyNested: "kpi",
                        },
                      ],
                      responseFormatter: [
                        {
                          destination: "reducer",
                          dataKey: "requiredListKpiDetails",
                          dataType: "array",
                          multiLevelResponse: true,
                          level: 0,
                          overwrite: true,
                        },
                      ],
                    },
                  ],
                },
              },
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 20,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "null",
                    dataType: "basic",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "versions",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "kpiDetails",
                    value: {
                      is_deleted: false,
                      editable_flow: [],
                      editable: false,
                      is_advance_lock: false,
                      options: {
                        is_actualized: false,
                        match_with_all: false,
                        single_match_with: false,
                      },
                      decimals: "0",
                      description: "",
                      lock_and_hold: null,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
