import { optimizationType } from "../../Constants";
import { Simulated_data } from "./Simulated_data_container";

export const Ia_Recommendation = {
	screen_composition: {
		components: [
			{
				type: "div",
				dataProps: {
					mountComponent:
					{
						type: "derived",
						dataKey:
							"step_4_table_load_status",
						subjectId:
							"data_present",
					},
				},
				componentProps: {
					content: [
						{
							type: "strategySummary",
							staticProps: {
								extraContent: [
									{
										type: "div",
										staticProps: {
											customStyle:
											{
												paddingLeft:
													"20px",
											},
										},
										componentProps:
										{
											content:
												[
													{
														type: "div",
														staticProps:
														{
															className:
																"flex-container",
															customStyle:
															{
																background:
																	"#0055af",
																padding:
																	"20px",
																borderRadius:
																	"3px",
																marginBottom:
																	"10px",
																justifyContent:
																	"space-between",
															},
														},
														componentProps:
														{
															content:
																[
																	{
																		type: "div",
																		componentProps:
																		{
																			content:
																				[
																					{
																						type: "text",
																						staticProps:
																						{
																							className:
																								"common-page-title",
																							variant:
																								"h5",
																							content:
																								"Store Selected",
																							customStyle:
																							{
																								color: "white",
																							},
																						},
																					},
																					{
																						type: "div",
																						staticProps:
																						{
																							className:
																								"flex-container",
																						},
																						componentProps:
																						{
																							content:
																								[
																									{
																										type: "text",
																										staticProps:
																										{
																											className:
																												"common-page-title",
																											variant:
																												"h4",
																											customStyle:
																											{
																												color: "white",
																											},
																										},
																										dataProps:
																										{
																											dynamicContent:
																											{
																												type: "derived",
																												dataKey:
																													"step_4_chart",
																												textContent:
																													[
																														{
																															content:
																																"selected_stores_count",
																															type: "dynamic",
																															className:
																																"bold",
																														},
																														{
																															content:
																																"/",
																															type: "static",
																														},
																														{
																															content:
																																"total_stores_count",
																															type: "dynamic",
																															className:
																																"bold",
																														},
																													],
																											},
																										},
																									},
																								],
																						},
																					},
																				],
																		},
																	},
																	{
																		type: "icon",
																		staticProps:
																		{
																			icon: "store",
																			customStyle:
																			{
																				fontSize:
																					"35px",
																				marginLeft:
																					"20px",
																			},
																		},
																	},
																],
														},
													},
													{
														type: "div",
														staticProps:
														{
															className:
																"flex-container",
															customStyle:
															{
																background:
																	"#0055af",
																padding:
																	"20px",
																borderRadius:
																	"3px",
																justifyContent:
																	"space-between",
															},
														},
														componentProps:
														{
															content:
																[
																	{
																		type: "div",
																		componentProps:
																		{
																			content:
																				[
																					{
																						type: "text",
																						staticProps:
																						{
																							className:
																								"common-page-title",
																							variant:
																								"h5",
																							content:
																								"Last Week Discount",
																							customStyle:
																							{
																								color: "white",
																							},
																						},
																					},
																					{
																						type: "text",
																						staticProps:
																						{
																							className:
																								"common-page-title",
																							variant:
																								"h4",
																							customStyle:
																							{
																								color: "white",
																							},
																						},
																						dataProps:
																						{
																							dynamicContent:
																							{
																								type: "derived",
																								dataKey:
																									"step_4_chart",
																								textContent:
																									[
																										{
																											content:
																												"lw_recommended_offer_percentage",
																											type: "dynamic",
																											className:
																												"bold",
																											formatter:
																												"toPercentage",
																										},
																									],
																							},
																						},
																					},
																				],
																		},
																	},
																	{
																		type: "icon",
																		staticProps:
																		{
																			icon: "calendar_month",
																			customStyle:
																			{
																				fontSize:
																					"35px",
																				marginLeft:
																					"20px",
																			},
																		},
																	},
																],
														},
													},
												],
										},
									},
								],
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"step_4_chart",
								},
							},
						},
						// CTA buttons and ag grid table
						{
							// isDefaultExport: false,
							type: "div",
							pathSrc: "components",
							componentPath: "ui/wrapperDiv/WrapperDiv",
							staticProps: {
								className: "box-container",
								customStyle: {
									padding: "30px 15px",
								},
							},
							componentProps: {
								content: [
									// table top buttons
									{
										type: "div",
										pathSrc: "components",
										componentPath:
											"ui/wrapperDiv/WrapperDiv",
										staticProps: {
											className: "flex-container",
											customStyle: {
												justifyContent: "space-between",
												marginBottom: "15px",
											},
										},
										componentProps: {
											content: [
												{
													id: "edit-md-strategy",
													type: "div",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														icon: "info",
														className:
															"common-page-title",
														customStyle: {
															fontWeight: "600",
														},
														variant: "h4",
														content:
															"Edit MD strategy",
													},
												},
												{
													type: "div",
													pathSrc: "components",
													componentPath:
														"ui/wrapperDiv/WrapperDiv",
													staticProps: {
														className:
															"flex-container",
														customStyle: {
															gap: "10px",
														},
													},
													componentProps: {
														content: [
															// Discard Draft
															{
																type: "button",
																pathSrc:
																	"components",
																componentPath:
																	"ui/button/Button",
																staticProps: {
																	variant:
																		"primary",
																	label: "Discard Draft",
																	customStyle:
																	{
																		// margin: "0 10px",
																	},
																	display: {
																		type: "controlled",
																		default:
																		{
																			show: true,
																			enabled: true,
																		},
																	},
																},
																dataProps: {
																	is_visible:
																	{
																		type: "derived",
																		dataKey:
																			"step_4_flags_options",
																		subjectId:
																			"enable_discard_draft",
																	},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 3095,
																							dataType:
																								"basic",
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							apiRequestKeyNested:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																						},
																					],
																					onComplete:
																					{
																						// on complete show the loader
																						actions:
																							[
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"ia_reco_updated_data",
																												value: null,
																											},
																										],
																								},
																								{
																									type: "api_function",
																									runOnLoad: true,
																									apiEndPoint:
																										"model",
																									apiMethod:
																										"post",
																									params: [
																										{
																											source: "fixed",
																											dataType:
																												"object",
																											apiRequestKey:
																												"id",
																											value: 3013,
																										},
																										{
																											source: "reducer",
																											sourceId:
																												"filtersForPayloadStep4",
																											apiRequestKey:
																												"parameters",
																										},
																										{
																											source: "reducer",
																											sourceId:
																												"step_1_A",
																											subjectId:
																												"strategy_id",
																											// sourceId: "strategy_id",
																											apiRequestKeyNested:
																												"strategy_id",
																											apiRequestKey:
																												"parameters",
																										},
																									],
																									responseFormatter:
																										[
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"ia_recc_column_config",
																												apiRequestKey:
																													"data",
																												overwrite: true,
																											},
																										],
																								},
																								{
																									type: "api_function",
																									apiEndPoint:
																										"model",
																									apiMethod:
																										"post",
																									confirmation:
																									{
																										type: "check",
																										conditionOperator:
																											"and",
																										conditions:
																											[
																												{
																													comparison:
																														"exists",
																													source: "reducer",
																													sourceId:
																														"step_1_A",
																													subjectId:
																														"strategy_id",
																													dataType:
																														"basic",
																												},
																											],
																									},
																									params: [
																										{
																											source: "fixed",
																											dataType:
																												"object",
																											apiRequestKey:
																												"id",
																											value: 3001,
																										},
																										{
																											source: "reducer",
																											sourceId:
																												"step_1_A",
																											subjectId:
																												"strategy_id",
																											dataType:
																												"basic",
																											apiRequestKey:
																												"parameters",
																											apiRequestKeyNested:
																												"strategy_id",
																										},
																										{
																											source: "fixed",
																											dataType:
																												"object",
																											value: "strategy",
																											apiRequestKey:
																												"parameters",
																											apiRequestKeyNested:
																												"section",
																										},
																									],
																									responseFormatter:
																										[
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"step_1_A",
																												dataType:
																													"object",
																											},
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"step_1_A_copy",
																												dataType:
																													"object",
																											},
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"step_1_A",
																												apiResponseKey:
																													"strategy_dates",
																												dataType:
																													"object",
																											},
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"step_1_A_copy",
																												apiResponseKey:
																													"strategy_dates",
																												dataType:
																													"object",
																											},
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"currentStep",
																												apiResponseKey:
																													"step_count",
																												dataType:
																													"object",
																											},
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"maxStepCount",
																												apiResponseKey:
																													"step_count",
																												dataType:
																													"object",
																											},
																										],
																								},
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												source: "fixed",
																												dataType:
																													"basic",
																												dataKey:
																													"ia_reco_updated_data",
																												value: [],
																												overwrite: true,
																											},
																										],
																								},
																								{
																									type: "reducer_function",
																									responseFormatter: [
																										{
																											source: "fixed",
																											dataType: "basic",
																											dataKey: "step4TableAPICallFlag",
																											value: true,
																											overwrite: true,
																										},
																									],
																								},
																								{
																									type: "api_function",
																									runOnLoad: true,
																									apiEndPoint:
																										"model",
																									apiMethod:
																										"post",
																									params: [
																										{
																											source: "fixed",
																											dataType:
																												"object",
																											apiRequestKey:
																												"id",
																											value: 3001,
																										},
																										{
																											source: "fixed",
																											dataType:
																												"object",
																											apiRequestKey:
																												"parameters",
																											value: "simulation",
																											apiRequestKeyNested:
																												"section",
																										},
																										{
																											source: "reducer",
																											sourceId:
																												"step_1_A",
																											subjectId:
																												"strategy_id",
																											// sourceId: "strategy_id",
																											apiRequestKeyNested:
																												"strategy_id",
																											apiRequestKey:
																												"parameters",
																										},
																									],
																									responseFormatter:
																										[
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"simulated_results",
																												dataType:
																													"object",
																												apiRequestKey:
																													"data",
																											},
																										],
																								},
																								{
																									type: "api_function",
																									apiEndPoint:
																										"model",
																									apiMethod:
																										"post",
																									params: [
																										{
																											source: "fixed",
																											dataType:
																												"object",
																											apiRequestKey:
																												"id",
																											value: 3010,
																										},
																										{
																											source: "reducer",
																											sourceId:
																												"step_1_A",
																											subjectId:
																												"strategy_id",
																											// sourceId: "strategy_id",
																											apiRequestKeyNested:
																												"strategy_id",
																											apiRequestKey:
																												"parameters",
																										},
																									],
																									responseFormatter:
																										[
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"step_4_flags_options",
																												apiRequestKey:
																													"data",
																												overwrite: true,
																											},
																										],
																								},
																							],
																					},
																				},
																			],
																	},
																],
															},
															// copy IA button
															{
																type: "togglePopoverButton",
																id: "copy_ia_button",
																dataProps: {
																	isOpen: {
																		type: "derived",
																		dataKey:
																			"showCopyIaPopover",
																	},
																	is_visible:
																	{
																		type: "derived",
																		dataKey:
																			"step_4_flags_options",
																		subjectId:
																			"enable_copy_ia",
																	},
																},
																staticProps: {
																	variant:
																		"outlined",
																	label: "Copy IA",
																	primaryButton: true,
																	secondaryButton: true,
																	customStyle:
																	{
																		// margin: "10px 15px 5px 15px",
																	},
																	primaryButtonText:
																		"Apply",
																	buttonStyle:
																	{
																		border: "1px solid",
																		margin: 0,
																	},
																	display: {
																		type: "controlled",
																		default:
																		{
																			show: true,
																			enabled: true,
																		},
																	},
																},
																componentProps:
																{
																	content:
																		[
																			{
																				id: "text",
																				type: "div",
																				pathSrc:
																					"components",
																				componentPath:
																					"ui/wrapperDiv/WrapperDiv",
																				staticProps:
																				{
																					customStyle:
																					{
																						width: "650px",
																						padding:
																							"10px 24px",
																						marginBottom:
																							"25px",
																					},
																				},
																				componentProps:
																				{
																					content:
																						[
																							{
																								id: "text",
																								type: "div",
																								pathSrc:
																									"components",
																								componentPath:
																									"ui/wrapperDiv/WrapperDiv",
																								staticProps:
																								{
																									customStyle:
																									{
																										display:
																											"flex",
																										width: "100%",
																										justifyContent:
																											"space-between",
																										position:
																											"relative",
																										zIndex: "2",
																									},
																								},
																								componentProps:
																								{
																									content:
																										[
																											{
																												id: "text",
																												type: "div",
																												staticProps:
																												{
																													customStyle:
																													{
																														display:
																															"flex",
																														alignItems:
																															"center",
																													},
																												},
																												componentProps:
																												{
																													content:
																														[
																															{
																																type: "text",
																																pathSrc:
																																	"components",
																																componentPath:
																																	"ui/typography/Typography",
																																staticProps:
																																{
																																	className:
																																		"common-page-title",
																																	variant:
																																		"h5",
																																	content:
																																		"Copy from",
																																},
																															},
																															{
																																type: "select",
																																staticProps:
																																{
																																	customStyle:
																																	{
																																		width: "200px",
																																		position:
																																			"relative",
																																	},
																																	className:
																																		"copyIA-copyFrom-selectBox",
																																	isMultipleSelection: false,
																																	hideClearSelection: true,
																																	hideSearch: true,
																																	updateSelectedOnEachSelection: true,
																																},
																																dataProps:
																																{
																																	options:
																																	{
																																		type: "derived",
																																		dataKey:
																																			"step_4_flags_options",
																																		subjectId:
																																			"pcd_values",
																																	},
																																},
																																functionProps:
																																	[
																																		{
																																			functionName:
																																				"onSelect",
																																			actions:
																																				[
																																					{
																																						type: "reducer_function",
																																						params: [
																																							{
																																								source: "self",
																																								apiRequestKey:
																																									"source_pcd",
																																								dataType:
																																									"basic",
																																							},
																																						],
																																						responseFormatter:
																																							[
																																								{
																																									destination:
																																										"reducer",
																																									dataKey:
																																										"ia_copy_keys",
																																									dataType:
																																										"basic",
																																								},
																																							],
																																					},
																																				],
																																		},
																																	],
																															},
																														],
																												},
																											},
																											{
																												id: "text",
																												type: "div",
																												staticProps:
																												{
																													customStyle:
																													{
																														display:
																															"flex",
																														alignItems:
																															"center",
																														justifyContent:
																															"right",
																													},
																												},
																												componentProps:
																												{
																													content:
																														[
																															{
																																type: "text",
																																pathSrc:
																																	"components",
																																componentPath:
																																	"ui/typography/Typography",
																																staticProps:
																																{
																																	className:
																																		"common-page-title",
																																	customStyle:
																																	{
																																		position:
																																			"relative",
																																		right: "212px",
																																	},
																																	variant:
																																		"h5",
																																	content:
																																		"Paste to",
																																},
																															},
																															{
																																type: "select",
																																staticProps:
																																{
																																	customStyle:
																																	{
																																		width: "200px",
																																	},
																																	className:
																																		"copyIA-pasteTo-selectBox",
																																	isMultipleSelection: true,
																																	hideClearSelection: true,
																																	hideSearch: true,
																																	updateSelectedOnEachSelection: true,
																																},
																																dataProps:
																																{
																																	options:
																																	{
																																		type: "derived",
																																		dataKey:
																																			"step_4_flags_options",
																																		subjectId:
																																			"pcd_values",
																																	},
																																},
																																functionProps:
																																	[
																																		{
																																			functionName:
																																				"onSelect",
																																			actions:
																																				[
																																					{
																																						type: "reducer_function",
																																						params: [
																																							{
																																								source: "self",
																																								apiRequestKey:
																																									"destination_pcd",
																																								dataType:
																																									"basic",
																																							},
																																						],
																																						responseFormatter:
																																							[
																																								{
																																									destination:
																																										"reducer",
																																									dataKey:
																																										"ia_copy_keys",
																																									dataType:
																																										"basic",
																																								},
																																							],
																																					},
																																				],
																																		},
																																	],
																															},
																														],
																												},
																											},
																										],
																								},
																							},
																						],
																				},
																			},
																		],
																},
																functionProps: [
																	{
																		functionName:
																			"onConfirm",
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					confirmation: {
																						type: "check",
																						showErrorMessage: true,
																						conditions: [
																							{
																								comparison: "exists",
																								source: "reducer",
																								sourceId:
																									"ia_copy_keys",
																								subjectId:
																									"source_pcd",
																								dataType: "basic",
																								message:
																									"Please enter source pcd",
																							},
																							{
																								comparison: "exists",
																								source: "reducer",
																								sourceId:
																									"ia_copy_keys",
																								subjectId:
																									"destination_pcd",
																								dataType: "array",
																								message:
																									"Please enter destination pcd",
																							},
																						],
																						conditionOperator: "and",
																						onConfirmationFailure: {
																							actions:
																								[
																									{
																										type: "reducer_function",
																										responseFormatter: [
																											{
																												source: "fixed",
																												dataKey:
																													"ia_copy_keys",
																												dataType: "object",
																												value: {},
																												overwrite: true,
																											},
																										],
																									},
																								],
																						}
																					},
																					params: [
																						{
																							source: "fixed",
																							dataType: "object",
																							apiRequestKey: "id",
																							value: 3005,
																						},
																						{
																							source: "reducer",
																							sourceId: "ia_copy_keys",
																							subjectId: "source_pcd",
																							apiRequestKey: "parameters",
																							apiRequestKeyNested: "source_pcd",
																						},
																						{
																							source: "reducer",
																							sourceId: "ia_copy_keys",
																							subjectId: "destination_pcd",
																							apiRequestKey: "parameters",
																							apiRequestKeyNested: "destination_pcd",
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							apiRequestKeyNested:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																						},
																					],
																					onComplete: {
																						actions: [
																							{
																								type: "validation",
																								conditions: [
																									{
																										comparison: "exists",
																										source: "response",
																									},
																								],
																								conditionOperator: "or",
																								onValidationSuccess: {
																									actions: [
																										{
																											type: "reducer_function",
																											responseFormatter: [
																												{
																													destination:
																														"reducer",
																													dataKey:
																														"copyIATableAPIFlag",
																													dataType:
																														"basic",
																													value: true,
																													overwrite: true,
																												},
																											],
																										},
																									],
																								},
																							},
																						]
																					}
																				},
																			],
																	},
																	{
																		functionName:
																			"onDeny",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					responseFormatter: [
																						{
																							source: "fixed",
																							dataKey:
																								"ia_copy_keys",
																							dataType: "object",
																							value: {},
																							overwrite: true,
																						},
																					],
																				},
																				{
																					type: "reducer_function",
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"showOptimizationModal",
																								value: false,
																								overwrite: true,
																							},
																						],
																				},
																			],
																	},
																],
															},
															// simulate button
															{
																id: "ia_simulate_button",
																type: "button",
																pathSrc:
																	"components",
																componentPath:
																	"ui/button/Button",
																staticProps: {
																	variant:
																		"primary",
																	label: "Simulate",
																	customStyle:
																	{
																		// margin: "0 10px",
																	},
																	display: {
																		type: "controlled",
																		default:
																		{
																			show: true,
																			enabled: false,
																		},
																	},
																},
																dataProps: {
																	is_visible:
																	{
																		type: "derived",
																		dataKey:
																			"step_4_flags_options",
																		subjectId:
																			"enable_simulation",
																	},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					confirmation:
																					{
																						type: "check",
																						conditions:
																							[
																								{
																									comparison:
																										"multiple",
																									conditionOperator:
																										"or",
																									conditions:
																										[
																											{
																												comparison:
																													"equals",
																												source: "reducer",
																												sourceId:
																													"step_4_flags_options",
																												subjectId:
																													"optimisation_type",
																												checkValue:
																													optimizationType.ia_optimization,
																											},
																											{
																												comparison:
																													"equals",
																												source: "reducer",
																												sourceId:
																													"step_4_flags_options",
																												subjectId:
																													"optimisation_type",
																												checkValue:
																													optimizationType.partial_optimization,
																											},
																										],
																								},
																								{
																									comparison: "exists",
																									source: "reducer",
																									sourceId:
																										"ia_copy_keys",
																									subjectId: "source_pcd"
																								},
																								{
																									comparison: "exists",
																									source: "reducer",
																									sourceId:
																										"ia_copy_keys",
																									subjectId: "destination_pcd"
																								}

																							],
																						conditionOperator:
																							"and",
																					},
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 3008,
																							dataType:
																								"basic",
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"ia_reco_updated_data",
																							apiRequestKeyNested:
																								"discount_values",
																							apiRequestKey:
																								"parameters",
																							dataType:
																								"array",
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							apiRequestKeyNested:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "session_storage",
																							sourceId:
																								"UNIQ_SSE_KEY",
																							apiRequestKeyNested:
																								"guid",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKeyNested:
																								"copy_ia",
																							value: true,
																							apiRequestKey:
																								"parameters",
																						},
																					],
																					onComplete:
																					{
																						// on complete show the loader
																						actions:
																							[
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												source: "fixed",
																												dataType:
																													"object",
																												dataKey:
																													"cells_lock_state",
																												value: {},
																												overwrite: true,
																											},
																										],
																								},
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												source: "fixed",
																												dataType:
																													"object",
																												dataKey:
																													"ia_recc_table_data",
																												value: [],
																												overwrite: true,
																											},
																										],
																								},
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"show_loader_until_simulate_complete",
																												value: true,
																											},
																										],
																								},
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												source: "fixed",
																												destination:
																													"reducer",
																												dataKey:
																													"ia_copy_keys",
																												value: {},
																											},
																										],
																								},
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												source:
																													"fixed",
																												dataKey:
																													"copyIATableAPIFlag",
																												value: false,
																												overwrite: true,
																											},
																										],
																								},
																							],
																					},
																				},
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					confirmation:
																					{
																						type: "check",
																						conditions:
																							[
																								{
																									comparison:
																										"multiple",
																									conditionOperator:
																										"or",
																									conditions:
																										[
																											{
																												comparison:
																													"equals",
																												source: "reducer",
																												sourceId:
																													"step_4_flags_options",
																												subjectId:
																													"optimisation_type",
																												checkValue:
																													optimizationType.ia_optimization,
																											},
																											{
																												comparison:
																													"equals",
																												source: "reducer",
																												sourceId:
																													"step_4_flags_options",
																												subjectId:
																													"optimisation_type",
																												checkValue:
																													optimizationType.partial_optimization,
																											},
																										],
																								},
																								{
																									comparison: "notExists",
																									source: "reducer",
																									sourceId:
																										"ia_copy_keys",
																									subjectId: "source_pcd"
																								},
																								{
																									comparison: "notExists",
																									source: "reducer",
																									sourceId:
																										"ia_copy_keys",
																									subjectId: "destination_pcd"
																								}

																							],
																						conditionOperator:
																							"and",
																					},
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 3008,
																							dataType:
																								"basic",
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"ia_reco_updated_data",
																							apiRequestKeyNested:
																								"discount_values",
																							apiRequestKey:
																								"parameters",
																							dataType:
																								"array",
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							// sourceId: "strategy_id",
																							apiRequestKeyNested:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "session_storage",
																							sourceId:
																								"UNIQ_SSE_KEY",
																							apiRequestKeyNested:
																								"guid",
																							apiRequestKey:
																								"parameters",
																						},
																					],
																					onComplete:
																					{
																						// on complete show the loader
																						actions:
																							[
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												source: "fixed",
																												dataType:
																													"object",
																												dataKey:
																													"cells_lock_state",
																												value: {},
																												overwrite: true,
																											},
																										],
																								},
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												source: "fixed",
																												dataType:
																													"object",
																												dataKey:
																													"ia_recc_table_data",
																												value: [],
																												overwrite: true,
																											},
																										],
																								},
																								{
																									type: "reducer_function",
																									responseFormatter:
																										[
																											{
																												destination:
																													"reducer",
																												dataKey:
																													"show_loader_until_simulate_complete",
																												value: true,
																											},
																										],
																								},
																							],
																					},
																				},
																				{
																					type: "reducer_function",
																					confirmation:
																					{
																						type: "check",
																						conditions:
																							[
																								{
																									comparison:
																										"equals",
																									source: "reducer",
																									sourceId:
																										"step_4_flags_options",
																									subjectId:
																										"optimisation_type",
																									checkValue:
																										optimizationType.no_optimization,
																								},
																							],
																						conditionOperator:
																							"or",
																					},
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"show_IA_recommendation_finalize_alert",
																								value: true,
																								overwrite: true,
																							},
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"IA_recommendation_finalize_alert_message",
																								value: "Simulation is possible if all cells are filled. Please fill all the cells to run simulation.",
																								overwrite: true,
																							},
																						],
																				},
																			],
																	},
																],
															},
															// optimize button
															{
																id: "ia_optimze_button",
																type: "button",
																pathSrc:
																	"components",
																componentPath:
																	"ui/button/Button",
																staticProps: {
																	variant:
																		"primary",
																	label: "Optimize",
																	customStyle:
																	{
																		// margin: "0 10px",
																	},
																	display: {
																		type: "controlled",
																		default:
																		{
																			show: true,
																			enabled: true,
																		},
																	},
																},
																dataProps: {
																	is_visible:
																	{
																		type: "derived",
																		dataKey:
																			"step_4_flags_options",
																		subjectId:
																			"enable_optimisation",
																	},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					confirmation:
																					{
																						type: "check",
																						conditions:
																							[
																								{
																									comparison:
																										"equals",
																									source: "reducer",
																									sourceId:
																										"step_4_flags_options",
																									subjectId:
																										"optimisation_type",
																									checkValue:
																										optimizationType.ia_optimization,
																								},
																								{
																									comparison:
																										"equals",
																									source: "reducer",
																									sourceId:
																										"step_4_flags_options",
																									subjectId:
																										"optimisation_type",
																									checkValue:
																										optimizationType.no_optimization,
																								},
																							],
																						conditionOperator:
																							"or",
																					},
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 3014,
																							dataType:
																								"basic",
																						},
																						{
																							source: "fixed",
																							sourceId:
																								"ia_reco_updated_data",
																							apiRequestKeyNested:
																								"discount_values",
																							apiRequestKey:
																								"parameters",
																							dataType:
																								"array",
																							value: [],
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							// sourceId: "strategy_id",
																							apiRequestKeyNested:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "session_storage",
																							sourceId:
																								"UNIQ_SSE_KEY",
																							apiRequestKeyNested:
																								"guid",
																							apiRequestKey:
																								"parameters",
																							// conditions:
																							// 	[
																							// 		{
																							// 			comparison:
																							// 				"equals",
																							// 			source: "reducer",
																							// 			sourceId:
																							// 				"rules_optimization_modal_details",
																							// 			subjectId:
																							// 				"optimisation_type",
																							// 			value: 1,
																							// 		},
																							// 	],
																						},
																					],
																					onComplete:
																					{
																						actions:
																							[
																								{
																									type: "redirect",
																									link: "workbench",
																								},
																							],
																					},
																				},
																				{
																					type: "reducer_function",
																					confirmation:
																					{
																						type: "check",
																						conditions:
																							[
																								{
																									comparison:
																										"equals",
																									source: "reducer",
																									sourceId:
																										"step_4_flags_options",
																									subjectId:
																										"optimisation_type",
																									checkValue:
																										optimizationType.partial_optimization,
																								},
																							],
																						conditionOperator:
																							"or",
																					},
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"show_IA_recommendation_finalize_alert",
																								value: true,
																								overwrite: true,
																							},
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"IA_recommendation_finalize_alert_message",
																								value: "Lock cells of your interest to stick to your discount. Blank and unlock cells of BL columns will be populated by IA recommendation.",
																								overwrite: true,
																							},
																						],
																				},
																			],
																	},
																],
															},
															{
																alignment:
																	"right",
																id: "product-group-config-view-edit-button",
																type: "iconButton",
																pathSrc:
																	"components",
																componentPath:
																	"ui/iconButton/IconButton",
																staticProps: {
																	variant:
																		"primary",
																	icon: "settings",
																	className:
																		"icon-button",
																	display: {
																		type: "controlled",
																		default:
																		{
																			show: true,
																			enabled: true,
																		},
																	},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"showRecommendedSettingPanel",
																								value: true,
																								overwrite: true,
																							},
																						],
																				},
																				{
																					type: "reducer_function",
																					params: [
																						{
																							source: "reducer",
																							sourceId: "filtersForPayloadStep4"

																						},
																					],
																					responseFormatter: [
																						{
																							destination: "reducer",
																							dataKey:
																								"tempFiltersForPayloadStep4",
																							overwrite: true
																						},
																					],
																				},
																				// {
																				// 	type: "reducer_function",
																				// 	responseFormatter:
																				// 		[
																				// 			{
																				// 				destination:
																				// 					"reducer",
																				// 				dataKey:
																				// 					"show_chips_for_ia_filter",
																				// 				overwrite: true,
																				// 				value: false,
																				// 			},
																				// 		],
																				// },
																			],
																	},
																],
															},
															// Stratgies Download Button
															{
																alignment: "right",
																type: "btnDropdown",
																staticProps: {
																	customStyle: {
																		margin: "0",
																	},
																	options: [
																		{
																			label: "Download Strategy",
																			value: "startegy",
																		},
																		{
																			label: "Download BLO Summary Metrics",
																			value: "summary_blo_metrics",
																		},
																		{
																			label: "Download IA Summary Metrics",
																			value: "summary_ia_metrics",
																		},
																	],
																	variant:
																		"primary",
																	icon: "file_download",
																	className:
																		"icon-button",
																},
																functionProps: [
																	{
																		functionName:
																			"onSelect",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					params: [
																						{
																							source: "self",
																							apiRequestKey:
																								"selectedData",
																							dataType:
																								"array",
																						},
																					],
																					responseFormatter: [
																						{
																							destination:
																								"reducer",
																							dataKey:
																								"selected_download_option_strategy",
																							apiResponseKey:
																								"selectedData",
																							dataType:
																								"basic",
																							overwrite: true,
																						},
																					],
																				},
																				// Download for strategy
																				{
																					type: "api_function",
																					apiEndPoint:
																						"report-download",
																					apiMethod:
																						"post",
																					confirmation: {
																						type: "check",
																						showErrorMessage: false,
																						conditions: [
																							{
																								comparison: "exists",
																								source: "reducer",
																								sourceId:
																									"tempFiltersForPayloadStep4",
																								dataType: "basic",
																							},
																							{
																								comparison: "equals",
																								source: "reducer",
																								sourceId:
																									"tempFiltersForPayloadStep4",
																								subjectId:
																									"trackSettingsChange",
																								checkValue: true
																							},
																							{
																								comparison: "equals",
																								source: "reducer",
																								sourceId: "selected_download_option_strategy",
																								checkValue: "startegy",
																							},
																						],
																						conditionOperator: "and",
																						onConfirmationFailure: {
																							actions:
																								[
																									{
																										type: "api_function",
																										apiEndPoint:
																											"report-download",
																										apiMethod:
																											"post",
																										confirmation: {
																											type: "exists",
																											showErrorMessage: true,
																											conditions: [
																												{
																													comparison: "equals",
																													source: "reducer",
																													sourceId: "selected_download_option_strategy",
																													checkValue: "startegy",
																												},
																											],
																										},
																										params: [
																											{
																												source: "fixed",
																												apiRequestKey:
																													"id",
																												value: 5,
																												isDownload: true,
																												dataType:
																													"basic",
																											},

																											{
																												source: "filters",
																												dataType:
																													"object",
																												apiRequestKey:
																													"parameters",
																											},
																											{
																												source: "reducer",
																												dataType:
																													"object",
																												sourceId:
																													"step_1_A",
																												subjectId:
																													"strategy_id",
																												apiRequestKey:
																													"parameters",
																												apiRequestKeyNested:
																													"strategy_id",
																											},
																											{
																												source: "fixed",
																												dataType:
																													"object",
																												apiRequestKey:
																													"parameters",
																												apiRequestKeyNested:
																													"report_name",
																												value: "strategy_discounts_report",
																											},
																											{
																												source: "reducer",
																												dataType:
																													"object",
																												sourceId:
																													"selected_filters_for_step4",
																												subjectId:
																													"Store",
																												apiRequestKey:
																													"parameters",
																												apiRequestKeyNested:
																													"store_level",
																											},
																											{
																												source: "reducer",
																												dataType:
																													"object",
																												sourceId:
																													"selected_filters_for_step4",
																												subjectId:
																													"Product",
																												apiRequestKey:
																													"parameters",
																												apiRequestKeyNested:
																													"product_level",
																											},
																											{
																												source: "reducer",
																												dataType:
																													"object",
																												sourceId:
																													"selected_filters_for_step4",
																												subjectId:
																													"Week",
																												apiRequestKey:
																													"parameters",
																												apiRequestKeyNested:
																													"week_level",
																											},
																										],
																										responseFormatter:
																											[],
																									},
																								],
																						}
																					},
																					params: [
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"id",
																							value: 7,
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"strategy_id",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"report_name",
																							value: "strategy_discounts_report",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"tempFiltersForPayloadStep4",
																							subjectId:
																								"store_level",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"store_level",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"tempFiltersForPayloadStep4",
																							subjectId:
																								"product_level",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"product_level",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"tempFiltersForPayloadStep4",
																							subjectId:
																								"pcd_ids",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"pcd_ids",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																				// Download for Download IA Summary metrics
																				{
																					type: "api_function",
																					apiEndPoint: "report-download",
																					apiMethod: "post",
																					confirmation: {
																						type: "exists",
																						showErrorMessage: true,
																						conditions: [
																							{
																								comparison: "equals",
																								source: "reducer",
																								sourceId: "selected_download_option_strategy",
																								checkValue: "summary_ia_metrics",
																							},
																						],
																					},
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 7777,
																							isDownload: true,
																							dataType:
																								"basic",
																						},

																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"strategy_id",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"report_name",
																							value: "ia_summary_metrics",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"table_type",
																							value: "ia",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																				// Download for Download BLO Summary metrics
																				{
																					type: "api_function",
																					apiEndPoint: "report-download",
																					apiMethod: "post",
																					confirmation: {
																						type: "exists",
																						showErrorMessage: true,
																						conditions: [
																							{
																								comparison: "equals",
																								source: "reducer",
																								sourceId: "selected_download_option_strategy",
																								checkValue: "summary_blo_metrics",
																							},
																						],
																					},
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 7777,
																							isDownload: true,
																							dataType:
																								"basic",
																						},

																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"strategy_id",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"report_name",
																							value: "blo_summary_metrics",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"table_type",
																							value: "blo",
																						},
																					],

																					responseFormatter:
																						[],
																				},
																			],
																	},
																],
															},

															{
																id: "bulk_edit_button",
																type: "iconButton",
																staticProps: {
																	tooltip: "Bulk Edit",
																	variant:
																		"primary",
																	icon: "edit",
																	customStyle:
																	{
																		// marginLeft:"10px",
																	},
																	className:
																		"icon-button",
																	display: {
																		type: "controlled",
																		default:
																		{
																			show: false,
																			enabled: true,
																		},
																	},
																},
																dataProps: {
																	is_visible:
																	{
																		type: "derived",
																		dataKey:
																			"step_4_flags_options",
																		subjectId:
																			"enable_simulation",
																	},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					confirmation:
																					{
																						type: "check",
																						conditionOperator:
																							"and",
																						showErrorMessage: true,
																						conditions:
																							[
																								{
																									comparison:
																										"exists",
																									source: "reducer",
																									sourceId:
																										"tableData",
																									subjectId:
																										"ia_recc_table",
																									dataType:
																										"array",
																									message:
																										"Please select atleast 1 Strategy.",
																								},
																							],
																					},
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"show_IA_recommendation_bulk_edit_modal",
																								value: true,
																								overwrite: true,
																							},
																						],
																				},
																			],
																	},
																],
															},
														],
													},
												},
											],
										},
									},
									// IA seelcted product and store info
									{
										type: "div",
										pathSrc: "components",
										componentPath:
											"ui/wrapperDiv/WrapperDiv",
										staticProps: {
											className: "box-container",
											customStyle: {
												display: "flex",
												alignItems: "center",
											},
										},
										componentProps: {
											content: [
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														className:
															"common-page-title",
														variant: "h4",
														content:
															"Product Level",
														customStyle: {
															marginRight: "4px",
															marginBottom: 0,
														},
													},
												},
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														className:
															"common-page-title",
														variant: "paragraph",
														customStyle: {
															textAlign: "left",
															font: "normal normal normal 14px/21px Poppins",
															letterSpacing:
																"0px",
															color: "#0055AF",
															margin: "0 10px",
														},
													},
													dataProps: {
														dynamicContent: {
															type: "derived",
															dataKey:
																"product_recommendation_label",
														},
													},
												},
												// {
												// 	type: "button",
												// 	pathSrc: "components",
												// 	componentPath:
												// 		"ui/button/Button",
												// 	staticProps: {
												// 		variant: "primary",
												// 		display: {
												// 			type: "controlled",
												// 			default: {
												// 				show: true,
												// 				enabled: true,
												// 				clickable: false,
												// 			},
												// 		},
												// 		customStyle: {
												// 			marginRight: "14px",
												// 		},
												// 	},
												// 	dataProps: {
												// 		label: {
												// 			type: "derived",
												// 			dataKey:
												// 				"product_recommendation_label",
												// 		},
												// 	},
												// },
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														customStyle: {
															display: "flex",
															border: "1px solid #c8ced7",
															height: "24px",
															marginBottom: 0,
														},
													},
												},
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														className:
															"common-page-title",
														variant: "h4",
														content: "Store Level",
														customStyle: {
															marginLeft: "14px",
															marginRight: "4px",
															marginBottom: 0,
														},
													},
												},
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														className:
															"common-page-title",
														variant: "paragraph",
														customStyle: {
															textAlign: "left",
															font: "normal normal normal 14px/21px Poppins",
															letterSpacing:
																"0px",
															color: "#0055AF",
															margin: "0 10px",
														},
													},
													dataProps: {
														dynamicContent: {
															type: "derived",
															dataKey:
																"store_recommendation_label",
														},
													},
												},
												// {
												// 	type: "button",
												// 	pathSrc: "components",
												// 	componentPath:
												// 		"ui/button/Button",
												// 	staticProps: {
												// 		variant: "primary",
												// 		display: {
												// 			type: "controlled",
												// 			default: {
												// 				show: true,
												// 				enabled: true,
												// 			},
												// 		},
												// 	},
												// 	dataProps: {
												// 		label: {
												// 			type: "derived",
												// 			dataKey:
												// 				"store_recommendation_label",
												// 		},
												// 	},
												// },
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														className:
															"common-page-title",
														variant: "h5",
														content:
															"Modifications to the existing strategy can be done at the Recommendation Level",
														customStyle: {
															fontSize: "12px",
															margin: "0 0 0 20px",
														},
													},
												},
											],
										},
									},
									// selected filters
									{
										type: "selected_chips",
										pathSrc: "components",
										componentPath:
											"ui/selectedChips/SelectedChips",
										staticProps: {
											title: "Selected Filters:",
											customStyle: {
												margin: 0,
											},
											// hideClose: false,
											shouldClear: false,
											chipStyle: {
												borderRadius: "3px",
												maxWidth: "unset",
												height: "20px",
											},
											show_chips: true,
										},
										dataProps: {
											// show_chips: {
											// 	type: "derived",
											// 	dataKey:
											// 		"show_chips_for_ia_filter",
											// },
											data: {
												type: "derived",
												dataKey:
													"selected_filters_for_step4",
												dataType: "array",
											},
										},
									},
									// IA generated table
									{
										id: "md_strategy_table",
										type: "aggridSSR",
										pathSrc: "components",
										componentPath: "ui/agGrid/AgGrid",
										staticProps: {
											tableId: "ia_recc_table",
											height: "550px",
											uniqueKey: "unique_id",
											inlineEditEnabled: true,
											handleInlineEdit: true,
											singleClickEdit: true,
											stopEditingWhenCellsLoseFocus: true,
											chooseCustomCellRenderer: true,
											// customHeaderCheckbox: true,
											useCustomFormatForIaTable: true,
											updateParent: false,
											rowSelection: "multiple",
											// Need to add the cell renderer format here, i.e,
											cellRenderFormat: {
												ia_reco: [],
												bl_override: [],
											},
											applyRowLevelLockAtColumn:
												"product_level_value",
											storeOldValues: true,
											customHeaderRenderer: true,
											displayFooter: true,
											orderKey: "order_",
											capMaxValue: 100,
											capMinValue: 0,
											// commenting for demo
											// groupIncludeFooter: true,
											// includes grand total
											// groupIncludeTotalFooter: true,
											// rowModelType: "serverSide",
											// api: "https://api.test.datacite.org/providers/caltech/dois",
										},
										dataProps: {
											is_cell_changed: {
												type: "derived",
												dataKey: "ia_reco_updated_data",
											},
											data: {
												type: "derived",
												dataKey: "ia_reco_table_data",
											},
											column: {
												type: "derived",
												dataKey:
													"ia_recc_column_config",
											},
											optimization_type: {
												type: "derived",
												dataKey: "step_4_flags_options",
												subjectId: "optimisation_type",
											},
											is_simulation_enabled: {
												type: "derived",
												dataKey: "step_4_flags_options",
												subjectId: "enable_simulation",
											},
											is_settings_changed: {
												type: "derived",
												dataKey:
													"filtersForPayloadStep4",
												subjectId:
													"trackSettingsChange",
											},
											step_count: {
												type: "derived",
												dataKey: "maxStepCount",
											},
											strategy_id: {
												type: "derived",
												datakey: "step_1_a",
												subjectId: "strategy_id",
											},
										},
										functionProps: [
											// on cell value change logic
											{
												functionName:
													"onCellValueChanged",
												actions: [
													{
														type: "reducer_function",
														params: [
															{
																source: "self",
																apiRequestKey:
																	"updatedSimulatedData",
																dataType:
																	"array",
																subjectId:
																	"updated_ia_table_payload",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"ia_reco_updated_data",
																apiResponseKey:
																	"updatedSimulatedData",
																dataType:
																	"array",
																uniqueKey: [
																	"pcd_id",
																	"rowid",
																],
																updateOnAllKeyCheck: true,
																updateType:
																	"unique_key_item_overwrite",
															},
														],
													},
												],
											},
											// lock api logic
											{
												functionName: "lockApiLogic",
												actions: [
													{
														type: "reducer_function",
														params: [
															{
																source: "self",
																dataType:
																	"object",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"ia_table_row_data",
																overwrite: true,
															},
														],
														onComplete: {
															actions: [
																// if lock level is cell && (step count = 3)
																{
																	type: "validation",
																	conditions:
																		[
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"maxStepCount",
																				value: 3,
																			},
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"ia_table_row_data",
																				subjectId:
																					"saveLockClick",
																				value: "cell",
																			},
																		],
																	conditionOperator:
																		"and",
																	onValidationSuccess:
																	{
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 3012,
																							dataType:
																								"basic",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"strategy_id",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"action",
																							apiRequestKeyNested:
																								"action",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"product_level_id",
																							apiRequestKeyNested:
																								"product_level_id",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"store_level_id",
																							apiRequestKeyNested:
																								"store_level_id",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"pcd_id",
																							apiRequestKeyNested:
																								"pcd_id",
																							apiRequestKey:
																								"parameters",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																			],
																	},
																},
																// lock level at header && (step count = 3)
																{
																	type: "validation",
																	conditions:
																		[
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"maxStepCount",
																				value: 3,
																			},
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"ia_table_row_data",
																				subjectId:
																					"saveLockClick",
																				value: "header",
																			},
																		],
																	conditionOperator:
																		"and",
																	onValidationSuccess:
																	{
																		actions:
																			[
																				// call the api to lock the table
																				{
																					type: "api_function",
																					runOnLoad: true,
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"id",
																							value: 3012,
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							// sourceId: "strategy_id",
																							apiRequestKeyNested:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"action",
																							// sourceId: "strategy_id",
																							apiRequestKeyNested:
																								"action",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKeyNested:
																								"lock_unlock_pcd",
																							value: true,
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"pcd_id",
																							// sourceId: "strategy_id",
																							apiRequestKeyNested:
																								"pcd_id",
																							apiRequestKey:
																								"parameters",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																			],
																	},
																},
																// lock at table level && (step count = 3)
																{
																	type: "validation",
																	conditions:
																		[
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"maxStepCount",
																				value: 3,
																			},
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"ia_table_row_data",
																				subjectId:
																					"saveLockClick",
																				value: "table",
																			},
																		],
																	conditionOperator:
																		"and",
																	onValidationSuccess:
																	{
																		actions:
																			[
																				// call the api to lock the table
																				{
																					type: "api_function",
																					runOnLoad: true,
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"id",
																							value: 3012,
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							// sourceId: "strategy_id",
																							apiRequestKeyNested:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"action",
																							// sourceId: "strategy_id",
																							apiRequestKeyNested:
																								"action",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKeyNested:
																								"lock_unlock_strategy",
																							value: true,
																							apiRequestKey:
																								"parameters",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																			],
																	},
																},
																// if lock level is at row && (step count = 3)
																{
																	type: "validation",
																	conditions:
																		[
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"maxStepCount",
																				value: 3,
																			},
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"ia_table_row_data",
																				subjectId:
																					"saveLockClick",
																				value: "row",
																			},
																		],
																	conditionOperator:
																		"and",
																	onValidationSuccess:
																	{
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 3012,
																							dataType:
																								"basic",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"step_1_A",
																							subjectId:
																								"strategy_id",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"strategy_id",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"action",
																							apiRequestKeyNested:
																								"action",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKeyNested:
																								"lock_unlock_row",
																							apiRequestKey:
																								"parameters",
																							value: true,
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"product_level_id",
																							apiRequestKeyNested:
																								"product_level_id",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"object",
																							sourceId:
																								"ia_table_row_data",
																							subjectId:
																								"store_level_id",
																							apiRequestKeyNested:
																								"store_level_id",
																							apiRequestKey:
																								"parameters",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																			],
																	},
																},
															],
														},
													},
												],
											},
										],
										dependantControls: [
											{
												action: "rowSelection",
												controls: [
													{
														id: "bulk_edit_button",
														selection: "multiple",
														switchKey: "show",
														value: true,
													},
													{
														id: "ia_simulate_button",
														selection: "none",
														switchKey: "show",
													},
													{
														id: "ia_optimze_button",
														selection: "none",
														switchKey: "show",
													},
													{
														id: "copy_ia_button",
														selection: "none",
														switchKey: "show",
													},
													{
														id: "product-group-config-view-edit-button",
														selection: "none",
														switchKey: "show",
													},
													{
														id: "product-group-config-view-download-button",
														selection: "none",
														switchKey: "show",
													},
												],
											},
										],
									},
								],
							},
						},
						// Simulated Results
						{
							type: "div",
							pathSrc: "components",
							componentPath: "ui/wrapperDiv/WrapperDiv",
							staticProps: {
								className: "box-container",
							},
							componentProps: {
								content: [
									{
										type: "text",
										pathSrc: "components",
										componentPath:
											"ui/typography/Typography",
										staticProps: {
											className: "common-page-title",
											customStyle: {
												marginBottom: "15px",
											},
											variant: "h3",
											content: "Simulation Results",
										},
									},
									{
										type: "div",
										pathSrc: "components",
										componentPath:
											"ui/wrapperDiv/WrapperDiv",
										staticProps: {
											className: "flex-container",
											customStyle: {
												overflowX: "auto",
											},
										},
										componentProps: {
											content: Simulated_data(),
										},
									},
								],
							},
						},
						// Simulated Results End
					],
				},
				functionProps: [
					{
						functionName: "onLoad",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										source: "fixed",
										dataType: "object",
										dataKey: "cells_lock_state",
										value: {},
										overwrite: true,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "componentDisplayControls",
										subjectId:
											"ia_simulate_button",
										dataType: "object",
										value: {
											enabled: false,
										},
										overwrite: false,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										source: "fixed",
										dataType: "object",
										dataKey: "columnLevelLockState",
										value: {},
										overwrite: true,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										source: "fixed",
										dataKey:
											"ia_copy_keys",
										dataType: "object",
										value: {},
										overwrite: true,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter:
									[
										{
											source:
												"fixed",
											dataKey:
												"copyIATableAPIFlag",
											value: false,
											overwrite: true,
										},
									],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										source: "fixed",
										dataType: "object",
										dataKey: "tableLevelLockFlag",
										value: false,
										overwrite: true,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										source: "fixed",
										dataType: "object",
										dataKey: "ia_recc_table_filters",
										value: {},
										overwrite: true,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										source: "fixed",
										dataType: "object",
										dataKey: "ia_recc_table_sort_options",
										value: {},
										overwrite: true,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter:
									[
										{
											source: "fixed",
											dataType:
												"object",
											dataKey:
												"ia_recc_table_data",
											value: [],
											overwrite: true,
										},
									],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										source: "fixed",
										dataType: "object",
										dataKey: "customCheckboxSelectionFlags",
										subjectId: "ia_recc_table",
										value: false,
										overwrite: true,
									},
								],
							},
							// fetch chart level data
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3003,
									},
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "parameters",
										apiRequestKeyNested:
											"include_store_lw_metrics",
										value: true,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										// sourceId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_4_chart",
										dataType: "object",
										apiRequestKey: "data",
									},
								],
								onComplete: {
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"ia_reco_table_data",
													value: [],
												},
											],
										},
										{
											type: "api_function",
											runOnLoad: true,
											apiEndPoint: "model",
											apiMethod: "post",
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3004,
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"filtersForPayloadStep4",
													subjectId: "pcd_ids",
													apiResponseKey:
														"pcd_metrics",
													getSelectedIds: true,
												},
												{
													destination: "reducer",
													dataKey:
														"selected_filters_for_step4",
													subjectId: "Week",
													subjectIdNested: "data",
													apiResponseKey:
														"pcd_metrics",
													getSelectedLabels: true,
												},
												{
													destination: "reducer",
													dataKey:
														"bulk_edit_for_step4",
													subjectId:
														"selected_pcd_bulk_options",
													apiResponseKey:
														"pcd_metrics",
													// getSelectedIdAndLabel: true,
													getFutureWeeksOnly: true,
												},
												{
													destination: "reducer",
													dataKey:
														"filters_for_step_4",
													dataType: "object",
												},
											],
										},
										{
											type: "api_function",
											runOnLoad: true,
											apiEndPoint: "model",
											apiMethod: "post",
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3001,
												},
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "parameters",
													value: "simulation",
													apiRequestKeyNested:
														"section",
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"simulated_results",
													dataType: "object",
													apiRequestKey: "data",
												},
											],
										},
										// fetch product and store reccomendation level
										{
											type: "api_function",
											runOnLoad: true,
											apiEndPoint: "model",
											apiMethod: "post",
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3001,
												},
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "parameters",
													value: "objective",
													apiRequestKeyNested:
														"section",
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"product_recommendation_level",
													apiResponseKey:
														"product_recommendation_level",
												},
												{
													destination: "reducer",
													dataKey:
														"store_recommendation_level",
													apiResponseKey:
														"store_recommendation_level",
												},
												{
													destination: "reducer",
													dataKey:
														"filtersForPayloadStep4",
													subjectId: "product_level",
													apiResponseKey:
														"product_recommendation_level",
												},
												{
													destination: "reducer",
													dataKey:
														"filtersForPayloadStep4",
													subjectId: "store_level",
													apiResponseKey:
														"store_recommendation_level",
												},
												{
													destination: "reducer",
													dataKey:
														"product_recommendation_label",
													apiResponseKey:
														"product_recommendation_level_name",
												},
												{
													destination: "reducer",
													dataKey:
														"store_recommendation_label",
													apiResponseKey:
														"store_recommendation_level_name",
												},
												{
													destination: "reducer",
													dataKey:
														"selected_filters_for_step4",
													subjectId: "Product",
													apiResponseKey:
														"product_recommendation_level_name",
													subjectIdNested: "data",
													dataType: "object",
												},
												{
													destination: "reducer",
													dataKey:
														"selected_filters_for_step4",
													subjectId: "Store",
													subjectIdNested: "data",
													apiResponseKey:
														"store_recommendation_level_name",
													dataType: "object",
												},
												// {
												// 	type: "reducer_function",
												// 	responseFormatter: [
												// 		{
												// 			destination:
												// 				"reducer",
												// 			dataKey:
												// 				"show_chips_for_ia_filter",
												// 			overwrite: true,
												// 			value: true,
												// 		},
												// 	],
												// },
											],
										},
										{
											type: "api_function",
											runOnLoad: true,
											apiEndPoint: "model",
											apiMethod: "post",
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3013,
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"ia_recc_column_config",
													apiRequestKey: "data",
													overwrite: true,
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													source: "fixed",
													dataType: "basic",
													dataKey:
														"ia_reco_updated_data",
													value: [],
													overwrite: true,
												},
											],
										},
									],
								},
							},
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3010,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										// sourceId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_4_flags_options",
										apiRequestKey: "data",
									},
									// {
									// 	destination: "reducer",
									// 	dataKey: "show_chips_for_ia_filter",
									// 	overwrite: true,
									// 	value: true,
									// },
								],
							},
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3100,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										// sourceId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_4_table_load_status",
										apiRequestKey: "data",
									},
								],
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									type: "check",
									conditionOperator: "and",
									conditions: [
										{
											comparison: "exists",
											source: "reducer",
											sourceId: "step_1_A",
											subjectId: "strategy_id",
											dataType: "basic",
										},
									],
								},
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3001,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										dataType: "basic",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "strategy_id",
									},
									{
										source: "fixed",
										dataType: "object",
										value: "strategy",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "section",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "maxStepCount",
										apiResponseKey: "step_count",
										dataType: "object",
									},
								],
							},
						],
					},
				],
			},
			// filter panel
			{
				type: "div",
				staticProps: {
					customStyle: {
						zIndex: 2,
						position: "absolute",
					},
				},
				dataProps: {
					is_visible: {
						type: "derived",
						dataKey: "showRecommendedSettingPanel",
					},
				},
				componentProps: {
					content: [
						{
							type: "panel",
							pathSrc: "components",
							componentPath: "ui/panel/Panel",
							staticProps: {
								size: "large",
								primaryButton: true,
								secondaryButton: true,
							},
							dataProps: {
								isOpen: {
									type: "derived",
									dataKey: "showRecommendedSettingPanel",
								},
							},
							componentProps: {
								content: [
									// product level buttons
									{
										type: "div",
										pathSrc: "components",
										componentPath:
											"ui/wrapperDiv/WrapperDiv",
										staticProps: {
											customStyle: {
												textAlign: "left",
												borderBottom:
													"1px dashed #D4D4D4",
												padding: "10px 0",
											},
										},
										componentProps: {
											content: [
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														variant: "h3",
														content:
															"Product level",
													},
												},
												{
													id: "Product",
													type: "buttonGroup",
													pathSrc: "components",
													componentPath:
														"ui/buttonGroup/ButtonGroup",
													staticProps: {
														isMultiSelect: false,
														variant: "outlined",
														customStyle: {
															margin: 0,
														},
														parentId:
															"temp_selected_filters_for_step4",
														filtersForPayload:
															"tempFiltersForPayloadStep4",
														label: "Product",
														changeFlagStatus:
															"trackSettingsChange",
													},
													dataProps: {
														buttons: {
															type: "derived",
															dataKey:
																"filters_for_step_4",
															subjectId:
																"product_level",
															saveWithLabel:
																"product_level",
															dataType: "object",
														},
														defaultActiveButtonId: {
															type: "derived",
															dataKey:
																"filtersForPayloadStep4",
															subjectId:
																"product_level",
														},
													},
												},
											],
										},
									},
									// store level buttons
									{
										type: "div",
										pathSrc: "components",
										componentPath:
											"ui/wrapperDiv/WrapperDiv",
										staticProps: {
											customStyle: {
												textAlign: "left",
												borderBottom:
													"1px dashed #D4D4D4",
												padding: "10px 0",
											},
										},
										componentProps: {
											content: [
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														variant: "h3",
														content: "Store level",
													},
												},
												{
													id: "Store",
													type: "buttonGroup",
													pathSrc: "components",
													componentPath:
														"ui/buttonGroup/ButtonGroup",
													staticProps: {
														isMultiSelect: false,
														variant: "outlined",
														label: "Store",
														customStyle: {
															margin: 0,
														},
														parentId:
															"temp_selected_filters_for_step4",
														filtersForPayload:
															"tempFiltersForPayloadStep4",
														changeFlagStatus:
															"trackSettingsChange",
													},
													dataProps: {
														buttons: {
															type: "derived",
															dataKey:
																"filters_for_step_4",
															subjectId:
																"store_level",
															saveWithLabel:
																"store_level",
															dataType: "object",
														},
														defaultActiveButtonId: {
															type: "derived",
															dataKey:
																"filtersForPayloadStep4",
															subjectId:
																"store_level",
														},
													},
												},
											],
										},
									},
									// week level buttons
									{
										type: "div",
										pathSrc: "components",
										componentPath:
											"ui/wrapperDiv/WrapperDiv",
										staticProps: {
											customStyle: {
												textAlign: "left",
												borderBottom:
													"1px dashed #D4D4D4",
												padding: "10px 0",
											},
										},
										componentProps: {
											content: [
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														variant: "h3",
														content: "Week level",
													},
												},
												{
													id: "Week",
													type: "buttonGroup",
													pathSrc: "components",
													componentPath:
														"ui/buttonGroup/ButtonGroup",
													staticProps: {
														isMultiSelect: true,
														label: "Week",
														variant: "outlined",
														customStyle: {
															margin: 0,
															display: "flex",
															flexWrap: "wrap",
															gap: "10px",
														},
														parentId:
															"temp_selected_filters_for_step4",
														filtersForPayload:
															"tempFiltersForPayloadStep4",
														changeFlagStatus:
															"trackSettingsChange",
													},
													dataProps: {
														buttons: {
															type: "derived",
															dataKey:
																"filters_for_step_4",
															subjectId:
																"pcd_metrics",
															saveWithLabel:
																"pcd_ids",
															dataType: "array",
														},
														defaultActiveButtonIds:
														{
															type: "derived",
															dataKey:
																"filtersForPayloadStep4",
															subjectId:
																"pcd_ids",
														},
													},
												},
											],
										},
									},
								],
							},
							functionProps: [
								{
									functionName: "onConfirm",
									actions: [
										{
											type: "reducer_function",
											params: [
												{
													source: "reducer",
													sourceId:
														"temp_selected_filters_for_step4",
												},
											],
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"tempFiltersForPayloadStep4",
														subjectId: "pcd_ids",
														dataType: "object",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"selected_filters_for_step4",
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													source: "fixed",
													dataType: "object",
													dataKey: "customCheckboxSelectionFlags",
													subjectId: "ia_recc_table",
													value: false,
													overwrite: true,
												},
											],
										},
										{
											type: "reducer_function",
											params: [
												{
													source: "reducer",
													sourceId:
														"tempFiltersForPayloadStep4",
												},
											],
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"tempFiltersForPayloadStep4",
														subjectId: "pcd_ids",
														dataType: "object",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"filtersForPayloadStep4",
												},
											],
										},
										{
											type: "reducer_function",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"tempFiltersForPayloadStep4",
														subjectId: "pcd_ids",
														dataType: "object",
													},
												],
											},
											responseFormatter: [
												{
													source: "fixed",
													dataType: "object",
													dataKey: "cells_lock_state",
													value: {},
													overwrite: true,
												},
											],
										},
										{
											type: "reducer_function",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"tempFiltersForPayloadStep4",
														subjectId: "pcd_ids",
														dataType: "object",
													},
												],
											},
											responseFormatter:
												[
													{
														source: "fixed",
														dataType:
															"object",
														dataKey:
															"ia_recc_table_data",
														value: [],
														overwrite: true,
													},
												],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"callIARecoTableAPI",
													dataType: "basic",
													value: true,
													overwrite: true,
												},
											],
										},
										{
											type: "reducer_function",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"tempFiltersForPayloadStep4",
														subjectId: "pcd_ids",
														dataType: "object",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showRecommendedSettingPanel",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
										{
											type: "reducer_function",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"tempFiltersForPayloadStep4",
														subjectId: "pcd_ids",
														dataType: "object",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showRecommendedSettingPanel",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onClose",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showRecommendedSettingPanel",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"filtersForPayloadStep4",
													subjectId:
														"trackSettingsChange",
													value: false,
												},
											],
										},
										// {
										// 	type: "reducer_function",
										// 	responseFormatter: [
										// 		{
										// 			destination: "reducer",
										// 			dataKey:
										// 				"show_chips_for_ia_filter",
										// 			overwrite: true,
										// 			value: true,
										// 		},
										// 	],
										// },
									],
								},
							],
						},
					],
				},
			},
			// bulk edit
			{
				type: "div",
				staticProps: {
					customStyle: {
						zIndex: 2,
						position: "fixed",
					},
				},
				dataProps: {
					is_visible: {
						type: "derived",
						dataKey: "show_IA_recommendation_bulk_edit_modal",
					},

					mountComponent: {
						type: "derived",
						dataKey: "show_IA_recommendation_bulk_edit_modal",
					},
				},
				componentProps: {
					content: [
						{
							type: "bulkEdit",
						},
					],
				},
			},
			// final modal alert
			{
				id: "ia_recommendation_finalize_alert",
				type: "modal",
				staticProps: {
					className: "",
					modalTitle: "Alert",
					primaryButtonText: "Submit",
					secondaryButtonText: "Cancel",
					modalSize: "small",
				},
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "show_IA_recommendation_finalize_alert",
					},
				},
				componentProps: {
					content: [
						{
							type: "text",
							staticProps: {
								customStyle: {
									textAlign: "left",
								},
								variant: "h5",
							},
							dataProps: {
								dynamicContent: {
									type: "derived",
									dataKey:
										"IA_recommendation_finalize_alert_message",
								},
							},
						},
					],
				},
				functionProps: [
					{
						functionName: "onConfirm",
						actions: [
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									type: "check",
									conditions: [
										{
											comparison: "equals",
											source: "reducer",
											sourceId: "step_4_flags_options",
											subjectId: "optimisation_type",
											checkValue:
												optimizationType.ia_optimization,
										},
									],
									conditionOperator: "or",
								},
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 3071,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "reducer",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "source",
										sourceId:
											"IA_recommendation_finalize_source",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										apiResponseKey: "conflict_records",
										dataKey:
											"finalize_strategy_conflict_table",
										overwrite: true,
										dataType: "array",
									},
								],
								onComplete: {
									actions: [
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"step_4_flags_options",
																subjectId:
																	"enable_bl_finalise",
																dataType:
																	"basic",
																value: false,
																overwrite: true,
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"step_4_flags_options",
																subjectId:
																	"enable_ia_finalise",
																dataType:
																	"basic",
																value: false,
																overwrite: true,
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"showFinalizeErrorOnStrategyConflictModal",
																dataType:
																	"basic",
																value: true,
																overwrite: true,
															},
														],
													},
												],
											},
										},
										{
											type: "validation",
											conditions: [
												{
													comparison: "notExists",
													source: "response",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "redirect",
														link: "workbench",
													},
												],
											},
										},
									],
									// actions: [
									// 	{
									// 		type: "redirect",
									// 		link: "workbench",
									// 	},
									// ],
								},
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									type: "check",
									conditions: [
										{
											comparison: "equals",
											source: "reducer",
											sourceId: "step_4_flags_options",
											subjectId: "optimisation_type",
											checkValue:
												optimizationType.partial_optimization,
										},
									],
									conditionOperator: "or",
								},
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 3014,
										dataType: "basic",
									},
									{
										source: "reducer",
										sourceId: "ia_reco_updated_data",
										apiRequestKeyNested: "discount_values",
										apiRequestKey: "parameters",
										dataType: "array",
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "session_storage",
										sourceId: "UNIQ_SSE_KEY",
										apiRequestKeyNested: "guid",
										apiRequestKey: "parameters",
									},
								],
								onComplete: {
									actions: [
										{
											type: "redirect",
											link: "workbench",
										},
									],
								},
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									type: "check",
									conditions: [
										{
											comparison: "equals",
											source: "reducer",
											sourceId: "step_4_flags_options",
											subjectId: "optimisation_type",
											checkValue: 2,
										},
										{
											comparison: "exists",
											source: "reducer",
											sourceId:
												"ia_copy_keys",
											subjectId: "source_pcd"
										},
										{
											comparison: "exists",
											source: "reducer",
											sourceId:
												"ia_copy_keys",
											subjectId: "destination_pcd"
										}
									],
									conditionOperator: "and",
								},
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 3008,
										dataType: "basic",
									},
									{
										source: "reducer",
										sourceId: "ia_reco_updated_data",
										apiRequestKeyNested: "discount_values",
										apiRequestKey: "parameters",
										dataType: "array",
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										// sourceId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "session_storage",
										sourceId: "UNIQ_SSE_KEY",
										apiRequestKeyNested: "guid",
										apiRequestKey: "parameters",
									},
									{
										source: "fixed",
										dataType:
											"object",
										apiRequestKeyNested:
											"copy_ia",
										value: true,
										apiRequestKey:
											"parameters",
									},
								],
								onComplete: {
									// on complete show the loader
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"show_loader_until_simulate_complete",
													value: true,
												},
											],
										},
									],
								},
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									type: "check",
									conditions: [
										{
											comparison: "equals",
											source: "reducer",
											sourceId: "step_4_flags_options",
											subjectId: "optimisation_type",
											checkValue: 2,
										},
										{
											comparison: "notExists",
											source: "reducer",
											sourceId:
												"ia_copy_keys",
											subjectId: "source_pcd"
										},
										{
											comparison: "notExists",
											source: "reducer",
											sourceId:
												"ia_copy_keys",
											subjectId: "destination_pcd"
										}
									],
									conditionOperator: "and",
								},
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 3008,
										dataType: "basic",
									},
									{
										source: "reducer",
										sourceId: "ia_reco_updated_data",
										apiRequestKeyNested: "discount_values",
										apiRequestKey: "parameters",
										dataType: "array",
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										// sourceId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "session_storage",
										sourceId: "UNIQ_SSE_KEY",
										apiRequestKeyNested: "guid",
										apiRequestKey: "parameters",
									},
								],
								onComplete: {
									// on complete show the loader
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"show_loader_until_simulate_complete",
													value: true,
												},
											],
										},
										{
											type: "reducer_function",
											responseFormatter:
												[
													{
														source:
															"fixed",
														dataKey:
															"copyIATableAPIFlag",
														value: false,
														overwrite: true,
													},
												],
										},
									],
								},
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"show_IA_recommendation_finalize_alert",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"show_IA_recommendation_finalize_alert",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
			//Strategy conflict table modal on Finalize
			{
				id: "finalize_strategy_conflict_modal",
				type: "modal",
				staticProps: {
					className: "",
					modalTitle: "Alert",
					primaryButtonText: "",
					secondaryButtonText: "Cancel",
					modalSize: "large",
					primaryButtonDisabled: true,
				},
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "showFinalizeErrorOnStrategyConflictModal",
					},
				},
				componentProps: {
					content: [
						{
							id: "finalize_Strategy_conflict_table",
							type: "aggrid",
							staticProps: {
								height: "300px",
								tableId: "finalize_Strategy_conflict_table",
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "Strategy ID",
											key: "strategy_id",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Strategy",
											key: "strategy_name",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Start Date",
											key: "start_date",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "End Date",
											key: "end_date",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Product ID",
											key: "product_h5_id",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Product",
											key: "product_h5_name",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Store ID",
											key: "store_h6_id",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Store",
											key: "store_h6_name",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
									],
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey: "finalize_strategy_conflict_table",
								},
							},
						},
					],
				},
				functionProps: [
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"showFinalizeErrorOnStrategyConflictModal",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
			// loader
			{
				type: "loader",
				dataProps: {
					showLoader: {
						type: "derived",
						dataKey: "show_loader_until_simulate_complete",
					},
				},
			},
			{
				type: "gifModal",
				dataProps: {
					totalRows: {
						type: "derived",
						dataKey:
							"step_4_table_load_status",
						subjectId:
							"total_rows",
					}
				},
				staticProps: {
					brewText: "Your table is brewing",
					dataKey: "step_4_table_load_status",
					gifSubjectId: "data_present",

				}

			}
		],
	},
};
