export const LANDING_SCREEN = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-content-emptystate",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            margin: "2px 10px",
            // border: "1px solid",
            // borderRadius: "10px",
            // borderColor: "#808080",
            // height: "600px",
          },
        },
        componentProps: {
          content: [
            {
              type: "text",
              staticProps: {
                marginTop: "20px",
                content: "Plan Smart Configurator",
                customStyle: {
                  fontWeight: "600",
                  fontSize: "18px",
                  display: "flex",
                  marginTop: "10px",
                },
              },
            },
            {
              type: "text",
              staticProps: {
                marginTop: "20px",
                fontWeight: "500",
                content: "Client Level Configuration",
                customStyle: {
                  fontSize: "16px",
                  display: "flex",
                  marginTop: "10px",
                  fontWeight: "500",
                },
              },
            },
            {
              type: "div",
              id: "configuration-wrapper",
              staticProps: {
                customStyle: {
                  display: "flex",
                  gap: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "module-configuration",
                    componentProps: {
                      content: [
                        {
                          type: "card",
                          staticProps: {
                            mainHeading: "Module Configuration",
                            subHeading: "Select what modules should be enabled",
                            customStyle: {
                              margin: "0px",
                              width: "380px",
                              height: "110px",
                              fontSize: "14px",
                              fontWeight: "600",
                            },
                          },
                        },
                      ],
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "module-configuration",
                          },
                        ],
                      },
                    ],
                  },
                  // {
                  //   type: "div",
                  //   id: "version-configuration",
                  //   componentProps: {
                  //     content: [
                  //       {
                  //         type: "card",
                  //         staticProps: {
                  //           mainHeading: "Version Configuration",
                  //           subHeading: "Maintain versions of data for client",
                  //           customStyle: {
                  //             margin: "2px 10px",
                  //             width: "380px",
                  //             height: "110px",
                  //           },
                  //         },
                  //       },
                  //     ],
                  //   },
                  //   functionProps: [
                  //     {
                  //       functionName: "onClick",
                  //       actions: [
                  //         {
                  //           type: "redirect",
                  //           link: "module-configuration",
                  //         },
                  //       ],
                  //     },
                  //   ],
                  // },
                  {
                    type: "div",
                    id: "out-of-box-kpi",
                    componentProps: {
                      content: [
                        {
                          type: "card",
                          staticProps: {
                            mainHeading: "Out of the box KPI Configuration",
                            subHeading:
                              "Manage the KPI's defination for the client",
                            customStyle: {
                              margin: "2px 10px",
                              width: "380px",
                              height: "110px",
                              fontSize: "14px",
                              fontWeight: "600",
                            },
                          },
                        },
                      ],
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "add-new-configurator",
                          },
                          {
                            type: "reducer_function",
                            responseFormatter: [
                              {
                                destination: "reducer",
                                dataKey: "sidebarNavigationData",
                                value: {
                                  id: 1,
                                  title: "KPI Configurator",
                                  sub_sections: [
                                    {
                                      id: 2,
                                      title: "KPI Details",
                                      config_id: "9",
                                      config_type: "filterConfig",
                                    },
                                  ],
                                },

                                dataType: "array",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              //   functionProps: [
              //     {
              //       functionName: "onClick",
              //       actions: [
              //         {
              //           type: "redirect",
              //           link: "module-configuration",
              //         },
              //       ],
              //     },
              //   ],
            },
            // {
            //   id: "create-product-group-divider",
            //   type: "divider",
            //   staticProps: {
            //     margin: "20px 0px",
            //   },
            // },
            // {
            //   type: "text",
            //   staticProps: {
            //     variant: "h4",
            //     marginTop: "20px",
            //     content: "Module Level Configuration",
            //   },
            // },
            // {
            //   type: "div",
            //   id: "configuration-wrapper",
            //   staticProps: {
            //     customStyle: {
            //       display: "flex",
            //     },
            //   },
            //   componentProps: {
            //     content: [
            //       {
            //         type: "div",
            //         id: "filter-module-configuration",
            //         componentProps: {
            //           content: [
            //             {
            //               type: "card",
            //               staticProps: {
            //                 mainHeading: "Module Filter Configuration",
            //                 subHeading:
            //                   "Set up the filters so that a new module plan can be seen",
            //                 customStyle: {
            //                   margin: "2px 10px",
            //                   width: "380px",
            //                   height: "110px",
            //                 },
            //               },
            //             },
            //           ],
            //         },
            //         functionProps: [
            //           {
            //             functionName: "onClick",
            //             actions: [
            //               {
            //                 type: "redirect",
            //                 link: "filter-module-configuration",
            //               },
            //             ],
            //           },
            //         ],
            //       },
            //     ],
            //   },
            // },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "text",
              staticProps: {
                margin: "0px",
                content: "Planning Screen Configurations",
                customStyle: {
                  fontSize: "16px",
                  display: "flex",
                  fontWeight: "500",
                },
              },
            },

            {
              type: "div",
              staticProps: {
                customStyle: {
                  display: "flex",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "configDesign",
                    staticProps: {
                      isConfig: true,
                    },
                    dataProps: {
                      data: {
                        type: "derived",
                        dataKey: "configs",
                        dataType: "array",
                      },
                    },
                  },
                  {
                    type: "button",
                    staticProps: {
                      variant: "primary",
                      label: "Add new configuration",
                      customStyle: {
                        marginLeft: "20px",
                        position: "relative",
                        top: "-90px",
                        borderRadius: "4px",
                        width: "221px",
                        height: "37px",
                        padding: "8px 24px 8px 24px",
                      },
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "reducer_function",
                            responseFormatter: [
                              {
                                destination: "reducer",
                                dataKey: "fromSelectedConfig",
                                value: false,
                                dataType: "basic",
                              },
                            ],
                          },
                          {
                            type: "redirect",
                            link: "add-new-config",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 70,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "null",
                    dataType: "basic",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "configs",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "select-channel",
                    subjectId: "selectedItems",
                    value: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
