import { rules_landing_screen_table_column_config } from "../../components/rules/RulesDemoJson";
export const RULES_PAGE = {
  screen_composition: {
    components: [
        //Filter modal open
        {
          id: "all_rules_filter",
          type: "filter",
          staticProps: {
            showSaveFilter: false,
            showPageHeader: true,
            showSaveFilter: true,
            filterHeader: "Filters",
            pageHeader: "Filters",
            accordionLabel: "Filters",
            showFilterButtons: true,
            showFilterChips: true,
            filter_config: [
              {
                type: "rule_type",
                is_mandatory: false,
                is_multiple_selection: true,
                filter_endpoint: "model",
                filter_model: 9002,
                filter_type: "rule_type",
                filter_group: 1,
                filter_group_label: "Rule selection",
                hideSearch: false,
              },
              {
                type: "rule_flexibility_type_id",
                is_mandatory: false,
                is_multiple_selection: true,
                filter_endpoint: "model",
                filter_model: 9003,
                filter_type: "rule_flexibility_type_id",
                filter_group: 1,
                filter_group_label: "Flexibility",
                hideSearch: false,
              },
              {
                type: "rule_id",
                is_mandatory: false,
                is_multiple_selection: true,
                filter_endpoint: "model",
                filter_model: 9004,
                filter_type: "rule_id",
                filter_group: 1,
                filter_group_label: "Rule Name",
                hideSearch: false,
              },
            ],
          },
          functionProps: [
            {
              functionName: "onFilter",
              actions: [
                {
                  type: "api_function",
                  apiEndPoint: "model",
                  apiMethod: "post",
                  params: [
                    {
                      source: "fixed",
                      apiRequestKey: "id",
                      value: 9001,
                      dataType: "basic",
                    },
                    {
                      source: "filters",
                      dataType: "object",
                      apiRequestKey: "parameters",
                    },
                  ],
                  responseFormatter: [
                    {
                      destination: "reducer",
                      dataKey: "all_rules_table",
                      subjectId:"all_rules",
                      overwrite: true,
                      dataType: "array",
                    },
                  ],
                },
              ],
            },
          ],
          dataProps: {
            filterModalOpen: {
              type: "derived",
              dataKey: "filterModalOpen",
            },
          },
        },
      {
        type: "div",
        staticProps: {
          className: "common-content-container",
          display: {
						type: "controlled",
						default: {
							show: true,
						},
					},
        },
        
        componentProps: {
          content: [
            {
              id: "all_rules",
              type: "aggrid",
              // rowSelection: "",
              staticProps: {
                tableTitle: "All Rules",
                height: "350px",
                borderBottom: "1px solid #EBECF0",
                paddingBottom: "20px",
                disableDomLayout: true,
                rowDraggable: false,
                tableId: "all_rules",
                uniqueKey: "rule_id",
                tableConfig: { ...rules_landing_screen_table_column_config },
                // className: "rules_strategy",
                stopEditingWhenCellsLoseFocus: true,
                // rowStyleClass: "rowstyle_rules_strategy",
                enableDisableRowFunc: true,
                // orderKey: "priority",
                // sortByKeys: ["priority", "row_num"],
                // disabled_key: "is_disabled",
                domLayout: true,
                updateParent: true,
              },
              dataProps: {
                data: {
                  type: "derived",
                  dataKey: "all_rules_table",
                  subjectId:"all_rules",
                },
              },
              functionProps: [
                {
                  functionName: "onCellValueChanged",
                  actions: [
                    {
                      type: "reducer_function",
                      params: [
                        {
                          source: "self",
                          apiRequestKey: "all_rules",
                          dataType: "array",
                        },
                      ],
                      responseFormatter: [
                        {
                          destination: "reducer",
                          dataKey: "all_rules_table",
                          sourceId: "all_rules",
                          dataType: "array",
                        },
                        // {
                        //   destination: "reducer",
                        //   dataKey: "changeFlagForRules",
                        //   value: true,
                        // },
                      ],
                    },
                  ],
                },
              ],
              componentProps: {
                content: [
                  {
                    alignment: "right",
                    id: "rules-view-details",
                    type: "button",
                    pathSrc: "components",
                    componentPath: "ui/button/Button",
                    staticProps: {
                      variant: "primary",
                      label:"Create New Rule"
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "reducer_function",
                            responseFormatter: [
                              {
                                destination: "reducer",
                                dataKey: "rules_overlay_flag",
                                dataType: "basic",
                                value: true,
                                overwrite: true,
                              },
                            ],
                          },
                        ],
                      },,
                    ],
                  },
                ],
              },
            },
          ],
        },
        functionProps:[
          {
            functionName:"onLoad",
            actions:[
              {
                type:"api_function",
                apiEndPoint:"model",
                apiMethod:"post",
                params:[
                  {
                    source:"fixed",
                    apiRequestKey:"id",
                    value:9001,
                    dataType:"object",
                    // overwrite:true,
                  }
                ],
                responseFormatter:[
                  {
                   destination:"reducer",
                   dataKey:"all_rules_table",
                   subjectId:"all_rules",
                   dataType:"array" ,
                   overwrite:true,
                  }
                ]
              }
            ]
          }
        ]
      },
      // Overlay
      {
        type: "fullScreenOverlay",
        componentProps: {
          content: [
            {
              type: "div",
              staticProps: {
                // className: "common-content-container",
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    staticProps: {
                      className: "flex-container",
                    },
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          staticProps: {
                            className: "common-page-title",
                            customStyle: {
                              margin: "0",
                            },
                            variant: "h2",
                            content: "All Rules",
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    staticProps: {
                      className: "flex-container",
                      customStyle: {
                        marginTop: "20px",
                        flexWrap: "wrap",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          staticProps: {
                            className: "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant: "h3",
                                  content: "Min - Max Discount %",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className: "rules-description",
                                  content:
                                    "Defines relationship between Private labels and National brands or between different brands",
                                },
                              },
                              {
                                id: "rules-view-details",
                                type: "button",
                                pathSrc: "components",
                                componentPath: "ui/button/Button",
                                staticProps: {
                                  className: "rules-view-details-button",
                                  variant: "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName: "onClick",
                                    actions: [
                                      {
                                        type: "reducer_function",
                                        responseFormatter: [
                                          {
                                            destination: "reducer",
                                            dataKey: "rules_overlay_flag",
                                            dataType: "basic",
                                            value: true,
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "create_rule_page_header",
                                            dataType: "basic",
                                            value: "Create New Min-Max Rule",
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "rule_type",
                                            dataType: "basic",
                                            value: "min_max_percent",
                                            overwrite: true,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "div",
                          staticProps: {
                            className: "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant: "h3",
                                  content: "Step Size %",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className: "rules-description",
                                  content:
                                    "Defines size-value relationship by making sure larger products have higher prices and also offers better value",
                                },
                              },
                              {
                                type: "button",
                                pathSrc: "components",
                                componentPath: "ui/button/Button",
                                staticProps: {
                                  className: "rules-view-details-button",
                                  variant: "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName: "onClick",
                                    actions: [
                                      {
                                        type: "reducer_function",
                                        responseFormatter: [
                                          {
                                            destination: "reducer",
                                            dataKey: "rules_overlay_flag",
                                            dataType: "basic",
                                            value: true,
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "create_rule_page_header",
                                            dataType: "basic",
                                            value: "Create New Step Size % Rule",
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "rule_type",
                                            dataType: "basic",
                                            value: "step_size_percent",
                                            overwrite: true,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "div",
                          staticProps: {
                            className: "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant: "h3",
                                  content: "Number of Markdowns",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className: "rules-description",
                                  content:
                                    "Allows you to decide number of markdowns that are applicable to selected products",
                                },
                              },
                              {
                                type: "button",
                                pathSrc: "components",
                                componentPath: "ui/button/Button",
                                staticProps: {
                                  className: "rules-view-details-button",
                                  variant: "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName: "onClick",
                                    actions: [
                                      {
                                        type: "reducer_function",
                                        responseFormatter: [
                                          {
                                            destination: "reducer",
                                            dataKey: "rules_overlay_flag",
                                            dataType: "basic",
                                            value: true,
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "create_rule_page_header",
                                            dataType: "basic",
                                            value: "Create New Number of Markdowns Rule",
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "rule_type",
                                            dataType: "basic",
                                            value: "no_of_markdown",
                                            overwrite: true,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "div",
                          staticProps: {
                            className: "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant: "h3",
                                  content: "Minimum Weeks on same price point",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className: "rules-description",
                                  content:
                                    "Allows how often does a MD needs to be changed for the selected lot",
                                },
                              },
                              {
                                type: "button",
                                pathSrc: "components",
                                componentPath: "ui/button/Button",
                                staticProps: {
                                  className: "rules-view-details-button",
                                  variant: "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName: "onClick",
                                    actions: [
                                      {
                                        type: "reducer_function",
                                        responseFormatter: [
                                          {
                                            destination: "reducer",
                                            dataKey: "rules_overlay_flag",
                                            dataType: "basic",
                                            value: true,
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "create_rule_page_header",
                                            dataType: "basic",
                                            value:
                                              "Create New Minimum Weeks on same price point",
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "rule_type",
                                            dataType: "basic",
                                            value: "min_week_pp",
                                            overwrite: true,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "div",
                          staticProps: {
                            className: "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant: "h3",
                                  content: "Applicable Discount %",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className: "rules-description",
                                  content:
                                    "Defines the allowable discounts for the selected products and stores",
                                },
                              },
                              {
                                type: "button",
                                pathSrc: "components",
                                componentPath: "ui/button/Button",
                                staticProps: {
                                  className: "rules-view-details-button",
                                  variant: "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName: "onClick",
                                    actions: [
                                      {
                                        type: "reducer_function",
                                        responseFormatter: [
                                          {
                                            destination: "reducer",
                                            dataKey: "rules_overlay_flag",
                                            dataType: "basic",
                                            value: true,
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "create_rule_page_header",
                                            dataType: "basic",
                                            value: "Create New Applicable Discount % Rule",
                                            overwrite: true,
                                          },
                                          {
                                            destination: "reducer",
                                            dataKey: "rule_type",
                                            dataType: "basic",
                                            value: "applicable_discount_percent",
                                            overwrite: true,
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
        dataProps: {
          showModal: {
            type: "derived",
            dataKey: "rules_overlay_flag",
          },
        },
      },
    ],
  },
};
