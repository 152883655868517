import React, { useEffect } from "react";
import { verifyTokenStatus } from "../components/auth/actions/authActions";
import { getTenantId } from "../store/features/auth/auth";
import { connect, useDispatch } from "react-redux";
import { firebaseobj } from "../components/auth/components/FirebaseInitialize";
const MetaDataComponent = (props) => {
  const dispatch = useDispatch();

  const checkUsrSession = () => {
    props.checkUserSession();
  };
  const fetchDomainDetails = () => {
    let loc = new URL("https://ootb-plansmart.devs.impactsmartsuite.com");
    dispatch(getTenantId(loc.hostname));
  };
  useEffect(() => {
    console.log("fetching tenet info");
    fetchDomainDetails();
  }, []);
  useEffect(() => {
    if (props.tenantDetails !== null) {
      firebaseobj.auth().tenantId = props?.tenantDetails;
      checkUsrSession();
    }
  }, [props.tenantDetails]);
  return <>{props.children}</>;
};

const mapStateToProps = (state) => ({
  tenantDetails: state.auth.tenantId,
});
const mapActionToProps = {
  checkUserSession: verifyTokenStatus,
};

export default connect(mapStateToProps, mapActionToProps)(MetaDataComponent);
