import React from "react";
import moment from "moment";
import _ from "lodash";
import store from "../../store";
import {
  updateReducerState,
  setSelectedRows,
  invokeReducerAction,
} from "../../store/features/appReducer/appReducer";
import { toastError } from "../../store/features/global/global";
import { pcdValidation, checkForDateChange } from "./pcdValidationHelper";
import { RulesValidation } from "./rulesValidationHelper";

export const getFormattedParams = (actionParams, data, screen) => {
  let params = {};
  actionParams?.map((actionParam) => {
    switch (actionParam.source) {
      case "reducer":
        const storedData = store.getState()?.app?.[actionParam.sourceId];
        let nestedResponse;
        // nestedResponse is used to support nested response
        if (actionParam?.subjectidNested1) {
          nestedResponse =
            storedData?.[actionParam.subjectId]?.[
              actionParam?.subjectidNested
            ]?.[actionParam?.subjectidNested1];
        } else if (actionParam?.subjectidNested) {
          nestedResponse =
            storedData?.[actionParam.subjectId]?.[actionParam?.subjectidNested];
        } else {
          nestedResponse = storedData?.[actionParam.subjectId];
        }
        if (actionParam.dataType === "file") {
          params = {
            ...params,
            [actionParam.apiRequestKey]: actionParam.subjectId
              ? !_.isEmpty(params?.[actionParam.apiRequestKey])
                ? {
                    ...params[actionParam.apiRequestKey],
                    [actionParam.apiRequestKeyNested]:
                      storedData?.[actionParam.subjectId],
                  }
                : actionParam.apiRequestKeyNested
                ? {
                    [actionParam.apiRequestKeyNested]:
                      storedData?.[actionParam.subjectId],
                  }
                : storedData?.[actionParam.subjectId]
              : storedData,
            fileData: true,
          };
        } else {
          if (actionParam.apiRequestKey) {
            params = {
              ...params,
              [actionParam.apiRequestKey]: actionParam.subjectId
                ? !_.isEmpty(params?.[actionParam.apiRequestKey])
                  ? {
                      ...params?.[actionParam.apiRequestKey],
                      [actionParam.apiRequestKeyNested]: nestedResponse,
                    }
                  : actionParam.apiRequestKeyNested
                  ? {
                      [actionParam.apiRequestKeyNested]: nestedResponse,
                    }
                  : nestedResponse
                : !_.isEmpty(params?.[actionParam.apiRequestKey])
                ? {
                    ...params?.[actionParam.apiRequestKey],
                    [actionParam.apiRequestKeyNested]: storedData,
                  }
                : nestedResponse
                ? {
                    [actionParam.apiRequestKeyNested]: storedData,
                  }
                : storedData,
            };
          } else if (actionParam.subjectidNested) {
            if (actionParam.dataType === "array") {
              params = !_.isEmpty(params)
                ? [
                    ...params,
                    ...storedData?.[actionParam.subjectId]?.[
                      actionParam.subjectidNested
                    ],
                  ]
                : storedData?.[actionParam.subjectId]?.[
                    actionParam.subjectidNested
                  ];
            } else {
              params = {
                ...params,
                ...storedData?.[actionParam.subjectId]?.[
                  actionParam.subjectidNested
                ],
              };
            }
          } else if (actionParam.subjectId) {
            if (actionParam.dataType === "array") {
              params = !_.isEmpty(params)
                ? [...params, ...storedData?.[actionParam.subjectId]]
                : storedData?.[actionParam.subjectId];
            } else {
              params = {
                ...params,
                ...storedData?.[actionParam.subjectId],
              };
            }
          } else {
            if (actionParam.dataType === "array") {
              params = !_.isEmpty(params)
                ? [...params, ...storedData]
                : storedData;
            } else {
              params = {
                ...params,
                ...storedData,
              };
            }
          }
        }
        break;

      case "table":
        // Get table from control reducer with id = sourceId
        // apiRequestKey should be same as the selection key or unique key in the table
        const selectedRows =
          store.getState()?.app?.tableData?.[actionParam.sourceId];
        if (actionParam.dataType === "basic") {
          if (actionParam.subjectId) {
            params = {
              ...params,
              [actionParam?.apiRequestKey]: !_.isEmpty(
                params?.[actionParam?.apiRequestKey]
              )
                ? {
                    ...params?.[actionParam?.apiRequestKey],
                    [actionParam.subjectId]:
                      selectedRows?.[0]?.[actionParam?.subjectId],
                  }
                : !_.isEmpty(actionParam?.apiRequestKeyNested)
                ? {
                    [actionParam.apiRequestKeyNested]:
                      selectedRows?.[0]?.[actionParam?.subjectId],
                  }
                : selectedRows?.[0]?.[actionParam?.subjectId],
            };
          } else {
            params = {
              ...params,
              [actionParam?.apiRequestKey]: actionParam.selectEntireRow
                ? selectedRows?.[0]
                : selectedRows?.[0]?.[actionParam?.apiRequestKey],
            };
          }
        } else if (actionParam.dataType === "array") {
          if (actionParam?.subjectId) {
            params = {
              ...params,
              [actionParam?.apiRequestKey]: !_.isEmpty(
                params?.[actionParam?.apiRequestKey]
              )
                ? {
                    ...params?.[actionParam?.apiRequestKey],
                    [actionParam.apiRequestKeyNested || actionParam.subjectId]:
                      actionParam.selectEntireRow
                        ? selectedRows
                        : selectedRows?.map(
                            (row) => row?.[actionParam?.subjectId]
                          ),
                  }
                : actionParam.apiRequestKeyNested
                ? {
                    [actionParam.apiRequestKeyNested]:
                      actionParam.selectEntireRow
                        ? selectedRows
                        : selectedRows?.map(
                            (row) => row?.[actionParam?.subjectId]
                          ),
                  }
                : actionParam.selectEntireRow
                ? selectedRows
                : selectedRows?.map((row) => row?.[actionParam?.subjectId]),
            };
          } else if (actionParam.subjectIdMultiple) {
            params = {
              ...params,
              [actionParam?.apiRequestKey]: !_.isEmpty(
                params?.[actionParam?.apiRequestKey]
              )
                ? {
                    ...params?.[actionParam?.apiRequestKey],
                    [actionParam.apiRequestKeyNested]:
                      actionParam.selectEntireRow
                        ? selectedRows
                        : selectedRows?.map((row) => {
                            let reqObj = {};
                            actionParam.subjectIdMultiple?.forEach((subId) => {
                              reqObj[subId] = row?.[subId];
                            });
                            return reqObj;
                          }),
                  }
                : actionParam.apiRequestKeyNested
                ? {
                    [actionParam.apiRequestKeyNested]:
                      actionParam.selectEntireRow
                        ? selectedRows
                        : selectedRows?.map((row) => {
                            let reqObj = {};
                            actionParam.subjectIdMultiple?.forEach((subId) => {
                              reqObj[subId] = row?.[subId];
                            });
                            return reqObj;
                          }),
                  }
                : actionParam.selectEntireRow
                ? selectedRows
                : selectedRows?.map((row) => {
                    let reqObj = {};
                    actionParam.subjectIdMultiple?.forEach((subId) => {
                      reqObj[subId] = row?.[subId];
                    });
                    return reqObj;
                  }),
            };
          } else {
            params = {
              ...params,
              [actionParam?.apiRequestKey]: !_.isEmpty(
                params?.[actionParam?.apiRequestKey]
              )
                ? {
                    ...params?.[actionParam?.apiRequestKey],
                    [actionParam.apiRequestKeyNested]:
                      actionParam.selectEntireRow
                        ? selectedRows
                        : selectedRows?.map(
                            (row) => row?.[actionParam?.apiRequestKey]
                          ),
                  }
                : actionParam.selectEntireRow
                ? selectedRows
                : selectedRows?.map((row) => row?.[actionParam?.apiRequestKey]),
            };
          }
        }
        break;
      case "url":
        // Check for URL param
        const query = new URLSearchParams(window.location.search);
        const val = query.get(actionParam.apiRequestKey)
          ? query.get(actionParam.apiRequestKey)
          : null;
        params = {
          ...params,
          [actionParam.apiRequestKey]: val,
        };
        break;
      case "filters":
        let filtersData = {};
        const filterReducerData = _.cloneDeep(
          store.getState().filters.lastSavedFilters?.[screen]?.filtersData
        );
        !_.isEmpty(filterReducerData) &&
          Object.keys(filterReducerData)?.map((key) => {
            if (key === "dateRange") {
              Object.keys(filterReducerData[key])?.map((dateKey) => {
                filtersData[dateKey] = filterReducerData[key][dateKey];
              });
            } else {
              filtersData[key] = filterReducerData[key]["selectedItemsArray"];
            }
          });
        if (actionParam.apiRequestKey) {
          params = {
            ...params,
            [actionParam.apiRequestKey]: filtersData,
          };
        } else {
          params = {
            ...params,
            ...filtersData,
          };
        }
        break;
      case "fixed":
        params = {
          ...params,
          [actionParam.apiRequestKey]: !_.isEmpty(
            params[actionParam.apiRequestKey]
          )
            ? {
                ...params[actionParam.apiRequestKey],
                [actionParam.apiRequestKeyNested]: actionParam.value,
              }
            : !_.isEmpty(actionParam.apiRequestKeyNested)
            ? {
                [actionParam.apiRequestKeyNested]: actionParam.value,
              }
            : actionParam.value,
        };
        break;
      case "self":
        if (actionParam.dataType === "array") {
          params = {
            ...params,
            [actionParam.apiRequestKey]: !_.isEmpty(
              params[actionParam.apiRequestKey]
            )
              ? {
                  ...params[actionParam.apiRequestKey],
                  [actionParam.apiRequestKeyNested]: data,
                }
              : !_.isEmpty(actionParam.apiRequestKeyNested)
              ? {
                  [actionParam.apiRequestKeyNested]: data,
                }
              : data,
          };
        } else if (actionParam.dataType === "selfArray") {
          params = {
            ...params,
            [actionParam.apiRequestKey]: !_.isEmpty(
              params[actionParam.apiRequestKey]
            )
              ? {
                  ...params[actionParam.apiRequestKey],
                  [actionParam.apiRequestKeyNested]: actionParam?.subjectId
                    ? [data?.[actionParam?.subjectId]]
                    : [data],
                }
              : !_.isEmpty(actionParam.apiRequestKeyNested)
              ? {
                  [actionParam.apiRequestKeyNested]: actionParam?.subjectId
                    ? [data?.[actionParam?.subjectId]]
                    : [data],
                }
              : actionParam?.subjectId
              ? [data?.[actionParam?.subjectId]]
              : [data],
          };
        } else if (actionParam.dataType === "object") {
          if (actionParam?.apiRequestKey)
            params = {
              ...params,
              [actionParam.apiRequestKey]: { ...data },
            };
          else {
            params = {
              ...params,
              ...data,
            };
          }
        } else if (actionParam.dataType === "file") {
          params = {
            ...params,
            [actionParam.apiRequestKey]: data,
            fileData: true,
          };
        } else {
          params = {
            ...params,
            [actionParam.apiRequestKey]: actionParam?.subjectId
              ? data[actionParam?.subjectId]
              : data,
          };
        }
        break;
      case "session_storage":
        let sessionStorageValue = sessionStorage.getItem(actionParam.sourceId);
        let isValidation = true;
        if (actionParam?.conditions) {
          _.map(actionParam.conditions, (condition) => {
            if (condition.source === "reducer") {
              let storedData = condition?.subjectId
                ? store.getState()?.app?.[condition.sourceId]?.[
                    condition.subjectId
                  ]
                : store.getState()?.app?.[condition.sourceId];
              if (storedData !== condition.value) isValidation = false;
            }
          });
        }
        params = isValidation
          ? {
              ...params,
              [actionParam.apiRequestKey]: !_.isEmpty(
                params?.[actionParam.apiRequestKey]
              )
                ? {
                    ...params[actionParam.apiRequestKey],
                    [actionParam.apiRequestKeyNested]: sessionStorageValue,
                  }
                : actionParam.apiRequestKeyNested
                ? {
                    [actionParam.apiRequestKeyNested]: sessionStorageValue,
                  }
                : sessionStorageValue,
            }
          : { ...params };
        break;
      default:
        break;
    }
    if (
      actionParam.distinct &&
      actionParam.dataType === "array" &&
      !_.isEmpty(params[actionParam?.apiRequestKey])
    ) {
      if (
        actionParam?.apiRequestKeyNested &&
        !_.isEmpty(
          params[actionParam?.apiRequestKey][actionParam?.apiRequestKeyNested]
        )
      ) {
        let data =
          params?.[actionParam?.apiRequestKey]?.[
            actionParam?.apiRequestKeyNested
          ];
        params[actionParam?.apiRequestKey][actionParam?.apiRequestKeyNested] =
          actionParam.distinctType === "object"
            ? [
                ...new Map(
                  data?.map((item) => [item[actionParam.subjectId], item])
                ),
              ]
            : [...new Set(data?.map((item) => item))];
      } else {
        let data = params?.[actionParam?.apiRequestKey];
        params[actionParam?.apiRequestKey] =
          actionParam.distinctType === "object"
            ? [
                ...new Map(
                  data?.map((item) => [item[actionParam.subjectId], item])
                ),
              ]
            : [...new Set(data?.map((item) => item))];
      }
    }
  });
  return params;
};

export const executeAction = async ({
  func,
  params,
  onComplete,
  data,
  responseFormatter,
  apiEndPoint,
  apiMethod,
  screen,
  confirmation,
  navigate,
}) => {
  if (!_.isEmpty(params)) {
    // If there is a confirmation, show modal based on condition
    let proceedOnConfirmation = true;
    if (!_.isEmpty(confirmation)) {
      proceedOnConfirmation = executeConfirmation(
        confirmation,
        data,
        screen,
        navigate
      );
    }

    // Stop the processing here if the confirmation modal needs to be shown
    if (!proceedOnConfirmation) return;
    // Get parameters
    const formattedParams = getFormattedParams(params, data, screen);
    let resp = {};
    // Change this After API is Raedy This is just for test and as a placeholder
    // Call the API or Action
    resp = await func({
      apiEndPoint,
      apiMethod,
      payload: formattedParams,
    });

    // If there is response to be reloaded into data, call the response formatter
    if (resp !== false && !_.isEmpty(responseFormatter)) {
      loadResponseIntoStore(responseFormatter, resp, screen);
    }

    if (resp !== false && !_.isEmpty(onComplete)) {
      // Execute the oncomplete api/reducer actions and return the redirect action
      return executeOnCompleteActions(onComplete, data, screen, resp, navigate);
    }

    return false;
  }
};

export const executeReducerAction = ({
  params,
  onComplete,
  data,
  responseFormatter,
  screen,
  confirmation,
  navigate,
}) => {
  // If there is a confirmation, show modal based on condition
  let proceedOnConfirmation = true;
  if (!_.isEmpty(confirmation)) {
    proceedOnConfirmation = executeConfirmation(
      confirmation,
      data,
      screen,
      navigate
    );
  }

  // Stop the processing here if the confirmation modal needs to be shown
  if (!proceedOnConfirmation) return;

  const formattedParams = getFormattedParams(params, data, screen);
  if (!_.isEmpty(responseFormatter)) {
    loadResponseIntoStore(responseFormatter, formattedParams, screen);
  }

  if (!_.isEmpty(onComplete)) {
    // Execute the oncomplete api/reducer actions and return the redirect action
    return executeOnCompleteActions(onComplete, data, screen, null, navigate);
  }
};

export const executeOnCompleteActions = async (
  onComplete,
  data,
  screen,
  response,
  navigate
) => {
  let redirectAction = onComplete?.actions?.filter(
    (action) => action.type === "redirect"
  );
  const apiActions = onComplete?.actions?.filter(
    (action) =>
      action.type === "api_function" || action.type === "reducer_function"
  );
  const validationActions = onComplete?.actions?.filter(
    (action) => action.type === "validation"
  );

  if (!_.isEmpty(apiActions)) {
    await apiActions?.map(async (action) => {
      let proceedOnConfirmation = true;
      if (!_.isEmpty(action.confirmation)) {
        proceedOnConfirmation = executeConfirmation(
          action.confirmation,
          data,
          screen,
          navigate
        );
      }

      // Stop the processing here if the confirmation modal needs to be shown
      if (!proceedOnConfirmation) return;

      const formattedParams = getFormattedParams(action.params, data, screen);
      if (action.type === "api_function") {
        // Call the API or Action
        const resp = await store.dispatch(
          invokeReducerAction({
            apiEndPoint: action.apiEndPoint,
            apiMethod: action.apiMethod,
            payload: formattedParams,
          })
        );

        // If there is response to be reloaded into data, call the response formatter
        if (resp !== false && !_.isEmpty(action.responseFormatter)) {
          loadResponseIntoStore(action.responseFormatter, resp, screen);
        }
      } else if (action.type === "reducer_function") {
        if (!_.isEmpty(action.responseFormatter)) {
          loadResponseIntoStore(
            action.responseFormatter,
            formattedParams,
            screen
          );
        }
      }
      return true;
    });
  }

  if (!_.isEmpty(validationActions)) {
    await validationActions?.map(async (action) => {
      let isConditionTrue = false;
      const conditionStatus = [];
      const { conditionOperator = "or" } = action;
      action?.conditions?.map((condition) => {
        let conditionData = null;
        if (condition.source === "response") {
          conditionData = condition.subjectId
            ? response?.[condition.sourceId]?.[condition.subjectId]
            : condition.sourceId
            ? response?.[condition.sourceId]
            : response;
        } else if (condition.source === "reducer") {
          conditionData = condition.subjectId
            ? returnSubjectIdN(
                condition,
                0,
                store.getState().app?.[condition.sourceId]
              )
            : condition.sourceId
            ? store.getState().app?.[condition.sourceId]
            : null;
        }
        if (condition.comparison === "exists") {
          if (
            (typeof conditionData === "object" && !_.isEmpty(conditionData)) ||
            (typeof conditionData !== "object" &&
              conditionData !== undefined &&
              conditionData !== null &&
              conditionData !== "")
          ) {
            conditionStatus.push(true);
          } else {
            conditionStatus.push(false);
          }
        } else if (condition.comparison === "notExists") {
          if (
            (typeof conditionData === "object" && !_.isEmpty(conditionData)) ||
            (typeof conditionData !== "object" &&
              (conditionData !== undefined ||
                conditionData !== null ||
                conditionData !== ""))
          ) {
            conditionStatus.push(false);
          } else {
            conditionStatus.push(true);
          }
        } else if (condition.comparison === "equals") {
          if (
            !_.isUndefined(conditionData) &&
            conditionData === condition.value
          ) {
            conditionStatus.push(true);
          } else {
            conditionStatus.push(false);
          }
        } else if (condition.comparison === "notEquals") {
          if (
            !_.isUndefined(conditionData) &&
            conditionData !== condition.value
          ) {
            conditionStatus.push(true);
          } else {
            conditionStatus.push(false);
          }
        }
      });
      if (conditionOperator === "or") {
        // Check if array has atleast one true
        if (conditionStatus.includes(true)) {
          isConditionTrue = true;
        }
      } else if (conditionOperator === "and") {
        // Check that array has all true
        if (conditionStatus.includes(false)) {
          isConditionTrue = false;
        } else {
          isConditionTrue = true;
        }
      }

      if (isConditionTrue) {
        // Execute validationSuccessActions
        !_.isEmpty(action.onValidationSuccess) &&
          (await action.onValidationSuccess?.actions.map(
            async (successAction) => {
              // Execute whatever action it is\
              const formattedParams = getFormattedParams(
                successAction.params,
                data,
                screen
              );
              if (successAction.type === "api_function") {
                // Call the API or Action
                const resp = await store.dispatch(
                  invokeReducerAction({
                    apiEndPoint: successAction.apiEndPoint,
                    apiMethod: successAction.apiMethod,
                    payload: formattedParams,
                  })
                );
                if (resp !== false && !_.isEmpty(successAction?.onComplete)) {
                  return executeOnCompleteActions(
                    successAction.onComplete,
                    data,
                    screen,
                    resp,
                    navigate
                  );
                }

                // If there is response to be reloaded into data, call the response formatter
                if (
                  resp !== false &&
                  !_.isEmpty(successAction.responseFormatter)
                ) {
                  loadResponseIntoStore(
                    successAction.responseFormatter,
                    resp,
                    screen
                  );
                }
              } else if (successAction.type === "reducer_function") {
                if (!_.isEmpty(successAction.responseFormatter)) {
                  loadResponseIntoStore(
                    successAction.responseFormatter,
                    formattedParams,
                    screen
                  );
                }
                if (!_.isEmpty(successAction.onComplete)) {
                  return executeOnCompleteActions(
                    successAction.onComplete,
                    data,
                    screen,
                    null,
                    navigate
                  );
                }
              } else if (successAction.type === "redirect") {
                navigate(`/${successAction.link}`);
              }
              return true;
            }
          ));
      }
      return true;
    });
  }

  // Wait for these to complete
  if (!_.isEmpty(redirectAction)) {
    redirectAction?.map((action) => navigate(`/${action.link}`));
  }
};

const storeSubjectIdN = (respKey, ind, data) => {
  if (_.isEmpty(respKey["subjectId" + (ind == 0 ? "" : ind)])) return data;
  return {
    [respKey["subjectId" + (ind == 0 ? "" : ind)]]: storeSubjectIdN(
      respKey,
      ind + 1,
      data
    ),
  };
};

export const returnSubjectIdN = (resp, ind, data) => {
  if (
    !resp ||
    !data ||
    (Array.isArray(data) && data.length === 0) ||
    typeof +ind !== "number"
  )
    return;
  let subjectId = "subjectId" + (ind == 0 ? "" : ind);
  let res = resp?.[subjectId];
  let nestedRes = resp?.["subjectidNested"];
  let nestedRes1 = resp?.["subjectidNested1"];
  if (!_.isEmpty(nestedRes1)) {
    return data?.[res]?.[nestedRes]?.[nestedRes1];
  }
  if (!_.isEmpty(nestedRes)) {
    return data?.[res]?.[nestedRes];
  }
  if (_.isEmpty(resp?.["subjectId" + (ind + 1)])) {
    return data?.[res];
  }
  return returnSubjectIdN(resp, ind + 1, data?.[res]);
};

export const loadResponseIntoStore = (responseFormatter, data, screen) => {
  responseFormatter?.map((respKey) => {
    let updatedData = respKey?.apiResponseKey
      ? data[respKey?.apiResponseKey]
      : respKey.value !== undefined
      ? respKey.value
      : data;
    if (respKey.multiLevelResponse) {
      updatedData =
        respKey.level !== undefined
          ? respKey.level1 !== undefined
            ? !respKey.level2 !== undefined
              ? updatedData[respKey?.level]?.[respKey?.level1]?.[
                  respKey?.level2
                ]
              : updatedData?.[respKey.level]?.[respKey.level1]
            : updatedData?.[respKey.level]
          : updatedData;
    }

    if (!_.isEmpty(respKey.filters) && Array.isArray(updatedData)) {
      let filtersToBeApplied = [];
      respKey.filters?.params?.map((filterParam) => {
        let filterValue = getFormattedParams(
          [filterParam.filterInput],
          null,
          screen
        );
        filtersToBeApplied.push({
          value: filterValue?.[filterParam.filterInput.apiRequestKey],
          key: filterParam.filterKeyInData,
        });
      });

      if (
        !_.isEmpty(filtersToBeApplied) &&
        respKey.filters?.filterOperator === "or"
      ) {
        updatedData = updatedData.filter((data) => {
          let isFilterTrue = false;
          filtersToBeApplied?.every((responseFilter) => {
            if (
              (Array.isArray(responseFilter.value) &&
                responseFilter.value?.includes(data?.[responseFilter.key])) ||
              (!Array.isArray(responseFilter.value) &&
                data?.[responseFilter.key] === responseFilter.value)
            ) {
              isFilterTrue = true;
            }
            if (isFilterTrue) {
              return false;
            }
          });
          if (isFilterTrue) {
            return true;
          }
        });
      } else if (respKey.filters?.filterOperator === "and") {
        updatedData = updatedData.filter((data) => {
          let isFilterTrue = false;
          filtersToBeApplied?.every((responseFilter) => {
            isFilterTrue =
              data?.[responseFilter.key] === responseFilter.value
                ? true
                : false;
          });
          if (isFilterTrue) {
            return true;
          }
        });
      }
    }
    if (respKey?.getSelectedIds) {
      if (respKey?.getFutureWeeksOnly) {
        updatedData = data?.[respKey?.apiResponseKey]
          ?.filter((buttons) => {
            return (
              moment(moment(buttons.pcd_start_date)).isAfter(
                moment(new Date()),
                "day"
              ) && buttons?.is_selected
            );
          })
          ?.map((row) => row?.id);
      } else {
        updatedData = data?.[respKey?.apiResponseKey]
          ?.filter((buttons) => {
            return buttons?.is_selected;
          })
          ?.map((row) => row?.id);
      }
    }
    if (respKey?.getSelectedLabels) {
      updatedData = data?.[respKey?.apiResponseKey]
        ?.filter((buttons) => {
          return buttons?.is_selected;
        })
        ?.map((row) => row?.label);
    }
    if (respKey?.getSelectedIdAndLabel) {
      updatedData = data?.[respKey?.apiResponseKey]?.filter((buttons) => {
        return buttons?.is_selected;
      });

      if (respKey?.getFutureWeeksOnly) {
        updatedData = updatedData?.filter((d) =>
          moment(moment(d.pcd_start_date)).isAfter(moment(new Date()), "day")
        );
      }

      updatedData = updatedData.map((row) => {
        return { label: row?.label, value: row?.id };
      });
    }
    if (respKey.subjectidNested) {
      store.dispatch(
        updateReducerState({
          ...respKey,
          payload: {
            [respKey.subjectId]: {
              ...store.getState().app?.[respKey.dataKey]?.[respKey.subjectId],
              [respKey.subjectidNested]: updatedData,
            },
          },
        })
      );
    } else if (respKey.subjectId) {
      if (respKey?.updateExistingNestedArray) {
        store.dispatch(
          updateReducerState({
            ...respKey,
            payload: {
              [respKey.subjectId]: [
                ...(store.getState().app?.[respKey.dataKey]?.[
                  respKey.subjectId
                ] || []),
                { ...updatedData },
              ],
            },
          })
        );
      } else {
        store.dispatch(
          updateReducerState({
            ...respKey,
            payload: storeSubjectIdN(respKey, 0, updatedData),
            // payload: {
            // 	[respKey.subjectId]: updatedData,
            // },
          })
        );
      }
    } else if (respKey?.updateExistingData) {
      store.dispatch(
        updateReducerState({
          ...respKey,
          payload: [...store.getState().app?.[respKey.dataKey], ...updatedData],
        })
      );
    } else {
      store.dispatch(
        updateReducerState({
          ...respKey,
          payload: updatedData,
        })
      );
    }

    // If storing into a table, check if rows need to be selected by default
    if (respKey.destination === "table" && respKey.selectAll) {
      store.dispatch(
        setSelectedRows({
          [respKey.dataKey]: respKey?.apiResponseKey
            ? data[respKey?.apiResponseKey]
            : respKey.value !== undefined
            ? respKey.value
            : data,
        })
      );
    }
  });
};

export const checkForChangeinSalesUnits = (
  checkValue,
  data_in_store,
  comparison
) => {
  let data_in_store_cleaned_up = _.cloneDeep(data_in_store);
  delete data_in_store_cleaned_up["st_percent"];
  return comparison === "equals"
    ? _.isEqual(checkValue, data_in_store_cleaned_up)
    : !_.isEqual(checkValue, data_in_store_cleaned_up);
};

export const executeConfirmation = (confirmation, data, screen, navigate) => {
  const conditionStatus = [];
  const conditionStatusNotifications = [];

  const { conditionOperator = "or" } = confirmation;
  let isConditionTrue = false;
  let isRulesCondition = true;
  let isPcdValid = true;

  for (var i = 0; i < confirmation?.conditions?.length; i++) {
    let condition = confirmation?.conditions[i];
    let source = condition?.source;
    let sourceId = condition?.sourceId;
    let subjectId = condition?.subjectId;

    let data_in_store =
      source === "table"
        ? subjectId
          ? // ? store.getState().app?.tableData?.[sourceId]?.[subjectId]
            returnSubjectIdN(
              condition,
              0,
              store.getState().app?.tableData?.[sourceId]
            )
          : store.getState().app?.tableData?.[sourceId]
        : subjectId
        ? // ? store.getState().app?.[sourceId]?.[subjectId]
          returnSubjectIdN(condition, 0, store.getState().app?.[sourceId])
        : store.getState().app?.[sourceId];

    if (condition.comparison === "multiple") {
      isConditionTrue = executeConfirmation(condition);
      conditionStatus.push(isConditionTrue);
      !isConditionTrue &&
        condition?.message &&
        conditionStatusNotifications.push(condition.message);
    } else if (
      condition.comparison === "exists" ||
      condition.comparison === "notExists"
    ) {
      isConditionTrue =
        condition.comparison === "exists"
          ? typeof data_in_store === "object"
            ? !_.isEmpty(data_in_store) && data_in_store !== null
            : data_in_store !== undefined &&
              data_in_store !== null &&
              data_in_store !== ""
          : typeof data_in_store === "object"
          ? _.isEmpty(data_in_store)
          : data_in_store === undefined || data_in_store === null;

      conditionStatus.push(isConditionTrue);
      !isConditionTrue &&
        condition?.message &&
        conditionStatusNotifications.push(condition.message);
    } else if (
      condition.comparison === "equals" ||
      condition.comparison === "notEquals" ||
      condition.comparison === "greaterthan" ||
      condition.comparison === "greaterthanEqual" ||
      condition.comparison === "lessthan" ||
      condition.comparison === "lessthanEqual"
    ) {
      let checkValue = condition.checkValue;
      if (typeof checkValue === "object" && checkValue?.source) {
        checkValue =
          checkValue?.source === "table"
            ? checkValue?.subjectId
              ? store.getState().app?.tableData?.[checkValue?.sourceId]?.[
                  checkValue?.subjectId
                ]
              : store.getState().app?.tableData?.[checkValue?.sourceId]
            : checkValue?.source === "reducer"
            ? checkValue?.subjectId
              ? store.getState().app?.[checkValue?.sourceId]?.[
                  checkValue?.subjectId
                ]
              : store.getState().app?.[checkValue?.sourceId]
            : undefined;
        // Triple equals not used since if a change made in text box for numbers gives a number as string so equals doesn't work in that case.
        if (
          condition.handleStPercent &&
          condition.subjectId === "sales_units"
        ) {
          isConditionTrue =
            condition.comparison === "equals"
              ? checkForChangeinSalesUnits(checkValue, data_in_store, true)
              : checkForChangeinSalesUnits(checkValue, data_in_store, true);
        } else {
          isConditionTrue =
            condition.comparison === "equals"
              ? condition.data_type === "date"
                ? moment(checkValue).isSame(moment(data_in_store), "day")
                : _.isEqual(checkValue, data_in_store)
              : condition.data_type === "date"
              ? !moment(checkValue).isSame(moment(data_in_store), "day")
              : !_.isEqual(checkValue, data_in_store);
        }
        isConditionTrue =
          condition.comparison === "greaterthan"
            ? condition.data_type === "date"
              ? checkValue
                ? moment(data_in_store).isAfter(moment(checkValue), "day")
                : moment(data_in_store).isAfter(moment(new Date()), "day")
              : _.gt(data_in_store, checkValue)
            : condition.data_type === "date"
            ? checkValue
              ? moment(data_in_store).isSameOrAfter(moment(checkValue), "day")
              : moment(data_in_store).isSameOrAfter(moment(new Date()), "day")
            : _.gte(data_in_store, checkValue);

        isConditionTrue =
          condition.comparison === "lessthan"
            ? condition.data_type === "date"
              ? checkValue
                ? moment(data_in_store).isBefore(moment(checkValue), "day")
                : moment(data_in_store).isBefore(moment(new Date()), "day")
              : _.lt(data_in_store, checkValue)
            : condition.data_type === "date"
            ? checkValue
              ? moment(data_in_store).isSameOrBefore(moment(checkValue), "day")
              : moment(data_in_store).isSameOrAfter(moment(new Date()), "day")
            : _.lte(data_in_store, checkValue);
      } else {
        isConditionTrue =
          condition.comparison === "equals"
            ? _.isEqual(checkValue, data_in_store)
            : !_.isEqual(checkValue, data_in_store);

        isConditionTrue =
          condition.comparison === "greaterthan"
            ? condition.data_type === "date"
              ? checkValue
                ? moment(data_in_store).isAfter(moment(checkValue), "day")
                : moment(data_in_store).isAfter(moment(new Date()), "day")
              : _.gt(data_in_store, checkValue)
            : condition.data_type === "date"
            ? checkValue
              ? moment(data_in_store).isSameOrAfter(moment(checkValue), "day")
              : moment(data_in_store).isSameOrAfter(moment(new Date()), "day")
            : _.gte(data_in_store, checkValue);

        isConditionTrue =
          condition.comparison === "lessthan"
            ? condition.data_type === "date"
              ? checkValue
                ? moment(data_in_store).isBefore(moment(checkValue), "day")
                : moment(data_in_store).isBefore(moment(new Date()), "day")
              : _.lt(data_in_store, checkValue)
            : condition.data_type === "date"
            ? checkValue
              ? moment(data_in_store).isSameOrBefore(moment(checkValue), "day")
              : moment(data_in_store).isSameOrBefore(moment(new Date()), "day")
            : _.lte(data_in_store, checkValue);
      }
      conditionStatus.push(isConditionTrue);
      !isConditionTrue &&
        condition?.message &&
        conditionStatusNotifications.push(condition.message);
    } else if (condition.comparison === "rulesValidation") {
      isRulesCondition = RulesValidation();
      if (!isRulesCondition) {
        isConditionTrue = false;
      }
      loadResponseIntoStore(
        [
          {
            destination: "reducer",
            dataKey: "rulesValidationFlag",
            dataType: "basic",
            overwrite: true,
          },
        ],
        false
      );
    } else if (condition.comparison === "pcdValidation") {
      isPcdValid = pcdValidation();
      conditionStatus.push(isPcdValid);
    } else if (condition.comparison === "checkForDateChange") {
      let hasPcdChanged = checkForDateChange();
      conditionStatus.push(hasPcdChanged);
    }
    if (conditionOperator === "and" && conditionStatus.includes(false)) break;
  }

  if (conditionOperator === "or") {
    // Check if array has atleast one true
    if (conditionStatus.includes(true) && isRulesCondition) {
      isConditionTrue = true;
    }
  } else if (conditionOperator === "and") {
    // Check that array has all true
    if (conditionStatus.includes(false) || !isRulesCondition) {
      isConditionTrue = false;
    } else {
      isConditionTrue = true;
    }
  }

  if (isConditionTrue) {
    // Show confirmation popup
    if (confirmation.type === "modal") {
      store.dispatch(
        updateReducerState({
          payload: true,
          dataKey: confirmation.modalDataKey,
          overwrite: true,
        })
      );

      // Also store the data in reducer temporarily for later use
      store.dispatch(
        updateReducerState({
          payload: data,
          dataKey: confirmation.temporaryDataStorageKey,
          overwrite: true,
        })
      );
    }
  }
  if (!isConditionTrue && confirmation.showErrorMessage)
    conditionStatusNotifications?.forEach(async (notification) => {
      await store.dispatch(toastError(notification));
    });

  if (!isConditionTrue && confirmation.onConfirmationFailure) {
    confirmation.onConfirmationFailure?.actions.map(async (failureAction) => {
      // Execute whatever action it is\
      const formattedParams = getFormattedParams(
        failureAction.params,
        data,
        screen
      );
      if (failureAction.type === "api_function") {
        let proceedOnConfirmation = true;
        if (!_.isEmpty(failureAction.confirmation)) {
          proceedOnConfirmation = executeConfirmation(
            failureAction.confirmation,
            data,
            screen,
            navigate
          );
        }

        // Stop the processing here if the confirmation modal needs to be shown
        if (!proceedOnConfirmation) return;
        // Call the API or Action
        const resp = await store.dispatch(
          invokeReducerAction({
            apiEndPoint: failureAction.apiEndPoint,
            apiMethod: failureAction.apiMethod,
            payload: formattedParams,
          })
        );
        if (resp !== false && !_.isEmpty(failureAction?.onComplete)) {
          return executeOnCompleteActions(
            failureAction.onComplete,
            data,
            screen,
            resp,
            navigate
          );
        }

        // If there is response to be reloaded into data, call the response formatter
        if (resp !== false && !_.isEmpty(failureAction.responseFormatter)) {
          loadResponseIntoStore(failureAction.responseFormatter, resp, screen);
        }
      } else if (failureAction.type === "reducer_function") {
        if (!_.isEmpty(failureAction.responseFormatter)) {
          failureAction.responseFormatter?.map((respKey) => {
            let updatedData = respKey?.apiResponseKey
              ? data[respKey?.apiResponseKey]
              : respKey.value !== undefined
              ? respKey.value
              : data;
            store.dispatch(
              updateReducerState({
                ...respKey,
                payload: updatedData,
              })
            );
          });
        }
      } else if (failureAction.type === "redirect") {
        navigate(`/${failureAction.link}`);
      }
      // return true;
    });
  }
  return confirmation.type === "modal" ? !isConditionTrue : isConditionTrue;
};
