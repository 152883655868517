import React, { PureComponent } from "react";
import { Provider } from "react-redux";
import store from "./store/index";
import AppRoutes from "./containers/common/Routes";
import "./App.scss";
import SnackQueue from "./containers/common/SnackQueue";
import MetaDataComponent from "./hoc/metadata";
import SnackBarProvider from "./containers/common/SnackBarProvider";

class App extends PureComponent {
  componentDidMount() {
    const { REACT_APP_NODE_ENV, REACT_APP_WHATFIX_URL } = process.env;
    if (REACT_APP_NODE_ENV === "test" || REACT_APP_NODE_ENV === "uat") {
      const script = document.createElement("script");
      script.src = REACT_APP_WHATFIX_URL;
      script.async = true;
      script.type = "text/javascript";
      script.language = "javascript";
      document.head.appendChild(script);
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("Handling chunk load error...");
    window.addEventListener("error", (e) => {
      // prompt user to confirm refresh
      console.log("chunk load error", e.message);
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload();
      }
    });
  }

  render() {
    return (
      <Provider store={store}>
        <SnackBarProvider>
          <SnackQueue />
          <MetaDataComponent>
            <AppRoutes />
          </MetaDataComponent>
        </SnackBarProvider>
      </Provider>
    );
  }
}

export default App;
