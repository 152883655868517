import moment from "moment";

export const DECISION_DASHBOARD = {
	screen_composition: {
		components: [
			{
				type: "filter",
				staticProps: {
					showSaveFilter: false,
					showPageHeader: true,
					showSaveFilter: true,
					pageHeader: "Markdown Dashboard",
					filterHeader: "Dashboard Filters",
					showFilterButtons: true,
					apiEndpoint: "getProductConfig",
					showFilterChips: true,
					filter_config: [
						{
							type: "product_h1",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							filter_group_label: "Select Hierarchy",
							hideSearch: false,
						},
						{
							type: "product_h2",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "product_h3",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "product_h4",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "brand",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "store_h1",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							filter_group_label: "Select Stores",
							hideSearch: false,
						},
						{
							type: "store_h2",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "store_h3",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "store_h4",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "store_h5",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "dateRange",
							is_mandatory: true,
							filter_endpoint: "",
							filter_type: "dateRange",
							filter_group: 3,
							filter_group_label: "Select Strategy and Date",
							// min_date: moment().subtract(12, "weeks"),
							// max_date: moment().add(10, "weeks"),
							range_limitation: "limited_period",
							max_selection_period: {
								num: 12,
								type: "weeks",
							},
							use_default: false, // will take from constants config if set to true
							start_date: moment().subtract(1, "weeks"),
							end_date: moment().add(1, "weeks"),
							date_picker_position: "down",
						},
						{
							type: "strategy_id",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "model",
							filter_model: 4003,
							filter_type: "strategy_id",
							filter_group: 3,
							filter_group_label: "Select Strategy and Date",
							hideSearch: false,
						},
					],
				},
				functionProps: [
					{
						functionName: "onFilter",
						actions: [
							{
								type: "reducer_function",
								params: [
									{
										source: "fixed",
										apiRequestKey: "metric_level_default",
										value: "pcd",
										dataType: "basic",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"decision_dashboard_metric_level",
										apiResponseKey: "metric_level_default",
										dataType: "basic",
									},
								],
							},
							{
								type: "reducer_function",
								params: [
									{
										source: "fixed",
										apiRequestKey:
											"metric_timeline_default",
										value: "0 week",
										dataType: "basic",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"decision_dashboard_metric_timeline",
										apiResponseKey:
											"metric_timeline_default",
										dataType: "basic",
									},
								],
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 4000,
										dataType: "basic",
									},
									{
										source: "filters",
										dataType: "object",
										apiRequestKey: "parameters",
									},
									{
										source: "fixed",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "timeline",
										value: "0 week",
										dataType: "basic",
									},
									{
										source: "fixed",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "view_by",
										value: "pcd",
										dataType: "basic",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "selectedTimeline",
										apiResponseKey: "timeline",
										dataType: "object",
										overwrite: true,
									},
									{
										destination: "reducer",
										dataKey: "decisionDashboardTableData",
										apiResponseKey: "table_data",
										dataType: "array",
										overwrite: true,
									},
								],
								onComplete: {
									actions: [
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
													sourceId: "table_data",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"decision-dashboard-strategy-table",
																dataType:
																	"object",
																value: {
																	show: true,
																},
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"decision-dashboard-download-button",
																dataType:
																	"object",
																value: {
																	show: true,
																},
																overwrite: false,
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"decision-dashboard-content-emptystate",
																dataType:
																	"object",
																value: {
																	show: false,
																},
															},
														],
													},
												],
											},
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filterModalOpen",
													value: false,
												},
											],
										},
									],
								},
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 4004,
										dataType: "basic",
									},
									{
										source: "filters",
										dataType: "object",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"decisionDashboardComparisonCardData",
										dataType: "array",
									},
								],
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 4005,
										dataType: "basic",
									},
									{
										source: "filters",
										dataType: "object",
										apiRequestKey: "parameters",
									},
									{
										source: "fixed",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "view_by",
										value: "weekly",
										dataType: "basic",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "decisionDashboardChartData",
										dataType: "object",
									},
								],
								onComplete: {
									actions: [
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"decision-dashboard-chart-content",
																dataType:
																	"object",
																value: {
																	show: true,
																},
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"decision-dashboard-content-emptystate",
																dataType:
																	"object",
																value: {
																	show: false,
																},
															},
														],
													},
												],
											},
										},
									],
								},
							},
						],
					},
				],
				dataProps: {
					filterModalOpen: {
						type: "derived",
						dataKey: "filterModalOpen",
					},
				},
			},
			{
				type: "div",
				id: "decision-dashboard-content-emptystate",
				staticProps: {
					className: "common-content-container flex-center",
					display: {
						type: "controlled",
						default: {
							show: true,
						},
					},
				},
				componentProps: {
					content: [
						{
							type: "emptyState",
							staticProps: {
								variant: "noPlan",
								layout: "horizontal",
								heading:
									"This is where you can see the strategy of the products at different levels",
								info: "Select the filters to view the strategies",
								primaryButtonProps: {
									label: "Select Filters",
								},
							},
							functionProps: [
								{
									functionName: "onClick",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filterModalOpen",
													value: true,
													dataType: "basic",
												},
											],
										},
									],
								},
							],
						},
					],
				},
			},

			{
				type: "div",
				id: "decision-dashboard-content",
				staticProps: {
					className: "common-content-container",
				},
				componentProps: {
					content: [
						{
							type: "div",
							id: "decision-dashboard-chart-content",
							staticProps: {
								className: "box-container",
								display: {
									type: "controlled",
									default: {
										show: false,
									},
								},
							},
							componentProps: {
								content: [
									{
										type: "headerTextFilters",
									},
									{
										type: "div",
										staticProps: {
											customStyle: {
												display: "flex",
											},
										},
										componentProps: {
											content: [
												{
													type: "div",
													staticProps: {
														customStyle: {
															width: "72%",
															paddingRight:
																"15px",
															position:
																"relative",
														},
													},
													componentProps: {
														content: [
															{
																type: "div",
																staticProps: {
																	className:
																		"flex-container",
																	customStyle:
																		{
																			position:
																				"absolute",
																			top: "65px",
																			right: "30px",
																			zIndex: 1,
																		},
																},
																componentProps:
																	{
																		content:
																			[
																				{
																					isDefaultExport: false,
																					type: "text",
																					staticProps:
																						{
																							className:
																								"common-page-title",
																							variant:
																								"h4",
																							content:
																								"View By",
																							customStyle:
																								{
																									color: "#758490",
																									fontSize:
																										"12px",
																									fontWeight:
																										"100",
																									padding:
																										"0 10px",
																								},
																						},
																				},
																				{
																					type: "select",
																					staticProps:
																						{
																							customStyle:
																								{
																									width: "200px",
																								},
																							isMultipleSelection: false,
																							hideClearSelection: true,
																							hideSearch: true,
																							updateSelectedOnEachSelection: true,
																							options:
																								[
																									{
																										value: "quarterly",
																										label: "Quarterly",
																									},
																									{
																										value: "monthly",
																										label: "Monthly",
																									},
																									{
																										value: "weekly",
																										label: "Weekly",
																									},
																								],
																							selectedOptions:
																								[
																									{
																										value: "weekly",
																										label: "Weekly",
																									},
																								],
																						},
																					functionProps:
																						[
																							{
																								functionName:
																									"onSelect",
																								actions:
																									[
																										{
																											type: "reducer_function",
																											params: [
																												{
																													source: "self",
																													apiRequestKey:
																														"selectedData",
																													dataType:
																														"basic",
																												},
																											],
																											responseFormatter:
																												[
																													{
																														destination:
																															"reducer",
																														dataKey:
																															"decision_dashboard_chart_metric_level",
																														apiResponseKey:
																															"selectedData",
																														dataType:
																															"basic",
																													},
																												],
																										},
																										{
																											type: "api_function",
																											apiEndPoint:
																												"model",
																											apiMethod:
																												"post",
																											params: [
																												{
																													source: "fixed",
																													apiRequestKey:
																														"id",
																													value: 4005,
																													dataType:
																														"basic",
																												},
																												{
																													source: "filters",
																													dataType:
																														"object",
																													apiRequestKey:
																														"parameters",
																												},
																												{
																													source: "reducer",
																													dataType:
																														"basic",
																													sourceId:
																														"decision_dashboard_chart_metric_level",
																													apiRequestKey:
																														"parameters",
																													apiRequestKeyNested:
																														"view_by",
																												},
																											],
																											responseFormatter:
																												[
																													{
																														destination:
																															"reducer",
																														dataKey:
																															"decisionDashboardChartData",
																														dataType:
																															"object",
																														overwrite: true,
																													},
																												],
																										},
																									],
																							},
																						],
																				},
																			],
																	},
															},
															{
																type: "tab",
																staticProps: {
																	width: "80%",
																	tabId: "decision_dashboard_chart_metric_type",
																	tabs: [
																		{
																			id: "gm_dollar",
																			label: "GM $",
																		},
																		{
																			id: "sales_dollar",
																			label: "Sales $",
																		},
																		{
																			id: "sales_unit",
																			label: "Sales Unit",
																		},
																		{
																			id: "gm_percent",
																			label: "GM %",
																		},
																		{
																			id: "aum",
																			label: "$AUM",
																		},
																		{
																			id: "aur",
																			label: "$AUR",
																		},
																		{
																			id: "inventory",
																			label: "Inventory",
																		},
																		{
																			id: "markdown_dollar",
																			label: "Markdown $",
																		},
																	],
																},
																componentProps:
																	{
																		content:
																			[
																				{
																					type: "chart",
																					staticProps:
																						{
																							retainData: true,
																							chartOptions:
																								{
																									chartType:
																										"line",
																									height: "450px",
																									title: "",
																									spacing:
																										[
																											50,
																											15,
																											10,
																											2,
																										],
																									xaxis: {
																										metric: "timeline",
																										gridLineColor:
																											"#e9e9e9",
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																										plotLines:
																											[
																												{
																													color: "#999999",
																													dashStyle:
																														"Solid",
																												},
																											],
																										series: [
																											{
																												id: "bl_override",
																												name: "BigLots Override",
																												color: "#9CB7ED",
																												dashStyle:
																													"Solid",
																											},
																											{
																												id: "ia_recommended",
																												name: "IA Recommended",
																												color: "#C6D192",
																												dashStyle:
																													"Solid",
																											},
																										],
																									},
																									yaxis: {
																										title: "GM $",
																										metric: "gm_dollar",
																										tickAmount: 5,
																										gridLineColor:
																											"#e9e9e9",
																										style: {
																											color: "#44677b",
																											fontSize:
																												"0.7rem",
																											fontWeight:
																												"600",
																										},
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																									},
																									tooltip:
																										{
																											enabled: true,
																										},
																									legend: {
																										enabled: true,
																										align: "right",
																									},
																								},
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardChartData",
																								subjectId:
																									"gm_dollar",
																							},
																						},
																					tabContentId:
																						"gm_dollar",
																				},
																				{
																					type: "chart",
																					staticProps:
																						{
																							retainData: true,
																							chartOptions:
																								{
																									chartType:
																										"line",
																									height: "450px",
																									title: "",
																									spacing:
																										[
																											50,
																											15,
																											10,
																											2,
																										],
																									xaxis: {
																										metric: "timeline",
																										gridLineColor:
																											"#e9e9e9",
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																										plotLines:
																											[
																												{
																													color: "#999999",
																													dashStyle:
																														"Solid",
																												},
																											],
																										series: [
																											{
																												id: "bl_override",
																												name: "BigLots Override",
																												color: "#9CB7ED",
																												dashStyle:
																													"Solid",
																											},
																											{
																												id: "ia_recommended",
																												name: "IA Recommended",
																												color: "#C6D192",
																												dashStyle:
																													"Solid",
																											},
																										],
																									},
																									yaxis: {
																										title: "Revenue",
																										metric: "sales_dollar",
																										tickAmount: 5,
																										gridLineColor:
																											"#e9e9e9",
																										style: {
																											color: "#44677b",
																											fontSize:
																												"0.7rem",
																											fontWeight:
																												"600",
																										},
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																									},
																									tooltip:
																										{
																											enabled: true,
																										},
																									legend: {
																										enabled: true,
																										align: "right",
																									},
																								},
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardChartData",
																								subjectId:
																									"sales_dollar",
																							},
																						},
																					tabContentId:
																						"sales_dollar",
																				},
																				{
																					type: "chart",
																					staticProps:
																						{
																							chartType:
																								"linechart",
																							retainData: true,
																							chartOptions:
																								{
																									chartType:
																										"line",
																									height: "450px",
																									title: "",
																									spacing:
																										[
																											50,
																											15,
																											10,
																											2,
																										],
																									backgroundColor:
																										"#F5F8FA",
																									plotBackgroundColor:
																										"#ffffff",
																									xaxis: {
																										metric: "timeline",
																										gridLineColor:
																											"#e9e9e9",
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																										plotLines:
																											[
																												{
																													color: "#999999",
																													dashStyle:
																														"Solid",
																												},
																											],
																										series: [
																											{
																												id: "bl_override",
																												name: "BigLots Override",
																												color: "#9CB7ED",
																												dashStyle:
																													"Solid",
																											},
																											{
																												id: "ia_recommended",
																												name: "IA Recommended",
																												color: "#C6D192",
																												dashStyle:
																													"Solid",
																											},
																										],
																									},
																									yaxis: {
																										title: "Sales Units",
																										metric: "sales_unit",
																										tickAmount: 5,
																										gridLineColor:
																											"#e9e9e9",
																										style: {
																											color: "#44677b",
																											fontSize:
																												"0.7rem",
																											fontWeight:
																												"600",
																										},
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																									},
																									tooltip:
																										{
																											enabled: true,
																										},
																									legend: {
																										enabled: true,
																										align: "right",
																									},
																								},
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardChartData",
																								subjectId:
																									"sales_unit",
																							},
																						},
																					tabContentId:
																						"sales_unit",
																				},
																				{
																					type: "chart",
																					staticProps:
																						{
																							chartType:
																								"linechart",
																							retainData: true,
																							chartOptions:
																								{
																									chartType:
																										"line",
																									height: "450px",
																									title: "",
																									spacing:
																										[
																											50,
																											15,
																											10,
																											2,
																										],
																									backgroundColor:
																										"#F5F8FA",
																									plotBackgroundColor:
																										"#ffffff",
																									xaxis: {
																										metric: "timeline",
																										gridLineColor:
																											"#e9e9e9",
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																										plotLines:
																											[
																												{
																													color: "#999999",
																													dashStyle:
																														"Solid",
																												},
																											],
																										series: [
																											{
																												id: "bl_override",
																												name: "BigLots Override",
																												color: "#9CB7ED",
																												dashStyle:
																													"Solid",
																											},
																											{
																												id: "ia_recommended",
																												name: "IA Recommended",
																												color: "#C6D192",
																												dashStyle:
																													"Solid",
																											},
																										],
																									},
																									yaxis: {
																										title: "GM %",
																										metric: "gm_percent",
																										tickAmount: 5,
																										gridLineColor:
																											"#e9e9e9",
																										style: {
																											color: "#44677b",
																											fontSize:
																												"0.7rem",
																											fontWeight:
																												"600",
																										},
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																									},
																									tooltip:
																										{
																											enabled: true,
																										},
																									legend: {
																										enabled: true,
																										align: "right",
																									},
																								},
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardChartData",
																								subjectId:
																									"gm_percent",
																							},
																						},
																					tabContentId:
																						"gm_percent",
																				},
																				{
																					type: "chart",
																					staticProps:
																						{
																							chartType:
																								"linechart",
																							retainData: true,
																							chartOptions:
																								{
																									chartType:
																										"line",
																									height: "450px",
																									title: "",
																									spacing:
																										[
																											50,
																											15,
																											10,
																											2,
																										],
																									backgroundColor:
																										"#F5F8FA",
																									plotBackgroundColor:
																										"#ffffff",
																									xaxis: {
																										metric: "timeline",
																										gridLineColor:
																											"#e9e9e9",
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																										plotLines:
																											[
																												{
																													color: "#999999",
																													dashStyle:
																														"Solid",
																												},
																											],
																										series: [
																											{
																												id: "bl_override",
																												name: "BigLots Override",
																												color: "#9CB7ED",
																												dashStyle:
																													"Solid",
																											},
																											{
																												id: "ia_recommended",
																												name: "IA Recommended",
																												color: "#C6D192",
																												dashStyle:
																													"Solid",
																											},
																										],
																									},
																									yaxis: {
																										title: "AUM",
																										metric: "aum",
																										tickAmount: 5,
																										gridLineColor:
																											"#e9e9e9",
																										style: {
																											color: "#44677b",
																											fontSize:
																												"0.7rem",
																											fontWeight:
																												"600",
																										},
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																									},
																									tooltip:
																										{
																											enabled: true,
																										},
																									legend: {
																										enabled: true,
																										align: "right",
																									},
																								},
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardChartData",
																								subjectId:
																									"aum",
																							},
																						},
																					tabContentId:
																						"aum",
																				},
																				{
																					type: "chart",
																					staticProps:
																						{
																							chartType:
																								"linechart",
																							retainData: true,
																							chartOptions:
																								{
																									chartType:
																										"line",
																									height: "450px",
																									title: "",
																									spacing:
																										[
																											50,
																											15,
																											10,
																											2,
																										],
																									backgroundColor:
																										"#F5F8FA",
																									plotBackgroundColor:
																										"#ffffff",
																									xaxis: {
																										metric: "timeline",
																										gridLineColor:
																											"#e9e9e9",
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																										plotLines:
																											[
																												{
																													color: "#999999",
																													dashStyle:
																														"Solid",
																												},
																											],
																										series: [
																											{
																												id: "bl_override",
																												name: "BigLots Override",
																												color: "#9CB7ED",
																												dashStyle:
																													"Solid",
																											},
																											{
																												id: "ia_recommended",
																												name: "IA Recommended",
																												color: "#C6D192",
																												dashStyle:
																													"Solid",
																											},
																										],
																									},
																									yaxis: {
																										title: "AUR",
																										metric: "aur",
																										tickAmount: 5,
																										gridLineColor:
																											"#e9e9e9",
																										style: {
																											color: "#44677b",
																											fontSize:
																												"0.7rem",
																											fontWeight:
																												"600",
																										},
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																									},
																									tooltip:
																										{
																											enabled: true,
																										},
																									legend: {
																										enabled: true,
																										align: "right",
																									},
																								},
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardChartData",
																								subjectId:
																									"aur",
																							},
																						},
																					tabContentId:
																						"aur",
																				},

																				{
																					type: "chart",
																					staticProps:
																						{
																							chartType:
																								"linechart",
																							retainData: true,
																							chartOptions:
																								{
																									chartType:
																										"line",
																									height: "450px",
																									title: "",
																									spacing:
																										[
																											50,
																											15,
																											10,
																											2,
																										],
																									backgroundColor:
																										"#F5F8FA",
																									plotBackgroundColor:
																										"#ffffff",
																									xaxis: {
																										metric: "timeline",
																										gridLineColor:
																											"#e9e9e9",
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																										plotLines:
																											[
																												{
																													color: "#999999",
																													dashStyle:
																														"Solid",
																												},
																											],
																										series: [
																											{
																												id: "bl_override",
																												name: "BigLots Override",
																												color: "#9CB7ED",
																												dashStyle:
																													"Solid",
																											},
																											{
																												id: "ia_recommended",
																												name: "IA Recommended",
																												color: "#C6D192",
																												dashStyle:
																													"Solid",
																											},
																										],
																									},
																									yaxis: {
																										title: "Inventory",
																										metric: "inventory",
																										tickAmount: 5,
																										gridLineColor:
																											"#e9e9e9",
																										style: {
																											color: "#44677b",
																											fontSize:
																												"0.7rem",
																											fontWeight:
																												"600",
																										},
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																									},
																									tooltip:
																										{
																											enabled: true,
																										},
																									legend: {
																										enabled: true,
																										align: "right",
																									},
																								},
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardChartData",
																								subjectId:
																									"inventory",
																							},
																						},
																					tabContentId:
																						"inventory",
																				},

																				{
																					type: "chart",
																					staticProps:
																						{
																							chartType:
																								"linechart",
																							retainData: true,
																							chartOptions:
																								{
																									chartType:
																										"line",
																									height: "450px",
																									title: "",
																									spacing:
																										[
																											50,
																											15,
																											10,
																											2,
																										],
																									backgroundColor:
																										"#F5F8FA",
																									plotBackgroundColor:
																										"#ffffff",
																									xaxis: {
																										metric: "timeline",
																										gridLineColor:
																											"#e9e9e9",
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																										plotLines:
																											[
																												{
																													color: "#999999",
																													dashStyle:
																														"Solid",
																												},
																											],
																										series: [
																											{
																												id: "bl_override",
																												name: "BigLots Override",
																												color: "#9CB7ED",
																												dashStyle:
																													"Solid",
																											},
																											{
																												id: "ia_recommended",
																												name: "IA Recommended",
																												color: "#C6D192",
																												dashStyle:
																													"Solid",
																											},
																										],
																									},
																									yaxis: {
																										title: "Markdown $",
																										metric: "markdown_dollar",
																										tickAmount: 5,
																										gridLineColor:
																											"#e9e9e9",
																										style: {
																											color: "#44677b",
																											fontSize:
																												"0.7rem",
																											fontWeight:
																												"600",
																										},
																										labels: {
																											color: "#44677b",
																											fontSize:
																												"0.6rem",
																										},
																									},
																									tooltip:
																										{
																											enabled: true,
																										},
																									legend: {
																										enabled: true,
																										align: "right",
																									},
																								},
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardChartData",
																								subjectId:
																									"markdown_dollar",
																							},
																						},
																					tabContentId:
																						"markdown_dollar",
																				},
																			],
																	},
																wrapperClass:
																	"",
															},
														],
													},
												},
												{
													type: "div",
													staticProps: {
														className:
															"box-container",
														customStyle: {
															width: "425px",
															height: "468px",
															ouline: "10px solid lime",
															display: "flex",
															flexDirection:
																"column",
															marginBottom: 0,
														},
													},

													componentProps: {
														content: [
															{
																isDefaultExport: false,
																type: "text",
																staticProps: {
																	className:
																		"common-page-title",
																	customStyle:
																		{
																			marginBottom:
																				"10px",
																		},
																	variant:
																		"h4",
																	content:
																		"BigLots Override v/s IA Recommended",
																},
															},
															{
																type: "div",
																staticProps: {
																	customStyle:
																		{
																			overflowY:
																				"scroll",
																		},
																},
																componentProps:
																	{
																		content:
																			[
																				{
																					type: "comparisonCard",
																					staticProps:
																						{
																							metric: "gm_dollar",
																							chipLabel:
																								"GM $",
																							comparisonCriteria:
																								[
																									{
																										id: "bl_override",
																										label: "Planned",
																									},
																									{
																										id: "ia_recommended",
																										label: "IA Recommended",
																									},
																								],
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardComparisonCardData",
																								subjectId:
																									"gm_dollar",
																							},
																						},
																				},
																				{
																					type: "comparisonCard",
																					staticProps:
																						{
																							metric: "sales_dollar",
																							chipLabel:
																								"Sales",
																							comparisonCriteria:
																								[
																									{
																										id: "bl_override",
																										label: "Planned",
																									},
																									{
																										id: "ia_recommended",
																										label: "IA Recommended",
																									},
																								],
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardComparisonCardData",
																								subjectId:
																									"sales_dollar",
																							},
																						},
																				},
																				{
																					type: "comparisonCard",
																					staticProps:
																						{
																							metric: "sales_unit",
																							chipLabel:
																								"Units",
																							comparisonCriteria:
																								[
																									{
																										id: "bl_override",
																										label: "Planned",
																									},
																									{
																										id: "ia_recommended",
																										label: "IA Recommended",
																									},
																								],
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardComparisonCardData",
																								subjectId:
																									"sales_unit",
																							},
																						},
																				},
																				{
																					type: "comparisonCard",
																					staticProps:
																						{
																							metric: "gm_percent",
																							chipLabel:
																								"GM %",
																							comparisonCriteria:
																								[
																									{
																										id: "bl_override",
																										label: "Planned",
																									},
																									{
																										id: "ia_recommended",
																										label: "IA Recommended",
																									},
																								],
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardComparisonCardData",
																								subjectId:
																									"gm_percent",
																							},
																						},
																				},
																				{
																					type: "comparisonCard",
																					staticProps:
																						{
																							metric: "aum",
																							chipLabel:
																								"AUM",
																							comparisonCriteria:
																								[
																									{
																										id: "bl_override",
																										label: "Planned",
																									},
																									{
																										id: "ia_recommended",
																										label: "IA Recommended",
																									},
																								],
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardComparisonCardData",
																								subjectId:
																									"aum",
																							},
																						},
																				},
																				{
																					type: "comparisonCard",
																					staticProps:
																						{
																							metric: "aur",
																							chipLabel:
																								"AUR",
																							comparisonCriteria:
																								[
																									{
																										id: "bl_override",
																										label: "Planned",
																									},
																									{
																										id: "ia_recommended",
																										label: "IA Recommended",
																									},
																								],
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardComparisonCardData",
																								subjectId:
																									"aur",
																							},
																						},
																				},

																				{
																					type: "comparisonCard",
																					staticProps:
																						{
																							metric: "inventory",
																							chipLabel:
																								"Inventory",
																							comparisonCriteria:
																								[
																									{
																										id: "bl_override",
																										label: "Planned",
																									},
																									{
																										id: "ia_recommended",
																										label: "IA Recommended",
																									},
																								],
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardComparisonCardData",
																								subjectId:
																									"inventory",
																							},
																						},
																				},
																				{
																					type: "comparisonCard",
																					staticProps:
																						{
																							metric: "markdown_dollar",
																							chipLabel:
																								"Markdown $",
																							comparisonCriteria:
																								[
																									{
																										id: "bl_override",
																										label: "Planned",
																									},
																									{
																										id: "ia_recommended",
																										label: "IA Recommended",
																									},
																								],
																						},
																					dataProps:
																						{
																							data: {
																								type: "derived",
																								dataKey:
																									"decisionDashboardComparisonCardData",
																								subjectId:
																									"markdown_dollar",
																							},
																						},
																				},
																			],
																	},
															},
														],
													},
												},
											],
										},
									},
								],
							},
						},
						{
							type: "div",
							id: "decision-dashboard-strategy-table",
							staticProps: {
								className: "box-container",
								customStyle: {
									marginTop: "15px",
								},
								display: {
									type: "controlled",
									default: {
										show: false,
									},
								},
							},
							componentProps: {
								content: [
									{
										isDefaultExport: false,
										type: "text",
										staticProps: {
											className: "common-page-title",
											variant: "h2",
											content: "Strategies",
										},
									},
									{
										type: "aggrid",
										staticProps: {
											height: "400px",
											tableId:
												"decision_dashboard_strategies",
											rowSelection: "multiple",
											uniqueKey: "unique_key",
											isRowSelectable: (rowData) => {
												return !rowData.data
													.is_sub_total;
											},
											getRowStyle: (params) => {
												return params?.data
													?.is_sub_total
													? {
															background:
																"#F7F7F7",
															borderBottom:
																"2px solid #758490",
													  }
													: {};
											},
											tableConfig: {
												column_headers: [
													{
														type: "static_column",
														mapping: "data",
														header: "",
														key: "",
														action: null,
														hidden: false,
														aggregate: null,
														sorting: false,
														filter: false,
														pinned: true,
														lockPinned: true,
														checkbox_selection: true,
														row_group: false,
														suppressMenu: true,
														maxWidth: 50,
														cellStyle: {
															background:
																"#f7f7f7",
														},
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Strategy",
														key: "strategy_name",
														action: "",
														hidden: false,
														aggregate: null,
														sorting: true,
														filter: true,
														pinned: false,
														checkbox_selection: false,
														row_group: false,
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Date Range",
														key: "start_date",
														data_type: "date",
														action: null,
														hidden: false,
														aggregate: null,
														sorting: false,
														filter: false,
														pinned: false,
														row_group: false,
														// suppressMenu: true,
														valueFormatter:
															"dateRangeFormatter",
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Product Count",
														key: "products_count",
														comparator: "number",
														action: null,
														hidden: false,
														aggregate: null,
														sorting: true,
														filter: true,
														pinned: false,
														row_group: false,
														cellRenderer:
															"clickActionCellRenderer",
														cellRendererParams: {
															actions: [
																{
																	type: "reducer_function",
																	params: [
																		{
																			source: "self",
																			apiRequestKey:
																				"selectedStrategy",
																			dataType:
																				"basic",
																		},
																	],
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"selectedStrategy",
																				apiResponseKey:
																					"selectedStrategy",
																				dataType:
																					"basic",
																			},
																		],
																},
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"showProductLevelMetricsModal",
																				dataType:
																					"basic",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
																{
																	type: "api_function",
																	apiEndPoint:
																		"model",
																	apiMethod:
																		"post",
																	params: [
																		{
																			source: "fixed",
																			apiRequestKey:
																				"id",
																			value: 4001,
																			dataType:
																				"basic",
																		},
																		{
																			source: "filters",
																			dataType:
																				"object",
																			apiRequestKey:
																				"parameters",
																		},
																		{
																			source: "reducer",
																			dataType:
																				"basic",
																			sourceId:
																				"selectedStrategy",
																			apiRequestKey:
																				"parameters",
																			subjectId:
																				"pcd_start_date",
																			apiRequestKeyNested:
																				"start_date",
																		},
																		{
																			source: "reducer",
																			dataType:
																				"basic",
																			sourceId:
																				"selectedStrategy",
																			apiRequestKey:
																				"parameters",
																			subjectId:
																				"pcd_end_date",
																			apiRequestKeyNested:
																				"end_date",
																		},
																		{
																			source: "reducer",
																			dataType:
																				"basic",
																			sourceId:
																				"selectedStrategy",
																			apiRequestKey:
																				"parameters",
																			subjectId:
																				"strategy_id",
																			apiRequestKeyNested:
																				"strategy_id",
																		},
																		// {
																		// 	source: "table",
																		// 	dataType:
																		// 		"basic",
																		// 	sourceId:
																		// 		"decision_dashboard_strategies",
																		// 	subjectId:
																		// 		"strategy_id",
																		// 	selection:
																		// 		"single",
																		// 	apiRequestKey:
																		// 		"parameters",
																		// },
																	],
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"decisionDashboardProductTableData",
																				dataType:
																					"array",
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Store Count",
														key: "stores_count",
														comparator: "number",
														action: null,
														hidden: false,
														aggregate: null,
														sorting: true,
														filter: true,
														pinned: false,
														row_group: false,
														cellRenderer:
															"clickActionCellRenderer",
														cellRendererParams: {
															actions: [
																{
																	type: "reducer_function",
																	params: [
																		{
																			source: "self",
																			apiRequestKey:
																				"selectedStrategy",
																			dataType:
																				"basic",
																		},
																	],
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"selectedStrategy",
																				apiResponseKey:
																					"selectedStrategy",
																				dataType:
																					"basic",
																			},
																		],
																},
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"showStoreLevelMetricsModal",
																				dataType:
																					"basic",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
																{
																	type: "api_function",
																	apiEndPoint:
																		"model",
																	apiMethod:
																		"post",
																	params: [
																		{
																			source: "fixed",
																			apiRequestKey:
																				"id",
																			value: 4002,
																			dataType:
																				"basic",
																		},
																		{
																			source: "filters",
																			dataType:
																				"object",
																			apiRequestKey:
																				"parameters",
																		},
																		{
																			source: "reducer",
																			dataType:
																				"basic",
																			sourceId:
																				"selectedStrategy",
																			subjectId:
																				"strategy_id",
																			apiRequestKey:
																				"parameters",
																			apiRequestKeyNested:
																				"strategy_id",
																		},
																	],
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"decisionDashboardStrategyStoreMetrics",
																				dataType:
																					"array",
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Clearance Discount %",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_clearance_discount",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_clearance_discount",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_clearance_discount",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_clearance_discount",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Margin",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_gm_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_gm_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_gm_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_gm_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Sales Units",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_sales_units",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_sales_units",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_sales_units",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_sales_units",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Revenue",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_revenue_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_revenue_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_revenue_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_revenue_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Sell Through",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_sell_through",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_sell_through",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_sell_through",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_sell_through",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Markdown $",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_markdown_dollar",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_markdown_dollar",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_markdown_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_markdown_$",
																comparator:
																	"number",
																action: null,
																hidden: false,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Inventory",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_inventory",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_inventory",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_inventory",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_inventory",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
													{
														type: "static_column",
														mapping: "data",
														header: "GM%",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_gm_percent",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_gm_percent",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_gm_percent",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_gm_percent",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
													{
														type: "static_column",
														mapping: "data",
														header: "AUM",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_aum",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_aum_$",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_aum_$",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_aum_$",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
													{
														type: "static_column",
														mapping: "data",
														header: "AUR",
														columns: [
															{
																type: "static_column",
																mapping: "data",
																header: "LW",
																key: "metrics.lw_aur_$",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "Strategy Till Date",
																key: "metrics.till_date_aur_$",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "IA Reco",
																key: "metrics.ia_reco_aur_$",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
															{
																type: "static_column",
																mapping: "data",
																header: "BL Override",
																key: "metrics.bl_aur_$",
																comparator:
																	"number",
																action: null,
																hidden: true,
																aggregate: null,
																sorting: true,
																filter: true,
																pinned: false,
																row_group: false,
															},
														],
													},
												],
												add_metrics_new: [
													{
														label: "Margin",
														value: "gm_dollars",
														metric_name: "Metrics",
														metric_type:
															"drop_down",
														columns_included: [
															"metrics.lw_gm_$",
															"metrics.till_date_gm_$",
															"metrics.ia_reco_gm_$",
															"metrics.bl_gm_$",
														],
													},
													{
														label: "Sales Units",
														value: "sales_units",
														metric_name: "Metrics",
														metric_type:
															"drop_down",
														columns_included: [
															"metrics.lw_sales_units",
															"metrics.till_date_sales_units",
															"metrics.ia_reco_sales_units",
															"metrics.bl_sales_units",
														],
													},
													{
														label: "Revenue",
														value: "sales_dollars",
														metric_name: "Metrics",
														metric_type:
															"drop_down",
														columns_included: [
															"metrics.lw_revenue_$",
															"metrics.till_date_revenue_$",
															"metrics.ia_reco_revenue_$",
															"metrics.bl_revenue_$",
														],
													},
													{
														label: "Sell Through",
														value: "sell_through",
														metric_name: "Metrics",
														metric_type:
															"drop_down",
														columns_included: [
															"metrics.lw_sell_through",
															"metrics.till_date_sell_through",
															"metrics.ia_reco_sell_through",
															"metrics.bl_sell_through",
														],
													},
													{
														label: "Markdown $",
														value: "markdown_$",
														metric_name: "Metrics",
														metric_type:
															"drop_down",
														columns_included: [
															"metrics.lw_markdown_dollar",
															"metrics.till_date_markdown_dollar",
															"metrics.ia_reco_markdown_$",
															"metrics.bl_markdown_$",
														],
													},
													{
														label: "Inventory",
														value: "inventory",
														metric_name: "Metrics",
														metric_type:
															"drop_down",
														columns_included: [
															"metrics.lw_inventory",
															"metrics.till_date_inventory",
															"metrics.ia_reco_inventory",
															"metrics.bl_inventory",
														],
													},
													{
														label: "AUM",
														value: "aum_$",
														metric_name: "Metrics",
														metric_type:
															"drop_down",
														columns_included: [
															"metrics.lw_aum",
															"metrics.till_date_aum_$",
															"metrics.ia_reco_aum_$",
															"metrics.bl_aum_$",
														],
													},
													{
														label: "AUR",
														value: "aur_$",
														metric_name: "Metrics",
														metric_type:
															"drop_down",
														columns_included: [
															"metrics.lw_aur_$",
															"metrics.till_date_aur_$",
															"metrics.ia_reco_aur_$",
															"metrics.bl_aur_$",
														],
													},
													{
														label: "GM%",
														value: "gm_percent",
														metric_name: "Metrics",
														metric_type:
															"drop_down",
														columns_included: [
															"metrics.lw_gm_percent",
															"metrics.till_date_gm_percent",
															"metrics.ia_reco_gm_percent",
															"metrics.bl_gm_percent",
														],
													},
												],
											},
										},
										dataProps: {
											data: {
												type: "derived",
												dataKey:
													"decisionDashboardTableData",
											},
										},
										componentProps: {
											content: [
												{
													alignment: "left",
													type: "div",
													staticProps: {
														className:
															"flex-container",
													},
													componentProps: {
														content: [
															{
																isDefaultExport: false,
																type: "text",
																staticProps: {
																	className:
																		"common-page-title",
																	variant:
																		"h4",
																	content:
																		"Time Period :",
																	customStyle:
																		{
																			color: "#758490",
																			fontSize:
																				"12px",
																			fontWeight:
																				"100",
																		},
																},
															},
															{
																isDefaultExport: false,
																type: "text",
																staticProps: {
																	className:
																		"common-page-title",
																	variant:
																		"h4",
																	customStyle:
																		{
																			marginLeft:
																				"10px",
																			fontSize:
																				"12px",
																			display:
																				"flex",
																			gap: "5px",
																		},
																},
																dataProps: {
																	dynamicContent:
																		{
																			type: "derived",
																			dataKey:
																				"selectedTimeline",
																			textContent:
																				[
																					{
																						content:
																							"start_date",
																						type: "dynamic",
																						format: "date",
																					},
																					{
																						content:
																							"to",
																						type: "static",
																						format: "date",
																					},
																					{
																						content:
																							"end_date",
																						type: "dynamic",
																						format: "date",
																					},
																				],
																		},
																},
															},
														],
													},
												},
												{
													alignment: "right",
													type: "div",
													staticProps: {
														className:
															"flex-container",
													},
													componentProps: {
														content: [
															{
																isDefaultExport: false,
																type: "text",
																staticProps: {
																	className:
																		"common-page-title",
																	variant:
																		"h4",
																	content:
																		"View By",
																	customStyle:
																		{
																			color: "#758490",
																			fontSize:
																				"12px",
																			fontWeight:
																				"100",
																			paddingRight:
																				"10px",
																		},
																},
															},
															{
																type: "select",
																id: "decision_dashboard_metric_level",
																staticProps: {
																	customStyle:
																		{
																			width: "200px",
																		},
																	isMultipleSelection: false,
																	hideClearSelection: true,
																	hideSearch: true,
																	updateSelectedOnEachSelection: true,
																	options: [
																		{
																			value: "pcd",
																			label: "PCD",
																		},
																		{
																			value: "aggregate",
																			label: "Aggregate",
																		},
																	],
																	selectedOptions:
																		[
																			{
																				value: "pcd",
																				label: "PCD",
																			},
																		],
																},
																functionProps: [
																	{
																		functionName:
																			"onSelect",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					params: [
																						{
																							source: "self",
																							apiRequestKey:
																								"selectedData",
																							dataType:
																								"array",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"decision_dashboard_metric_level",
																								apiResponseKey:
																									"selectedData",
																								dataType:
																									"array",
																								overwrite: true,
																							},
																						],
																				},
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 4000,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"decision_dashboard_metric_level",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"view_by",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"decision_dashboard_metric_timeline",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"timeline",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"selectedTimeline",
																								apiResponseKey:
																									"timeline",
																								dataType:
																									"object",
																								overwrite: true,
																							},
																							{
																								destination:
																									"reducer",
																								apiResponseKey:
																									"table_data",
																								dataKey:
																									"decisionDashboardTableData",
																								dataType:
																									"array",
																								overwrite: true,
																							},
																						],
																				},
																			],
																	},
																],
															},
															{
																type: "btnDropdown",
																staticProps: {
																	options: [
																		{
																			label: "Next Week",
																			value: "1 week",
																		},
																		{
																			label: "Next 4 Weeks",
																			value: "4 weeks",
																		},
																		{
																			label: "Next 6 Weeks",
																			value: "6 weeks",
																		},
																		{
																			label: "Next 13 Weeks",
																			value: "13 weeks",
																		},
																	],
																	variant:
																		"primary",
																	icon: "schedule",
																	className:
																		"icon-button",
																},
																functionProps: [
																	{
																		functionName:
																			"onSelect",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					params: [
																						{
																							source: "self",
																							apiRequestKey:
																								"selectedData",
																							dataType:
																								"basic",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"decision_dashboard_metric_timeline",
																								apiResponseKey:
																									"selectedData",
																								dataType:
																									"basic",
																							},
																						],
																				},
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 4000,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"decision_dashboard_metric_timeline",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"timeline",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"decision_dashboard_metric_level",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"view_by",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"selectedTimeline",
																								apiResponseKey:
																									"timeline",
																								dataType:
																									"object",
																								overwrite: true,
																							},
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"decisionDashboardTableData",
																								apiResponseKey:
																									"table_data",
																								dataType:
																									"array",
																								overwrite: true,
																							},
																						],
																				},
																			],
																	},
																],
															},
															{
																alignment:
																	"right",
																id: "decision-dashboard-edit-button",
																type: "iconButton",
																staticProps: {
																	variant:
																		"primary",
																	icon: "edit",
																	iconClass:
																		"colorWhite",
																	className:
																		"icon-button",
																	display: {
																		type: "controlled",
																		default:
																			{
																				show: false,
																				enabled: true,
																			},
																	},
																	customStyle:
																		{
																			marginLeft:
																				"10px",
																		},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 3088,
																							dataType:
																								"basic",
																						},
																						{
																							source: "table",
																							sourceId:
																								"decision_dashboard_strategies",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"strategy_id",
																							subjectId:
																								"strategy_id",
																							dataType:
																								"array",
																						},
																					],
																					onComplete:
																						{
																							actions:
																								[
																									{
																										type: "validation",
																										conditions:
																											[
																												{
																													comparison:
																														"notExists",
																													source: "response",
																												},
																											],
																										conditionOperator:
																											"or",
																										onValidationSuccess:
																											{
																												actions:
																													[
																														{
																															type: "reducer_function",
																															params: [
																																{
																																	source: "table",
																																	sourceId:
																																		"decision_dashboard_strategies",
																																	apiRequestKey:
																																		"read_only",
																																	dataType:
																																		"basic",
																																},
																															],
																															responseFormatter:
																																[
																																	{
																																		destination:
																																			"reducer",
																																		dataKey:
																																			"editReadOnlyFlag",
																																		apiResponseKey:
																																			"read_only",
																																		overwrite: true,
																																		dataType:
																																			"basic",
																																	},
																																],
																														},
																														{
																															type: "reducer_function",
																															params: [
																																{
																																	source: "table",
																																	sourceId:
																																		"decision_dashboard_strategies",
																																	apiRequestKey:
																																		"strategy_id",
																																	dataType:
																																		"basic",
																																},
																															],
																															responseFormatter:
																																[
																																	{
																																		destination:
																																			"reducer",
																																		dataKey:
																																			"step_1_A",
																																		overwrite: true,
																																		dataType:
																																			"object",
																																	},
																																],
																															onComplete:
																																{
																																	actions:
																																		[
																																			{
																																				type: "reducer_function",
																																				params: [
																																					{
																																						source: "table",
																																						sourceId:
																																							"decision_dashboard_strategies",
																																						subjectId:
																																							"step_count",
																																						apiRequestKey:
																																							"step_count",
																																						dataType:
																																							"basic",
																																					},
																																				],
																																				responseFormatter:
																																					[
																																						{
																																							destination:
																																								"reducer",
																																							dataKey:
																																								"step_1_A",
																																							subjectId:
																																								"step_count",
																																							apiResponseKey:
																																								"step_count",
																																							overwrite: false,
																																							dataType:
																																								"object",
																																						},
																																					],
																																			},
																																			{
																																				type: "redirect",
																																				link: "workbench/create-strategy",
																																			},
																																		],
																																},
																														},
																													],
																											},
																									},
																								],
																						},
																				},
																			],
																	},
																],
															},
															// Stratgies Download Button
															{
																type: "btnDropdown",
																staticProps: {
																	options: [
																		{
																			label: "Temporary Integration Extract",
																			value: "temp",
																		},
																		{
																			label: "Permanent Integration Extract",
																			value: "perm",
																		},
																		{
																			label: "Current View",
																			value: "current_view",
																		},
																	],
																	variant:
																		"primary",
																	icon: "file_download",
																	className:
																		"icon-button",
																},
																functionProps: [
																	{
																		functionName:
																			"onSelect",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					params: [
																						{
																							source: "self",
																							apiRequestKey:
																								"selectedData",
																							dataType:
																								"array",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"selected_download_option",
																								apiResponseKey:
																									"selectedData",
																								dataType:
																									"basic",
																								overwrite: true,
																							},
																						],
																				},
																				// Download for temp
																				{
																					type: "api_function",
																					apiEndPoint:
																						"report/integration-extract",
																					apiMethod:
																						"post",
																					confirmation:
																						{
																							type: "exists",
																							showErrorMessage: true,
																							conditions:
																								[
																									{
																										comparison:
																											"equals",
																										source: "reducer",
																										sourceId:
																											"selected_download_option",
																										checkValue:
																											"temp",
																									},
																								],
																							conditionOperator:
																								"and",
																						},
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 8004,
																							dataType:
																								"basic",
																						},
																						{
																							source: "fixed",
																							apiRequestKey:
																								"report_name",
																							value: "temp",
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"filters",
																						},
																						{
																							source: "table",
																							sourceId:
																								"decision_dashboard_strategies",
																							subjectId:
																								"strategy_id",
																							dataType:
																								"array",
																							apiRequestKey:
																								"selected_strategy_ids",
																							distinct: true,
																						},
																					],
																					responseFormatter:
																						[],
																				},
																				// Download for Perm
																				{
																					type: "api_function",
																					apiEndPoint:
																						"report/integration-extract",
																					apiMethod:
																						"post",
																					confirmation:
																						{
																							type: "exists",
																							showErrorMessage: true,
																							conditions:
																								[
																									{
																										comparison:
																											"equals",
																										source: "reducer",
																										sourceId:
																											"selected_download_option",
																										checkValue:
																											"perm",
																									},
																								],
																							conditionOperator:
																								"and",
																						},
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 8005,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"filters",
																						},
																						{
																							source: "fixed",
																							apiRequestKey:
																								"report_name",
																							value: "perm",
																							dataType:
																								"basic",
																						},
																						{
																							source: "table",
																							sourceId:
																								"decision_dashboard_strategies",
																							subjectId:
																								"strategy_id",
																							dataType:
																								"array",
																							apiRequestKey:
																								"selected_strategy_ids",
																							distinct: true,
																						},
																					],
																					responseFormatter:
																						[],
																				},
																				// Download for current_view
																				{
																					type: "api_function",
																					apiEndPoint:
																						"report-download",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 4,
																							isDownload: true,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"decision_dashboard_metric_timeline",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"timeline",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"decision_dashboard_metric_level",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"view_by",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"report_name",
																							value: "decision_dashboard_current_view_report",
																						},
																					],
																					confirmation:
																						{
																							type: "exists",
																							showErrorMessage: true,
																							conditions:
																								[
																									{
																										comparison:
																											"equals",
																										source: "reducer",
																										sourceId:
																											"selected_download_option",
																										checkValue:
																											"current_view",
																									},
																								],
																							conditionOperator:
																								"and",
																						},
																					responseFormatter:
																						[],
																				},
																			],
																	},
																],
															},
														],
													},
												},
											],
										},
										dependantControls: [
											{
												action: "rowSelection",
												controls: [
													{
														id: "decision-dashboard-edit-button",
														selection: "single",
														switchKey: "show",
														value: true,
													},
													{
														id: "decision-dashboard-download-button",
														selection:
															"none-and-data-exists",
														switchKey: "show",
														value: true,
													},
												],
											},
										],
									},
								],
							},
						},
					],
				},
			},
			{
				type: "fullScreenOverlay",
				componentProps: {
					content: [
						{
							id: "decision-dashboard-strategy-metrics",
							type: "div",
							staticProps: {
								className:
									"flex-container justify-space-between",
							},
							dataProps: {
								data: {},
							},
							componentProps: {
								content: [
									{
										id: "decision-dashboard-strategy-metrics-title",
										type: "div",
										staticProps: {
											className: "flex-container",
											customStyle: {
												gap: "10px",
											},
										},
										dataProps: {
											data: {},
										},
										componentProps: {
											content: [
												{
													id: "",
													isDefaultExport: false,
													type: "text",
													staticProps: {
														className: "",
														variant: "h4",
													},
													dataProps: {
														dynamicContent: {
															type: "derived",
															dataKey:
																"selectedStrategy",
															textContent: [
																{
																	content:
																		"strategy_name",
																	type: "dynamic",
																},
															],
														},
													},
												},
												{
													id: "",
													isDefaultExport: false,
													type: "text",
													staticProps: {
														className: "",
														variant: "paragraph",
													},
													dataProps: {
														dynamicContent: {
															type: "derived",
															dataKey:
																"selectedStrategy",
															textContent: [
																{
																	content:
																		"(",
																	type: "static",
																},
																{
																	content:
																		"pcd_start_date",
																	type: "dynamic",
																	format: "date",
																},
																{
																	content:
																		"-",
																	type: "static",
																},
																{
																	content:
																		"pcd_end_date",
																	type: "dynamic",
																	format: "date",
																},
																{
																	content:
																		")",
																	type: "static",
																},
															],
														},
													},
												},
											],
										},
									},
									{
										id: "return-to-dashboard-button",
										type: "button",
										staticProps: {
											icon: "return",
											iconClass: "",
											className: "",
											customStyle: {
												fontSize: "14px",
												margin: 0,
												fontWeight: 500,
												background: "transparent",
											},
											variant: "secondary",
											label: "Return to Dashboard",
											iconType:"svg",
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"showStoreLevelMetricsModal",
																dataType:
																	"basic",
																value: false,
																overwrite: true,
															},
														],
													},
												],
											},
										],
									},
								],
							},
						},
						{
							type: "aggrid",
							staticProps: {
								height: "400px",
								tableId:
									"decision_dashboard_strategies_store_count",
								uniqueKey: "store_h6_id",
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "Store",
											key: "store_h6_id",
											action: "",
											flex: 1,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											checkbox_selection: false,
											row_group: false,
											suppressMenu: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Store Name",
											key: "store_h6_name",
											action: null,
											flex: 2,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
									],
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"decisionDashboardStrategyStoreMetrics",
								},
							},
							componentProps: {},
						},
					],
				},
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "showStoreLevelMetricsModal",
					},
				},
			},
			{
				type: "fullScreenOverlay",
				componentProps: {
					content: [
						{
							type: "div",
							staticProps: {
								className: "flex-container",
								customStyle: {
									justifyContent: "space-between",
									marginBottom: "10px",
								},
							},
							componentProps: {
								content: [
									{
										type: "div",
										staticProps: {
											className: "flex-container",
										},
										componentProps: {
											content: [
												{
													id: "decision-dashboard-strategy-metrics-title",
													type: "div",
													staticProps: {
														className:
															"flex-container",
														customStyle: {
															gap: "10px",
														},
													},
													dataProps: {
														data: {},
													},
													componentProps: {
														content: [
															{
																id: "",
																isDefaultExport: false,
																type: "text",
																staticProps: {
																	className:
																		"",
																	variant:
																		"h4",
																},
																dataProps: {
																	dynamicContent:
																		{
																			type: "derived",
																			dataKey:
																				"selectedStrategy",
																			textContent:
																				[
																					{
																						content:
																							"strategy_name",
																						type: "dynamic",
																					},
																				],
																		},
																},
															},
															{
																id: "",
																isDefaultExport: false,
																type: "text",
																staticProps: {
																	className:
																		"",
																	variant:
																		"paragraph",
																},
																dataProps: {
																	dynamicContent:
																		{
																			type: "derived",
																			dataKey:
																				"selectedStrategy",
																			textContent:
																				[
																					{
																						content:
																							"(",
																						type: "static",
																					},
																					{
																						content:
																							"pcd_start_date",
																						type: "dynamic",
																						format: "date",
																					},
																					{
																						content:
																							"-",
																						type: "static",
																					},
																					{
																						content:
																							"pcd_end_date",
																						type: "dynamic",
																						format: "date",
																					},
																					{
																						content:
																							")",
																						type: "static",
																					},
																				],
																		},
																},
															},
														],
													},
												},
											],
										},
									},
									{
										id: "return-to-dashboard-group-button",
										type: "button",
										staticProps: {
											icon: "return",
											iconClass: "",
											className: "",
											customStyle: {
												fontSize: "14px",
												margin: 0,
												fontWeight: 500,
												background: "transparent",
											},
											variant: "secondary",
											label: "Return to Dashboard",
											iconType:"svg",
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"showProductLevelMetricsModal",
																overwrite: true,
																value: false,
															},
														],
													},
												],
											},
										],
									},
								],
							},
						},
						{
							type: "aggrid",
							staticProps: {
								height: "400px",
								tableId:
									"decision_dashboard_strategies_product_count",
								rowSelection: "multiple",
								uniqueKey: "product_h5_id",
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "SKU Id",
											key: "product_h5_id",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: true,
											lockPinned: true,
											checkbox_selection: false,
											row_group: false,
											suppressMenu: true,
											cellStyle: {
												background: "#f7f7f7",
											},
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Strategy",
											key: "strategy_name",
											action: "",
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: true,
											checkbox_selection: false,
											row_group: false,
											cellStyle: {
												background: "#f7f7f7",
											},
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Clearance Discount %",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_clearance_discount",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_clearance_discount",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_clearance_discount",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_clearance_discount",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Margin",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_margin",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_margin",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_gm_$",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_gm_$",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Sales Units",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_sales_units",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_sales_units",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_sales_units",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_sales_units",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Revenue",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_revenue",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_revenue",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_revenue_$",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_revenue_$",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Sell Through",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_st_percent",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_st_percent",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_sell_through",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_sell_through",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Markdown $",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_markdown_dollar",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_markdown_dollar",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_markdown_$",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_markdown_$",
													comparator: "number",
													action: null,
													hidden: false,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Inventory",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_inventory",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_inventory",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_inventory",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_inventory",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
										{
											type: "static_column",
											mapping: "data",
											header: "GM%",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_gm_percent",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_gm_percent",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_gm_percent",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_gm_percent",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
										{
											type: "static_column",
											mapping: "data",
											header: "AUM",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_aum",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_aum",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_aum_$",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_aum_$",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
										{
											type: "static_column",
											mapping: "data",
											header: "AUR",
											columns: [
												{
													type: "static_column",
													mapping: "data",
													header: "LW",
													key: "lw_aur",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "Strategy Till Date",
													key: "till_date_aur",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "IA Reco",
													key: "ia_reco_aur_$",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
												{
													type: "static_column",
													mapping: "data",
													header: "BL Override",
													key: "bl_aur_$",
													comparator: "number",
													action: null,
													hidden: true,
													aggregate: null,
													sorting: true,
													filter: true,
													pinned: false,
													row_group: false,
												},
											],
										},
									],
									add_metrics_new: [
										{
											label: "Margin",
											value: "gm_dollars",
											metric_name: "Metrics",
											metric_type: "drop_down",
											columns_included: [
												"lw_margin",
												"till_date_margin",
												"ia_reco_gm_$",
												"bl_gm_$",
											],
										},
										{
											label: "Sales Units",
											value: "sales_units",
											metric_name: "Metrics",
											metric_type: "drop_down",
											columns_included: [
												"lw_sales_units",
												"till_date_sales_units",
												"ia_reco_sales_units",
												"bl_sales_units",
											],
										},
										{
											label: "Revenue",
											value: "sales_dollars",
											metric_name: "Metrics",
											metric_type: "drop_down",
											columns_included: [
												"lw_revenue",
												"till_date_revenue",
												"ia_reco_revenue_$",
												"bl_revenue_$",
											],
										},
										{
											label: "Sell Through",
											value: "sell_through",
											metric_name: "Metrics",
											metric_type: "drop_down",
											columns_included: [
												"lw_st_percent",
												"till_date_st_percent",
												"ia_reco_sell_through",
												"bl_sell_through",
											],
										},
										{
											label: "Markdown $",
											value: "markdown_$",
											metric_name: "Metrics",
											metric_type: "drop_down",
											columns_included: [
												"lw_markdown_dollar",
												"till_date_markdown_dollar",
												"ia_reco_markdown_$",
												"bl_markdown_$",
											],
										},
										{
											label: "Inventory",
											value: "inventory",
											metric_name: "Metrics",
											metric_type: "drop_down",
											columns_included: [
												"lw_inventory",
												"till_date_inventory",
												"ia_reco_inventory",
												"bl_inventory",
											],
										},
										{
											label: "AUM",
											value: "aum_$",
											metric_name: "Metrics",
											metric_type: "drop_down",
											columns_included: [
												"lw_aum",
												"till_date_aum",
												"ia_reco_aum_$",
												"bl_aum_$",
											],
										},
										{
											label: "AUR",
											value: "aur_$",
											metric_name: "Metrics",
											metric_type: "drop_down",
											columns_included: [
												"lw_aur",
												"till_date_aur",
												"ia_reco_aur_$",
												"bl_aur_$",
											],
										},
										{
											label: "GM%",
											value: "gm_percent",
											metric_name: "Metrics",
											metric_type: "drop_down",
											columns_included: [
												"lw_gm_percent",
												"till_date_gm_percent",
												"ia_reco_gm_percent",
												"bl_gm_percent",
											],
										},
									],
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"decisionDashboardProductTableData",
								},
							},
							componentProps: {},
						},
					],
				},
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "showProductLevelMetricsModal",
					},
				},
			},
		],
	},
};
