/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
import { map } from "lodash";
import { API } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import { formatGlobalHierarchyOptions } from "../../../utils/helpers/apiHelpers";
import { setFilteredData } from "../filters/filters";
import {
  global_attributes,
  screen_config,
} from "../../../constants/NewConstants";

const initialState = {
  loading: 0,
  lengthyOpLoader: false,
  error: null,
  message: "",
  fiscalCalendar: [],
  disableDataLoadScreen: [],
  screenConfig: {},
  elementLabels: {},
  globalMetrics: [],
  navConfig: {},
  sseCompletedEvents: [],
  updatedTime: {},
  notifications: [],
  userScreens: {}, // By default access to all screens - change to none later
  userPermissions: null, // No access to actions by default - Change to null
  newSseConnection: false,
  globalConfigOptions: [],
  unreadCount: 0,
  snacks: [],
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    requestStart(state, action) {
      state.loading += 1;
      state.error = false;
    },
    requestStop(state, action) {
      state.loading -= 1;
      state.error = false;
    },
    requestComplete(state, action) {
      state.loading -= 1;
      state.error = false;
      state.message = action.payload ? action.payload : null;
      if (state.message) {
        const payload = {
          key: new Date().getTime(),
          message: state.message,
          alertType: "info",
        };
        state.snacks.push(payload);
      }
    },
    requestFail(state, action) {
      const error = action.payload || "Something went wrong. Please try again";
      state.loading -= 1;
      state.error = error;
      state.message = "";
      if (error) {
        const payload = {
          key: new Date().getTime(),
          message: state.error,
          alertType: "error",
        };
        state.snacks.push(payload);
      }
    },
    disableLoader(state, action) {
      state.loading = 0;
    },
    requestStartNoLoader(state, action) {
      state.error = false;
    },
    requestCompleteNoLoader(state, action) {
      state.error = false;
      state.message = action.payload ? action.payload : null;
      if (state.message) {
        const payload = {
          key: new Date().getTime(),
          message: state.message,
          alertType: "info",
        };
        state.snacks.push(payload);
      }
    },
    requestFailNoLoader(state, action) {
      const error = action.payload || "Something went wrong. Please try again";
      state.error = error;
      state.message = "";
      if (error) {
        const payload = {
          key: new Date().getTime(),
          message: state.error,
          alertType: "error",
        };
        state.snacks.push(payload);
      }
    },
    toastError(state, action) {
      const error = action.payload || "Something went wrong. Please try again";
      state.error = error;
      state.message = "";
      if (error) {
        const payload = {
          key: new Date().getTime(),
          message: state.error,
          alertType: "error",
        };
        state.snacks.push(payload);
      }
    },
    removeSnack(state, action) {
      const key = action.payload;
      state.snacks = state.snacks.filter((item) => {
        return item?.key != key;
      });
    },
    resetToastMessage(state, action) {
      state.error = null;
      state.message = null;
    },
    setScreenDisableDataLoad(state, action) {
      state.disableDataLoadScreen = action.payload;
    },
    setFiscalCalendar(state, action) {
      state.fiscalCalendar = action.payload;
    },
    setScreenConfig(state, action) {
      state.screenConfig = action.payload;
    },
    setElementLabels(state, action) {
      state.elementLabels = action.payload;
    },
    setMetrics(state, action) {
      state.globalMetrics = action.payload;
    },
    setNavigationConfig(state, action) {
      state.navConfig = action.payload;
    },
    sseCompleteEvents(state, action) {
      state.sseCompletedEvents = action.payload;
    },
    clearCompletedEvents(state, action) {
      state.sseCompletedEvents = [];
    },
    setUpdatedTime(state, action) {
      state.updatedTime = action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setUnreadNotificationsCount(state, action) {
      state.unreadCount = action.payload;
    },
    setUserScreens(action, payload) {
      state.userScreens = action.payload;
    },
    setUserPermissions(state, action) {
      state.userPermissions = action.payload;
    },
    toggleLengthyOpLoader(state, action) {
      state.lengthyOpLoader = action.payload;
    },
    setNewSseConnection(state, action) {
      state.newSseConnection = action.payload;
    },
    setGlobalConfigOptions(state, action) {
      state.globalConfigOptions = action.payload;
    },
  },
});

export const {
  requestStart,
  requestComplete,
  requestFail,
  requestStartNoLoader,
  requestCompleteNoLoader,
  requestFailNoLoader,
  toastError,
  resetToastMessage,
  setScreenDisableDataLoad,
  setFiscalCalendar,
  setScreenConfig,
  setElementLabels,
  setNavigationConfig,
  sseCompleteEvents,
  clearCompletedEvents,
  setNotifications,
  setUnreadNotificationsCount,
  disableLoader,
  setUserScreens,
  setUserPermissions,
  setUpdatedTime,
  toggleLengthyOpLoader,
  setMetrics,
  setNewSseConnection,
  setGlobalConfigOptions,
  removeSnack,
  requestStop,
} = globalSlice.actions;

export default globalSlice.reducer;

export const getGlobalConfig = () => (dispatch, getState) => {
  dispatch(requestStart());
  let filterList =
    screen_config[global_attributes.landing_screen] &&
    screen_config[global_attributes.landing_screen].filter_config
      ? screen_config[global_attributes.landing_screen].filter_config
      : [];
  let filtersData = {};
  filterList &&
    map(filterList, (o) => {
      filtersData[o.type] = o.type.includes("dateRange")
        ? { start_date: null, end_date: null, weeks: [] }
        : {
            options: [],
            selectedItems: [],
            selectedItemsArray: [],
          };
    });
  dispatch(
    setFilteredData({
      payload: filtersData,
      activeScreen: global_attributes.landing_screen,
    })
  );
  dispatch(setElementLabels(global_attributes.labels));
  dispatch(setScreenConfig(screen_config));
  dispatch(requestComplete());
};

export const removeLoader = () => (dispatch, getState) => {
  const loading = getState().global.loading;
  if (loading !== 0) {
    dispatch(disableLoader());
  }
};

export const getFiscalCalendar = (payload) => (dispatch, getState) => {
  dispatch(requestStart());
  return API.post("/fiscal-calendar", payload)
    .then((response) => {
      if (response.data && response.data.status === 200) {
        const { data } = response.data;
        // const fiscalCalendarData = formatCalendarData(data);
        dispatch(setFiscalCalendar(data));
        dispatch(requestComplete());
        return data;
      } else {
        dispatch(requestFail());
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      const errorMessage = setErrorMessage(error);
      dispatch(requestFail(errorMessage));
      return false;
    });
};

export const getNotifications =
  (showLoader = true) =>
  (dispatch, getState) => {
    if (showLoader) {
      dispatch(requestStart());
    } else {
      dispatch(requestStartNoLoader());
    }
    // return API.post("/model", { id: 5000 })
    // 	.then((response) => {
    // 		if (response.data && response.data.status === 200) {
    // 			const { data } = response.data;
    // 			// Check which notifications are new and add read = false
    // 			dispatch(setNotifications(data));
    // 			if (showLoader) {
    // 				dispatch(requestComplete());
    // 			} else {
    // 				dispatch(requestCompleteNoLoader());
    // 			}
    // 			return data;
    // 		}
    // 	})
    // 	.catch((error) => {
    // 		console.log(error);
    // 		const errorMessage = setErrorMessage(error);
    // 		if (showLoader) {
    // 			dispatch(requestFail(errorMessage));
    // 		} else {
    // 			dispatch(requestFailNoLoader(errorMessage));
    // 		}
    // 		return false;
    // 	});
  };

export const updateNotificationReadStatus =
  (payload) => (dispatch, getState) => {
    dispatch(requestStart());
    return API.post("/model", { id: 5001, ...payload })
      .then((response) => {
        if (response.data && response.data.status === 200) {
          // Check which notifications are new and add read = false
          dispatch(requestComplete());
          return true;
        }
      })
      .catch((error) => {
        const errorMessage = setErrorMessage(error);
        dispatch(requestFail(errorMessage));
        return false;
      });
  };

export const updateNotificationStatus = (payload) => (dispatch, getState) => {
  dispatch(requestStartNoLoader());
  API.put("/notifications", payload)
    .then((response) => {
      if (response.data && response.data.status === 200) {
        dispatch(requestCompleteNoLoader());
      }
    })
    .catch((error) => {
      console.log(error);
      const errorMessage = setErrorMessage(error);
      dispatch(requestFail(errorMessage));
      dispatch(requestComplete());
    });
};

export const addNewNotification =
  (notificationsList) => (dispatch, getState) => {
    const { notifications } = _.cloneDeep(getState().global.notifications);
    const updatedNotifications = {
      notifications: [...notificationsList, ...notifications],
      notifications_count: [...notificationsList, ...notifications]?.length,
    };
    dispatch(setNotifications(updatedNotifications));
  };

export const updateUnreadCount = (count) => (dispatch, getState) => {
  const tempCount = count;
  // const updatedNotifications = [...notificationsList, ...notifications];
  dispatch(setUnreadNotificationsCount(tempCount));
};

export const getUserScreens = () => (dispatch, getState) => {
  dispatch(requestStart());
  API.get("/user-management/screens/")
    .then((response) => {
      if (response.data && response.data.status === true) {
        const { data } = response.data;
        dispatch(setUserScreens(data));
        dispatch(requestComplete());
      }
    })
    .catch((error) => {
      console.log(error);
      const errorMessage = setErrorMessage(error);
      dispatch(requestFail(errorMessage));
    });
};

export const getUserHierarchy = (screen) => (dispatch, getState) => {
  dispatch(requestStart());
  API.get("/user-management/screen-hierarchy?app=pricesmart&screen=" + screen)
    .then((response) => {
      if (response.data && response.data.status === true) {
        const { data } = response.data;
        if (data) {
          dispatch(setUserPermissions(data));
        }
        dispatch(requestComplete());
      }
    })
    .catch((error) => {
      console.log(error);
      const errorMessage = setErrorMessage(error);
      dispatch(requestFail(errorMessage));
    });
};

export const getGlobalConfigOptions = (payload) => (dispatch, getState) => {
  dispatch(requestStart());
  return API.post("/model-data", payload)
    .then((response) => {
      if (response.data && response.data.status === 200) {
        const { data } = response.data;
        // const fiscalCalendarData = formatCalendarData(data);
        dispatch(
          setGlobalConfigOptions(formatGlobalHierarchyOptions(data?.[0]))
        );
        dispatch(requestComplete());
      } else {
        dispatch(requestFail());
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      const errorMessage = setErrorMessage(error);
      dispatch(requestFail(errorMessage));
      return false;
    });
};

export const validateOperation = (payload) => (dispatch) => {
  dispatch(requestStart());
  return API.post("/validate_edit", payload)
    .then((response) => {
      if (response.data) {
        const { data } = response.data;
        // const fiscalCalendarData = formatCalendarData(data);

        dispatch(requestComplete());

        return data;
      } else {
        dispatch(requestFail());
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      const errorMessage = setErrorMessage(error);
      dispatch(requestFail(errorMessage));
      return false;
    });
};
