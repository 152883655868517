import moment from "moment";

export const PCD_CONFIG_VIEW = {
	screen_composition: {
		components: [
			{
				id: "pcd-config-view-filter",
				type: "filter",
				staticProps: {
					showSaveFilter: false,
					showPageHeader: true,
					showSaveFilter: true,
					filterHeader: "Pricing Calendar Config",
					pageHeader: "Pricing Calendar Config",
					accordionLabel: "Pricing Calendar Config",
					showFilterButtons: true,
					showFilterChips: true,
					filter_config: [
						{
							type: "dateRange",
							is_mandatory: true,
							filter_endpoint: "",
							filter_type: "dateRange",
							filter_group: 1,
							filter_group_label: "Select Date Range",
							use_default: false, // will take from constants config if set to true
							start_date: moment().subtract(3, "weeks"),
							end_date: moment().add(3, "weeks"),
							date_picker_position: "down",
							// min_date: moment().subtract(12, "weeks"),
							// max_date: moment().add(10, "weeks"),
							// range_limitation: "limited_period",
							// max_selection_period: {
							// 	num: 12,
							// 	type: "weeks",
							// },
						},
						{
							type: "calendar_config_ids",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "model",
							filter_model: 6004,
							filter_type: "calendar_config_ids",
							filter_group: 1,
							filter_group_label: "Select Calendar Config",
							hideSearch: false,
						},
					],
				},
				functionProps: [
					{
						functionName: "onFilter",
						actions: [
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 6002,
										dataType: "basic",
									},
									{
										source: "filters",
										dataType: "object",
										apiRequestKey: "parameters",
									},
									{
										source: "fixed",
										dataType: "basic",
										apiRequestKey: "parameters",
										apiRequestKeyNested:
											"date_range_exact_match",
										value: false,
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "pcdConfigs",
										overwrite: true,
										dataType: "array",
									},
								],
								onComplete: {
									actions: [
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"product-group-config-download-button",
																dataType:
																	"object",
																value: {
																	show: true,
																},
																overwrite: false,
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"filterModalOpen",
																value: false,
															},
														],
													},
												],
											},
										},
									],
								},
							},
						],
					},
				],
				dataProps: {
					filterModalOpen: {
						type: "derived",
						dataKey: "filterModalOpen",
					},
				},
			},
			{
				id: "grid-wrapper-div",
				type: "div",
				staticProps: {
					className: "common-content-container",
				},
				componentProps: {
					content: [
						{
							id: "pcd_config_view",
							type: "aggrid",
							staticProps: {
								height: "400px",
								tableId: "pcd_config_view",
								rowSelection: "multiple",
								tableTitle:
									"List of Pricing Calendar Configurations",
								uniqueKey: "calendar_config_id",
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "",
											key: "",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: false,
											filter: false,
											pinned: true,
											lockPinned: true,
											checkbox_selection: true,
											row_group: false,
											suppressMenu: true,
											maxWidth: 50,
											cellStyle: {
												background: "#f7f7f7",
											},
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Config Name",
											key: "config_name",
											action: "",
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											checkbox_selection: false,
											row_group: false,
											flex: 2,
											cellStyle: {
												background: "#f7f7f7",
											},
											cellRenderer:
												"clickActionCellRenderer",
											cellRendererParams: {
												actions: [
													{
														type: "reducer_function",
														params: [
															{
																source: "self",
																subjectId:
																	"calendar_config_id",
																apiRequestKey:
																	"selectedCalendarConfig",
																dataType:
																	"basic",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																apiResponseKey:
																	"selectedCalendarConfig",
																subjectId:
																	"calendar_config_id",
																overwrite: true,
																dataType:
																	"basic",
															},
														],
														onComplete: {
															actions: [
																{
																	type: "redirect",
																	link: "pricing-calendar-configuration/create-pcd-config",
																},
															],
														},
													},
												],
											},
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Days",
											key: "no_of_days",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 1,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Date Range",
											key: "start_date",
											data_type: "date",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: false,
											filter: false,
											pinned: false,
											row_group: false,
											suppressMenu: true,
											valueFormatter:
												"dateRangeFormatter",
											flex: 1,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Created By",
											key: "created_by_user",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Created On",
											data_type: "date",
											key: "created_at",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
									],
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey: "pcdConfigs",
								},
							},
							dependantControls: [
								{
									action: "rowSelection",
									controls: [
										{
											id: "pcd-config-view-edit-button",
											selection: "single",
											switchKey: "show",
											value: true,
										},
										{
											id: "pcd-config-view-create-button",
											selection: "none",
											switchKey: "show",
											value: true,
										},
									],
								},
							],
							componentProps: {
								content: [
									{
										alignment: "right",
										id: "pcd-config-view-create-button",
										type: "button",
										staticProps: {
											variant: "primary",
											label: "Create New Config",
											display: {
												type: "controlled",
												default: {
													show: true,
													enabled: true,
												},
											},
											customStyle: {
												width: "180px",
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "redirect",
														link: "pricing-calendar-configuration/create-pcd-config",
													},
												],
											},
										],
									},
									{
										alignment: "right",
										id: "pcd-config-view-edit-button",
										type: "iconButton",
										staticProps: {
											variant: "primary",
											iconClass: "colorWhite",
											icon: "edit",
											className: "icon-button",
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: true,
												},
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														params: [
															{
																source: "table",
																sourceId:
																	"pcd_config_view",
																apiRequestKey:
																	"calendar_config_id",
																dataType:
																	"basic",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																overwrite: true,
																dataType:
																	"object",
															},
														],
														onComplete: {
															actions: [
																{
																	type: "redirect",
																	link: "pricing-calendar-configuration/create-pcd-config",
																},
															],
														},
													},
												],
											},
										],
									},
									{
										alignment: "right",
										id: "product-group-config-download-button",
										type: "iconButton",
										staticProps: {
											variant: "primary",
											icon: "file_download",
											iconClass: "colorWhite",
											className: "icon-button",
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: true,
												},
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "api_function",
														apiEndPoint:
															"report-download",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																apiRequestKey:
																	"id",
																value: 6,
																isDownload: true,
																dataType:
																	"basic",
															},

															{
																source: "filters",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
															},
															{
																source: "fixed",
																dataType:
																	"basic",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"date_range_exact_match",
																value: false,
															},
															{
																source: "fixed",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"report_name",
																value: "calendar_config_report",
															},
														],
														responseFormatter: [],
													},
												],
											},
										],
									},

									// {
									// 	alignment: "right",
									// 	id: "pcd-config-view-delete-button",
									// 	type: "iconButton",
									// 	staticProps: {
									// 		variant: "primary",
									// 		icon: "delete",
									// 		className: "icon-button",
									// 		display: {
									// 			type: "controlled",
									// 			default: {
									// 				show: false,
									// 				enabled: true,
									// 			},
									// 		},
									// 	},
									// 	functionProps: [
									// 		{
									// 			functionName: "onClick",
									// 			actions: [
									// 				{
									// 					type: "api_function",
									// 					apiEndPoint:
									// 						"pcd-configs",
									// 					apiMethod: "put",
									// 					params: [
									// 						{
									// 							source: "table",
									// 							sourceId:
									// 								"pcd_config_view",
									// 							apiRequestKey:
									// 								"pcd_config_id",
									// 							dataType:
									// 								"array",
									// 						},
									// 						{
									// 							source: "fixed",
									// 							apiRequestKey:
									// 								"is_deleted",
									// 							value: 1,
									// 							dataType:
									// 								"basic",
									// 						},
									// 					],
									// 					onComplete: {
									// 						actions: [
									// 							{
									// 								type: "api_function",
									// 								apiEndPoint:
									// 									"get-pcd-configs",
									// 								apiMethod:
									// 									"post",
									// 								params: [
									// 									{
									// 										source: "filters",
									// 										dataType:
									// 											"object",
									// 									},
									// 								],
									// 								responseFormatter:
									// 									[
									// 										{
									// 											destination:
									// 												"reducer",
									// 											dataKey:
									// 												"pcdConfigs",
									// 											overwrite: true,
									// 											dataType:
									// 												"array",
									// 										},
									// 									],
									// 							},
									// 						],
									// 					},
									// 				},
									// 			],
									// 		},
									// 	],
									// },
								],
							},
						},
					],
				},
			},
		],
	},
};
