import { rules_column_config } from "./../../../components/createStrategy/demoJsons";

export const Rules = {
	screen_composition: {
		components: [
			{
				type: "div",
				staticProps: {
					className: "stepper-container",
					customStyle: {
						alignItems: "center",
						paddingTop: "10px",
					},
				},
				functionProps: [
					{
						functionName: "onLoad",
						actions: [
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3001,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "parameters",
										value: "rule",
										apiRequestKeyNested: "section",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_3_strategy_data",
										dataType: "object",
										overwrite: true,
									},
									{
										destination: "reducer",
										dataKey: "maxStepCount",
										apiResponseKey: "step_count",
										dataType: "object",
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataType: "object",
										dataKey: "enable_next_for_optimization",
										value: false,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "rulesValidationFlag",
										value: true,
										overwrite: true,
									},
								],
							},
						],
					},
				],
				componentProps: {
					content: [
						{
							type: "div",
							staticProps: {
								className: "flex-container",
							},
							componentProps: {
								content: [
									{
										type: "text",
										staticProps: {
											className: "common-page-title",
											variant: "h5",
											content:
												"Please add the rules that are applicable for this MD Strategy.",
											customStyle: {
												padding: "0px !important",
												fontSize: "14px",
												marginBottom: "0",
											},
										},
									},
									// {
									// 	id: "add-applicable-rules-button",
									// 	type: "button",
									// 	staticProps: {
									// 		className: "common-page-title",
									// 		customStyle: {
									// 			padding: "0px !important",
									// 			fontSize: "14px",
									// 			marginBottom: "0",
									// 		},
									// 		variant: "url",
									// 		label: "Add applicable rules",
									// 	},
									// 	functionProps: [
									// 		{
									// 			functionName: "onClick",
									// 			actions: [
									// 				{
									// 					type: "reducer_function",
									// 					params: [
									// 						{
									// 							source: "reducer",
									// 							sourceId:
									// 								"step_1_A",
									// 							subjectId:
									// 								"strategy_id",
									// 							apiRequestKey:
									// 								"strategy_id",
									// 						},
									// 					],
									// 					responseFormatter: [
									// 						{
									// 							destination:
									// 								"reducer",
									// 							dataKey:
									// 								"rule_strategy_id",
									// 							dataType:
									// 								"basic",
									// 							overwrite: true,
									// 						},
									// 					],
									// 					onComplete: {
									// 						actions: [
									// 							{
									// 								type: "redirect",
									// 								link: "pricing-calendar-configuration",
									// 							},
									// 						],
									// 					},
									// 				},
									// 			],
									// 		},
									// 	],
									// },
								],
							},
						},
						{
							type: "text",
							staticProps: {
								className: "disclaimer",
								variant: "h5",
								content:
									"Drag the rules in the order of priority you need",
								customStyle: {
									marginBottom: "0",
									fontSize: "14px",
								},
							},
						},
					],
				},
			},
			// rules table
			{
				id: "strategy_rules",
				type: "aggrid",
				rowSelection: "single",
				staticProps: {
					height: "450px",
					borderBottom: "1px solid #EBECF0",
					paddingBottom: "20px",
					disableDomLayout: true,
					rowDraggable: true,
					tableId: "strategy_rules",
					uniqueKey: "row_num",
					tableConfig: { ...rules_column_config },
					className: "rules_strategy",
					stopEditingWhenCellsLoseFocus: true,
					rowStyleClass: "rowstyle_rules_strategy",
					enableDisableRowFunc: true,
					orderKey: "priority",
					sortByKeys: ["priority", "row_num"],
					disabled_key: "is_disabled",
					domLayout: true,
					updateParent: true,
				},
				dataProps: {
					data: {
						type: "derived",
						dataKey: "step_3_strategy_data",
						subjectId: "strategy_rules",
					},
				},
				functionProps: [
					{
						functionName: "onCellValueChanged",
						actions: [
							{
								type: "reducer_function",
								params: [
									{
										source: "self",
										apiRequestKey: "strategy_rules",
										dataType: "array",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_3_strategy_data",
										sourceId: "strategy_rules",
										dataType: "array",
									},
									{
										destination: "reducer",
										dataKey: "changeFlagForRules",
										value: true,
									},
									{
										destination: "reducer",
										dataKey: "rulesValidationFlag",
										value: true,
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
			// CTA buttons
			{
				type: "div",
				pathSrc: "components",
				componentPath: "ui/wrapperDiv/WrapperDiv",
				staticProps: {
					className: "flex-container",
					customStyle: {
						marginTop: "25px",
					},
				},
				componentProps: {
					content: [
						// Next button
						{
							type: "button",
							pathSrc: "components",
							componentPath: "ui/button/Button",
							id: "rules_modal_next",
							staticProps: {
								customStyle: {
									marginRight: "10px",
								},
								variant: "primary",
								label: "Next",
								display: {
									type: "controlled",
									default: {
										show: true,
										enabled: true,
									},
								},
							},
							functionProps: [
								// on click of next
								{
									functionName: "onClick",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"rulesValidationFlag",
													value: true,
													overwrite: true,
												},
											],
										},
										// This is for step count is one or if any rules are edited, trigger save api
										{
											type: "api_function",
											apiEndPoint: "model",
											apiMethod: "post",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "multiple",
														conditionOperator: "or",
														conditions: [
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"changeFlagForRules",
																checkValue: true,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"step_3_strategy_data",
																subjectId:
																	"step_count",
																checkValue: 1,
															},
														],
													},
													{
														comparison:
															"rulesValidation",
													},
												],
											},
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3103,
												},
												{
													source: "reducer",
													sourceId:
														"step_3_strategy_data",
													subjectId: "strategy_rules",
													value: "strategy_rules",
													dataType: "object",
													apiRequestKeyNested:
														"rules",
													apiRequestKey: "parameters",
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "step_1_A",
													subjectId: "strategy_id",
													apiResponseKey:
														"strategy_id",
													dataType: "object",
												},
												{
													destination: "reducer",
													dataKey: "maxStepCount",
													apiResponseKey:
														"step_count",
													dataType: "object",
												},
											],
											onComplete: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"showOptimizationModal",
																value: true,
															},
														],
													},
													{
														type: "validation",
														conditions: [
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"strategy_id",
															},
														],
														conditionOperator: "or",
														onValidationSuccess: {
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"changeFlagForRules",
																				dataType:
																					"basic",
																				value: false,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
												],
											},
										},
										// show modal
										{
											type: "reducer_function",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "multiple",
														conditionOperator: "or",
														conditions: [
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"step_3_strategy_data",
																subjectId:
																	"step_count",
																checkValue: 1,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"step_3_strategy_data",
																subjectId:
																	"step_count",
																checkValue: 2,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"changeFlagForRules",
																checkValue: true,
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"editReadOnlyFlag",
																checkValue: true,
															},
														],
													},
													{
														comparison:
															"rulesValidation",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showOptimizationModal",
													value: true,
												},
											],
										},
										// redirect from stepo 3 to step 4
										{
											type: "reducer_function",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "equals",
														source: "reducer",
														sourceId:
															"step_3_strategy_data",
														subjectId: "step_count",
														checkValue: 3,
													},
													{
														comparison: "notEquals",
														source: "reducer",
														sourceId:
															"changeFlagForRules",
														checkValue: true,
													},
													{
														comparison:
															"rulesValidation",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "step_1_A",
													subjectId: "currentStep",
													dataType: "basic",
													value: 3,
												},
											],
										},
									],
								},
								// on click of back
								{
									functionName: "onDeny",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showOptimizationModal",
													value: false,
												},
												// {
												// 	source: "fixed",
												// 	dataKey:
												// 		"rules_optimization_modal_details",
												// 	value: null,
												// 	overwrite: true,
												// },
											],
										},
									],
								},
							],
						},
						// Back button
						{
							type: "button",
							pathSrc: "components",
							componentPath: "ui/button/Button",
							staticProps: {
								variant: "secondary",
								label: "Back",
							},
							functionProps: [
								{
									functionName: "onClick",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "step_1_A",
													subjectId: "currentStep",
													dataType: "basic",
													value: 1,
												},
											],
										},
									],
								},
							],
						},
					],
				},
			},
			//Optimization modal
			{
				id: "rules_optimization_modal",
				type: "modal",
				staticProps: {
					className: "",
					modalTitle: "Select Optimization type",
					primaryButtonText: "Next",
					secondaryButtonText: "Cancel",
					modalSize: "medium",
				},
				componentProps: {
					content: [
						{
							type: "div",
							pathSrc: "components",
							componentPath: "ui/wrapperDiv/WrapperDiv",
							staticProps: {
								customStyle: {
									display: "flex",
									flexDirection: "column",
									height: "100%",
								},
							},
							componentProps: {
								content: [
									{
										type: "text",
										pathSrc: "components",
										componentPath:
											"ui/typography/Typography",
										staticProps: {
											className: "common-page-title",
											variant: "h4",
											content: "Optimisation Type",
											customStyle: {
												padding: "0px !important",
												float: "left",
											},
										},
									},
									{
										type: "form",
										inputType: "dataInput",
										id: "rules_optimization_modal_details",
										pathSrc: "components",
										componentPath: "ui/form/Form",
										direction: "column",
										staticProps: {
											fields: [
												{
													type: "radio",
													id: 0,
													placeholder:
														"IA_optimization",
													variant: "outlined",
													isMandatory: true,
													label: "Impact Analytics Optimised (Recommended)",
													radioGroupId:
														"optimisation_type",
												},
												{
													inputType: "dataInput",
													type: "radio",
													id: 1,
													placeholder: "Description",
													variant: "outlined",
													isMandatory: true,
													label: "Partial Optimisation",
													radioGroupId:
														"optimisation_type",
												},
												{
													inputType: "dataInput",
													type: "radio",
													id: 2,
													placeholder: "Description",
													variant: "outlined",
													isMandatory: true,
													label: "No Optimisation",
													radioGroupId:
														"optimisation_type",
												},
											],
											customStyle: {
												display: "flex",
												flexDirection: "column",
												flexWrap: "no-wrap",
											},
										},
										functionProps: [
											{
												functionName: "onRadioClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"enable_next_for_optimization",
																value: true,
															},
														],
													},
												],
											},
										],
									},
								],
							},
						},
					],
				},
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "showOptimizationModal",
					},
					primaryButtonEnabled: {
						type: "derived",
						dataKey: "enable_next_for_optimization",
					},
				},
				functionProps: [
					{
						functionName: "onConfirm",
						actions: [
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									type: "check",
									showErrorMessage: true,
									conditions: [
										{
											comparison: "exists",
											source: "reducer",
											sourceId:
												"rules_optimization_modal_details",
											subjectId: "optimisation_type",
											dataType: "object",
											message:
												"Please select an optimization type",
										},
									],
									conditionOperator: "and",
								},
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3009,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "reducer",
										sourceId:
											"rules_optimization_modal_details",
										subjectId: "optimisation_type",
										dataType: "object",
										apiRequestKeyNested:
											"optimisation_type",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_1_A",
										subjectId: "strategy_id",
										apiResponseKey: "strategy_id",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "maxStepCount",
										apiResponseKey: "step_count",
										dataType: "object",
									},
								],
								onComplete: {
									actions: [
										// redirect to step 4 if partial or no optimiazation condiiotn is matched
										{
											type: "validation",
											conditions: [
												{
													comparison: "equals",
													source: "reducer",
													sourceId:
														"rules_optimization_modal_details",
													subjectId:
														"optimisation_type",
													value: 1,
												},
												{
													comparison: "equals",
													source: "reducer",
													sourceId:
														"rules_optimization_modal_details",
													subjectId:
														"optimisation_type",
													value: 2,
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"showOptimizationModal",
																value: false,
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																subjectId:
																	"currentStep",
																dataType:
																	"basic",
																value: 3,
															},
														],
													},
													// reset the optimization_type
													{
														type: "reducer_function",
														responseFormatter: [
															{
																source: "fixed",
																dataKey:
																	"rules_optimization_modal_details",
																value: null,
																overwrite: true,
															},
														],
													},
												],
											},
										},
										//call the optimize api and redirect to workbench screen if optimization type is IA-Reco
										{
											type: "validation",
											conditions: [
												{
													comparison: "equals",
													source: "reducer",
													sourceId:
														"rules_optimization_modal_details",
													subjectId:
														"optimisation_type",
													value: 0,
												},
											],
											conditionOperator: "and",
											onValidationSuccess: {
												actions: [
													{
														type: "api_function",
														apiEndPoint: "model",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																apiRequestKey:
																	"id",
																value: 3014,
																dataType:
																	"basic",
															},
															{
																source: "fixed",
																sourceId:
																	"ia_reco_updated_data",
																apiRequestKeyNested:
																	"discount_values",
																apiRequestKey:
																	"parameters",
																dataType:
																	"array",
																value: [],
															},
															{
																source: "reducer",
																sourceId:
																	"step_1_A",
																subjectId:
																	"strategy_id",
																// sourceId: "strategy_id",
																apiRequestKeyNested:
																	"strategy_id",
																apiRequestKey:
																	"parameters",
															},
															{
																source: "session_storage",
																sourceId:
																	"UNIQ_SSE_KEY",
																apiRequestKeyNested:
																	"guid",
																apiRequestKey:
																	"parameters",
															},
														],
														onComplete: {
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"showOptimizationModal",
																				value: false,
																				overwrite: true,
																			},
																		],
																},
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				source: "fixed",
																				dataKey:
																					"rules_optimization_modal_details",
																				value: null,
																				overwrite: true,
																			},
																		],
																},
																{
																	type: "redirect",
																	link: "workbench",
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "showOptimizationModal",
										value: false,
										overwrite: true,
									},
									{
										source: "fixed",
										dataKey: "enable_next_for_optimization",
										value: null,
									},
									{
										source: "fixed",
										dataKey:
											"rules_optimization_modal_details",
										value: null,
										overwrite: true,
									},
								],
							},
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3001,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "fixed",
										dataType: "object",
										value: "rule",
										apiRequestKeyNested: "section",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_3_strategy_data",
										dataType: "object",
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
			// Rule Edit Panel
			{
				type: "div",
				staticProps: {
					customStyle: {
						zIndex: 2,
						position: "absolute",
					},
				},
				dataProps: {
					is_visible: {
						type: "derived",
						dataKey: "editRuleForStrategyPanel",
					},
				},
				componentProps: {
					content: [
						{
							type: "panel",
							staticProps: {
								size: "large",
								primaryButton: true,
							},
							dataProps: {
								isOpen: {
									type: "derived",
									dataKey: "editRuleForStrategyPanel",
								},
							},
							componentProps: {
								content: [
									{
										type: "text",
										staticProps: {
											content: "Edit Rule for Strategy",
											variant: "h3",
										},
									},
									{
										id: "edit_rule_for_strategy_table",
										type: "aggrid",
										staticProps: {
											height: "400px",
											tableId:
												"edit_rule_for_strategy_table",
											uniqueKey: "level",
											tableConfig: {
												column_headers: [
													{
														type: "static_column",
														mapping: "data",
														header: "Class",
														key: "class",
														action: null,
														hidden: false,
														aggregate: null,
														sorting: true,
														filter: true,
														pinned: false,
														row_group: false,
													},
													// Applicable Discount
													{
														type: "static_column",
														mapping: "data",
														header: "Applicable values",
														key: "applicable_value",
														action: null,
														hidden: false,
														pinned: false,
														row_group: false,
														editable: false,
														extra: {
															showAsChips: true,
														},
														cellRenderer:
															"cellWithIconAndLabel",
														cellRendererFramework:
															"cellWithIconAndLabel",
														is_edit: false,
														suppressMenu: true,
														flex: 3,
													},
													// Min Max weeks PP	//No: Markdown // Step Size %
													{
														type: "static_column",
														mapping: "data",
														header: "Min Values",
														key: "min_value",
														hidden: false,
														pinned: false,
														row_group: false,
														cellRenderer:
															"customInputTypeEditor",
														cellRendererFramework:
															"customInputTypeEditor", // Use the custom dropdown editor
														extra: {
															MultipleOf: 5,
															range: [1, 100],
															enableDropDown: true,
															checkContraintType: true,
															inputMinVal: 1,
															inputMaxVal:
																"max_allowed_weeks",
															condtionKey:
																"max_value",
															// step size%
														},
														data_type: "number",
														is_edit: true,
														suppressMenu: true,
														width: 130,
														flex: 1,
													},
													{
														type: "static_column",
														mapping: "data",
														header: "Max Values",
														key: "max_value",
														hidden: false,
														pinned: false,
														row_group: false,
														cellRenderer:
															"customInputTypeEditor",
														cellRendererFramework:
															"customInputTypeEditor", // Use the custom dropdown editor
														extra: {
															MultipleOf: 5,
															range: [1, 100],
															enableDropDown: true,
															checkContraintType: true,
															inputMinVal: 1,
															inputMaxVal:
																"max_allowed_weeks",
															condtionKey:
																"min_value",
															// step size%
														},
														data_type: "number",
														is_edit: true,
														suppressMenu: true,
														width: 130,
														flex: 1,
													},
												],
											},
										},
										dataProps: {
											data: {
												type: "derived",
												dataKey: "strategy_rule_detail",
											},
										},
									},
								],
							},
							functionProps: [
								{
									functionName: "onClose",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"editRuleForStrategyPanel",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
								{
									functionName: "onConfirm",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"editRuleForStrategyPanel",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
											],
										},
									],
								},
							],
						},
					],
				},
			},
		],
	},
};
