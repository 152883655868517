export const EDIT_AGGREGATION_STRATEGY = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-content-emptystate",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            margin: "2px 10px",
            height: "600px",
          },
        },
        componentProps: {
          content: [
            {
              type: "div",
              id: "basic-details-sub-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h1",
                      content: "Edit Aggregation Strategy",
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "kpi-value-sub-container",
              staticProps: {
                customStyle: {
                  marginLeft: "15px",
                  display: "flex",
                  alignItems: "center",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          id: "kpi-type",
                          staticProps: {
                            content: "Select KPI",
                            variant: "h4",
                          },
                        },
                        {
                          type: "text",
                          id: "kpi-type",
                          staticProps: {
                            content: "Some additional dummy text",
                            variant: "paragraph",
                          },
                        },
                      ],
                    },
                  },

                  {
                    type: "div",
                    id: "select_container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "400px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "select",
                          id: "editAggregationKpi",
                          staticProps: {
                            isMultipleSelection: false,
                            hideClearSelection: true,
                            hideSearch: true,
                            updateSelectedOnEachSelection: true,
                            selectedOptionFromApi: true,
                            apiDataKey: "selectedKpi",
                            subjectId: "selectedKpi",
                          },
                          dataProps: {
                            options: {
                              type: "derived",
                              dataKey: "requiredListKpiDetails",
                              subjectId: "kpis",
                              dataType: "array",
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },

            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
                color: "black",
              },
            },
            {
              id: "aggregationStrategyForm",
              type: "form",
              staticProps: {
                dataKey: "aggregationStrategyForm",
                customStyle: {
                  width: "auto",
                },
                fields: [
                  {
                    type: "text",
                    id: "roll_up_product_hierarchy",
                    variant: "outlined",
                    placeholder: "Enter here",
                    label: "Product Hierarchy",
                    showLabel: true,
                  },
                  {
                    type: "text",
                    id: "roll_up_channel",
                    placeholder: "Enter here...",
                    variant: "outlined",
                    label: "Channel Aggregation",
                    showLabel: true,
                  },
                ],
              },
              dataProps: {
                roll_up_product_hierarchy: {
                  type: "derived",
                  dataKey: "selectedKpi",
                  subjectId: "roll_up_product_hierarchy",
                },
                roll_up_channel: {
                  type: "derived",
                  dataKey: "selectedKpi",
                  subjectId: "roll_up_channel",
                },
              },
            },
            {
              type: "div",
              id: "basic-details-buttons",
              staticProps: {
                customStyle: {
                  paddingBottom: "10px",
                  width: "100%",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "bottom-divider",
                    componentProps: {
                      content: [
                        {
                          id: "create-product-group-divider",
                          type: "divider",
                          staticProps: {
                            color: "black",
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "bottom-buttons",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        display: "flex",
                        marginLeft: "75%",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "cancel-basic-details-button",
                          type: "button",
                          staticProps: {
                            variant: "text",
                            label: "cancel",
                            className: "create-store-group-button",
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "redirect",
                                  link: "add-new-config",
                                },
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "loadEditAggregationStrategy",
                                      value: false,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          alignment: "right",
                          id: "strategy-workbench-create-button",
                          type: "button",
                          staticProps: {
                            variant: "secondary",
                            label: "Save",
                            customStyle: {
                              border: "1px solid",
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "api_function",
                                  runOnLoad: false,
                                  apiEndPoint: "model-update",
                                  apiMethod: "post",
                                  confirmation: {
                                    type: "check",
                                    showErrorMessage: true,
                                    conditions: [
                                      {
                                        comparison: "exists",
                                        source: "reducer",
                                        sourceId: "selectedKpi",
                                        subjectId: "kpi_id",
                                        dataType: "basic",
                                        message: "Please select the kpi name",
                                      },
                                    ],
                                  },
                                  params: [
                                    {
                                      source: "fixed",
                                      dataType: "object",
                                      apiRequestKey: "id",
                                      value: 71,
                                    },
                                    {
                                      source: "reducer",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "kpi",
                                      sourceId: "selectedKpi",
                                      subjectId: "kpi_id",
                                      dataType: "basic",
                                    },
                                    {
                                      source: "reducer",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested:
                                        "roll_up_product_hierarchy",
                                      sourceId: "selectedKpi",
                                      subjectId: "roll_up_product_hierarchy",
                                      dataType: "basic",
                                    },
                                    {
                                      source: "reducer",
                                      dataType: "basic",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "rollup_channel",
                                      sourceId: "selectedKpi",
                                      subjectId: "roll_up_channel",
                                    },
                                    {
                                      source: "reducer",
                                      apiRequestKey: "parameters",
                                      apiRequestKeyNested: "attribute",
                                      sourceId: "selectedConfig",
                                      subjectId: "id",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 52,
                  },
                  {
                    source: "reducer",
                    dataType: "basic",
                    sourceId: "selectedKpi",
                    subjectId: "kpi",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "kpi",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "requiredListKpiDetails",
                    dataType: "array",
                    multiLevelResponse: true,
                    level: 0,
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
