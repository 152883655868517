import { createSlice } from "@reduxjs/toolkit";
import { API } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import { requestStart, requestComplete, requestFail } from "../global/global";
import { formatRoles } from "../../../utils/helpers/apiHelpers";
import { firebaseobj } from "../../../components/auth/components/FirebaseInitialize";
import axiosinstance from "../../../utils/axiosInstance";
import { FETCH_TENANT } from "../../../components/config/api";
const { REACT_APP_PROMO_URL } = process.env;

const initialState = {
  token: null,
  userName: "",
  roleActions: [],
  isTenantInfoFetched: false,
  tenantId: null,
  alertProperties: {
    Text: "",
    Type: "info",
  },
};
const fetchUserProviders = (userEmail) => {
  return firebaseobj.auth().fetchSignInMethodsForEmail(userEmail);
};

export const getTenantId = (hostname) => (dispatch) => {
  //Logic to fetch tenantID from backend using axios
  return axiosinstance
    .get(`${FETCH_TENANT}?url=${hostname}`)
    .then((data) => {
      dispatch(setTenantFetchSuccess(data?.data?.data?.google_tenant_identity));
    })
    .catch((error) => {
      //Handling the error
      // dispatch({
      //   type: TENANT_FETCH_FAILED,
      // });
    });
};

const callAPI = (path, payload, dispatch) => {
  API.post(path, payload)
    .then((response) => {
      if (response.status === 200) {
        const { data, user_id, message } = response.data;
        localStorage.setItem("userId", user_id);
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", data.user_name);
        localStorage.setItem("userRoles", data.user_actions);
        const roles = formatRoles(data.user_actions);
        dispatch(setRoleActions(roles));
        dispatch(isAuthenticated(data));
        dispatch(requestComplete());
      }
    })
    .catch((error) => {
      console.log(error);
      const errorMessage = setErrorMessage(error);
      dispatch(requestFail(errorMessage));
    });
};
export const LOADER = "LOADER";
export const ADD_SNACK = "ADD_SNACK";

const loginUser = async (userData, dispatch) => {
  dispatch({
    type: LOADER,
    payload: {
      status: true,
      text: "Authenticating user...",
    },
  });
  const providers = await fetchUserProviders(userData.email);
  if (providers.length == 0) {
    dispatch({
      type: ADD_SNACK,
      payload: {
        message:
          "There is no user record corresponding to this identifier. The user may have been deleted",
        options: {
          variant: "error",
        },
      },
    });
    dispatch({
      type: LOADER,
      payload: {
        status: false,
        text: "Loading...",
      },
    });
    return;
  }
  if (!providers.includes("password")) {
    dispatch({
      type: ADD_SNACK,
      payload: {
        message: `User Record is already associated with ${providers[0]}`,
        options: {
          variant: "error",
        },
      },
    });
    dispatch({
      type: LOADER,
      payload: {
        status: false,
        text: "Loading...",
      },
    });
    return;
  }
  firebaseobj
    .auth()
    .signInWithEmailAndPassword(userData.email, userData.password)
    .catch((error) => {
      dispatch({
        type: LOADER,
        payload: {
          status: false,
          text: "Loading...",
        },
      });
      dispatch({
        type: ADD_SNACK,
        payload: {
          message: error.message,
          options: {
            variant: "error",
          },
        },
      });
    });
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    isAuthenticated(state, action) {
      const { token, user_name } = action.payload;
      state.token = token;
      state.userName = user_name;
    },
    setRoleActions(state, action) {
      state.roleActions = action.payload;
    },
    setTenantFetchSuccess(state, action) {
      state.tenantId = action.payload;
    },
  },
});

export const { isAuthenticated, setRoleActions, setTenantFetchSuccess } =
  authSlice.actions;
export default authSlice.reducer;

export const signIn = (payload) => (dispatch, getState) => {
  dispatch(requestStart());
  //calling the api to login insead call the api to verify token
  console.log("payload", payload);
  loginUser(payload, dispatch);
  //callAPI("/login", payload, dispatch);
};

export const signOut = () => (dispatch) => {
  localStorage.clear();
  sessionStorage.clear();
  // Check if SSO user. Logout of session
  if (firebaseobj?.auth().currentUser) {
    firebaseobj.auth().signOut();
  }

  dispatch(requestComplete());
  // window.location.href = REACT_APP_PROMO_URL;
  window.location.href = "/";
};

export const samlLoginCallback = (payload) => (dispatch, getState) => {
  dispatch(requestStart());
  callAPI("/samlCustomLogin", payload, dispatch);
};
